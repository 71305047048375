import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import Modal from "antd/es/modal/Modal";
import React, { useContext } from "react";
import noteContext from "../../Context/StyleContext";
import Search from "antd/es/transfer/search";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import { CalcMethod, PmtMethod } from "./AssetModel";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import AddLedger from "../Master/Ledger/AddLedger";
import FixedAssetsServiceNew, {
  Asset_Type,
  AssetStatus,
} from "../../Services/FixedAssetsServiceNew";
import { notificationContext } from "../../Common/PageRoute";
import { Utils } from "../../../Utilities/Utils";
interface ModelProps {
  isDisposeOpen: boolean;
  setIsDisposeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setReRender: any;
  reRender: boolean;
}
interface SelAssetModel {
  selAsset: string;
  value: number;
}
const DisposedAssestNewModal = ({
  isDisposeOpen,
  setIsDisposeOpen,
  setReRender,
  reRender,
}: ModelProps) => {
  const [form] = Form.useForm();
  const { windowWidth } = useContext(noteContext);
  const { RangePicker } = DatePicker;
  const { openNotification } = useContext(notificationContext);
  const { currentClient, companySettings } = useClient();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [payLedgers, setPayLedgers] = React.useState<any>([]);
  const [depAmount, setDepAmount] = React.useState<SelAssetModel[]>();
  const [pnLAmount, setPnLAmount] = React.useState<SelAssetModel[]>();
  const [payLedgersVal, setPayLedgersVal] = React.useState<any>([]);
  const [partyLedger, setPartyLedger] = React.useState<any>();
  const [addLedgerResponse, setAddLedgerResponse] = React.useState<any>(null);
  const [assestList, setAssestList] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [assestType, setAssestType] = React.useState<Asset_Type>(
    Asset_Type?.All
  );
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);

  // const [assestListId, setAssestListId] = React.useState<any>();
  // const [loss, setLoss] = React.useState<number>(0);

  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: "",
  });
  const [searchAsset, setSearchAsset] = React.useState<any>();
  const [paymentMode, setPaymentMode] = React.useState<any>();
  //   const [partyLedgers, setPartyLedgers] = React.useState<any>([]);

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs();
    const startDate = now.subtract(15, "day").startOf("day");
    const endDate = now.endOf("day");
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs();
    const startDate = now.subtract(7, "day").startOf("day");
    const endDate = now.endOf("day");
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };
  const showDepAmount = (selAssetNumber: string) => {
    let selDepAmount = depAmount?.find(
      (asst) => asst.selAsset === selAssetNumber
    );
    return selDepAmount?.value !== 0 ? selDepAmount?.value : 0;
  };
  const showPnLAmount = (selAssetNumber: string) => {
    let selPnLAmount = pnLAmount?.find(
      (asst) => asst.selAsset === selAssetNumber
    );
    let returnedAmt =
      selPnLAmount?.value !== 0 ? (
        selPnLAmount?.value! > 0 ? (
          <span style={{ color: "green" }}>
            {Utils.getFormattedNumber(
              Math.round(selPnLAmount?.value! * 100) / 100
            )}
          </span>
        ) : (
          <span style={{ color: "red" }}>
            {Utils.getFormattedNumber(
              Math.round(selPnLAmount?.value! * 100) / 100
            )}
          </span>
        )
      ) : (
        0
      );

    return returnedAmt;
  };
  console.log("Dep Amount: ", depAmount);
  const onPaymentModeChange = (selValue: PmtMethod) => {
    if (selValue === PmtMethod.bank) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.BankOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.fullCredit) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.PartiesOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.cash) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.CashOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.partialCredit) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.BankAndCash
      ).then((res: any) => {
        setPayLedgers(res);
      });
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.PartiesOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    setPaymentMode(selValue);
  };
  console.log("searchAsset", searchAsset);

  React.useEffect(() => {
    if (addLedgerResponse) {
      const exists = payLedgers.some(
        (option: any) => option?.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("paymentLedger", addLedgerResponse?.id);
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payLedgers]);

  React.useEffect(() => {
    // setModelLoading(true);
    setLoading(true);
    FixedAssetsServiceNew.getChildAssetList(
      currentClient?.id!,
      dayjs(filterDate?.from, "DD/MM/YYYY").toISOString(),
      dayjs(filterDate?.to, "DD/MM/YYYY").endOf("day").toISOString(),
      0,
      0,
      null,
      "",
      searchAsset,
      assestType
    ).then((res) => {
      if (res) {
        console.log("resss", res.items);
        setAssestList(res?.items);
        const formData = {
          items: res.items.map((itm: any) => {
            return {
              assetNumber: {
                label: itm?.assetNumber,
                value: {
                  id: itm?.id,
                  assetNumber: itm?.assetNumber,
                  invoiceKey: itm.invoiceKey,
                  price: itm?.assetPrice,
                  rate: itm?.depRate,
                  year: itm?.lifeCycleDuration,
                  method: itm?.depMethod,
                  residualValue: itm?.assetResidualValue,
                  accumulatedDepreciation: itm?.accumulatedDepreciation,
                  lastDepDate: itm?.assetDepLastDate,
                  startDate: itm?.assetDepStartDate,
                  isDepreciable: itm?.isDepreciable,
                  gst: itm?.gst?.rate,
                },
              },
            };
          }),
        };
        form.setFieldValue("items", formData.items);
        setLoading(false);
        // form.setFieldsValue(formData);
      } else {
        setAssestList([]);
        setLoading(false);
      }
      // setModelLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAsset, assestType, filterDate]);

  const onSubmit = () => {
    form.validateFields().then(async (val) => {
      console.log("val", val);
      let comonFields = {
        disposalDate: dayjs(val?.disDate).toISOString(),
        paymentMethod: val?.paymentMethod,
        disposalPaymentLedger: payLedgersVal?.value,
        payRef: val?.payRef,
        payDate: dayjs(val?.payDate).toISOString(),
        disposalPartyLedger: partyLedger,
        partiallyPaidAmount: val?.partiallyPaidAmount,
        // assetId: assestListId,
      };
      const items = val?.items
        ?.filter(
          (itm: any) =>
            itm?.assetDisposalValue !== null && itm.assetDisposalValue > 0
        )
        .map((v: any) => {
          return {
            ...comonFields,
            disposalValue: v?.assetDisposalValue,
            childAssetNumber: v?.assetNumber?.value?.assetNumber,
            assetId: v?.assetNumber?.value?.id,
            invoiceKey: v?.assetNumber?.value?.invoiceKey,
          };
        });
      console.log("submit", items);
      if (items.length > 0) {
        setButtonLoading(true);
        FixedAssetsServiceNew.postDispose(currentClient?.id!, items)
          .then((res) => {
            if (res?.status && res?.result) {
              // setJournal({ open: true, data: res?.result });
              openNotification("success", `Asset disposed successfully`);
              setReRender(() => !reRender);
            } else {
              setButtonLoading(false);
              setIsDisposeOpen(false);
            }
          })
          .catch((ex) => {
            openNotification("error", ex);
            setButtonLoading(false);
          });
      }
    });
    // .catch((ex) => {
    //   openNotification("error", ex);
    //   setButtonLoading(false);
    // });
  };

  // console.log("assestList", assestList);

  // const handleInputChange = (value: any, index: any) => {
  //   const updatedList = [...assestList];
  //   updatedList[index] = {
  //     ...updatedList[index],
  //     assetDisposalValue: value,
  //   };
  //   setAssestList(updatedList);
  // };

  // Handler for removing items
  const handleRemove = (selAssetNumber: string) => {
    // const updatedItemsList = assestList.filter(
    //   (asst: any) => asst.assetNumber !== selAssetNumber
    // );
    debugger;
    const updatedItemsList = form
      .getFieldValue("items")
      .filter((itm: any) => itm?.assetNumber?.label !== selAssetNumber);
    form.setFieldValue("items", updatedItemsList);
    console.log("updatedItemsList: ", updatedItemsList);

    const updatedList = assestList.filter(
      (asst: any) => asst.assetNumber !== selAssetNumber
    );
    setAssestList(updatedList);
    setDepAmount((preVal: any) => {
      const updatedDepAmount = preVal ? [...preVal] : [];
      return updatedDepAmount.filter(
        (asst: SelAssetModel) => asst.selAsset !== selAssetNumber
      );
    });
    setPnLAmount((preVal: any) => {
      const updatedPnLAmount = preVal ? [...preVal] : [];
      return updatedPnLAmount.filter(
        (asst: SelAssetModel) => asst.selAsset !== selAssetNumber
      );
    });
  };

  const calculateDepreciation = (
    disDate: any,
    rate: any,
    price: any,
    method: CalcMethod,
    residualValue: any,
    startDate: any,
    lastDepDate: any,
    accumulatedDep: any,
    year: any,
    isDepreciableAsset: boolean,
    selAssetNumber?: string
  ) => {
    let depreciation = 0;
    let leapyear = false;
    if (isDepreciableAsset) {
      const date1 =
        dayjs(lastDepDate).unix() > 1
          ? dayjs(lastDepDate, "YYYY/MM/DD")
          : dayjs(startDate, "YYYY/MM/DD");
      const date2 = dayjs(disDate, "DD/MM/YYYY");
      leapyear = date2.year() % 4 === 1 ? true : false;
      let yeardays = leapyear ? 366 : 365;
      let days = date2.diff(date1, "day") < 0 ? 0 : date2.diff(date1, "day");
      if (!lastDepDate || accumulatedDep === 0) days++;
      let calcPrice = price - accumulatedDep;
      if (method === CalcMethod.WrittenDownValue) {
        depreciation = (calcPrice * (rate / 100) * days) / yeardays;
      } else {
        depreciation = (((price - residualValue) / year) * days) / yeardays;
        if (depreciation > calcPrice) {
          depreciation = calcPrice > 0 ? calcPrice : 0;
        }
      }
    }
    setDepAmount((prevVal: any) => {
      let newVal = prevVal ? [...prevVal] : [];
      if (newVal?.find((asst) => asst.selAsset === selAssetNumber)) {
        newVal = newVal.map((asst: any) => {
          return asst.selAsset === selAssetNumber
            ? {
                selAsset: selAssetNumber,
                value: depreciation,
              }
            : asst;
        });
      } else {
        newVal.push({
          selAsset: selAssetNumber!,
          value: depreciation,
        });
      }
      return newVal;
    });

    // setDepAmount((prevVal: number[]) => {
    //   let newVal = [...prevVal];
    //   if (selIndex! > newVal.length) {
    //     newVal.push(depreciation);
    //   } else {
    //     newVal[selIndex!] = depreciation;
    //   }
    //   return newVal;
    // });
    // console.log("Calculated depreciation is: ", depreciation);

    return depreciation;
  };

  const onFieldsChange: any = (disDate: any) => {
    // console.log("Value of Val:", val, disDate);
    // let itemsData = form.getFieldValue("items").map((itm: any) => {
    //   let disValue = itm?.assetDisposalValue
    //     ? Utils.getNumber(itm?.assetDisposalValue)
    //     : 0;

    //   if (disValue > 0) {
    //     let selAsset = itm?.assetNumber;

    //     let depValue = calculateDepreciation(
    //       disDate,
    //       selAsset?.value?.rate,
    //       selAsset?.value?.price,
    //       selAsset?.value?.method,
    //       selAsset?.value?.residualValue,
    //       selAsset?.value?.startDate,
    //       selAsset?.value?.lastDepDate,
    //       selAsset?.value?.accumulatedDepreciation,
    //       selAsset?.value?.year,
    //       selAsset?.value?.isDepreciable,
    //       selAsset?.label
    //     );
    //     let wdv =
    //       selAsset?.value?.price -
    //       selAsset?.value?.accumulatedDepreciation -
    //       depValue;
    //     // let gstAmount =
    //     //   value -
    //     //   (value * 100) /
    //     //     (selAsset?.value?.gst + 100);
    //     let profitLoss = disValue - wdv;
    //     setPnLAmount((prevVal: any) => {
    //       let newVal = prevVal ? [...prevVal] : [];
    //       if (
    //         newVal?.find(
    //           (asst) => asst.selAsset === selAsset?.value?.assetNumber
    //         )
    //       ) {
    //         newVal = newVal.map((asst: any) => {
    //           return asst.selAsset === selAsset?.value?.assetNumber
    //             ? {
    //                 selAsset: selAsset?.value?.assetNumber,
    //                 value: profitLoss,
    //               }
    //             : asst;
    //         });
    //       } else {
    //         newVal.push({
    //           selAsset: selAsset?.value?.assetNumber,
    //           value: profitLoss,
    //         });
    //       }
    //       return newVal;
    //     });
    //   }
    // });

    // let disPrice = form.getFieldValue("assetDisposalValue");
    // let disposalPrice =
    //   disPrice && typeof disPrice === "string" && disPrice?.includes("₹")
    //     ? Utils.getNumber(disPrice?.slice(1))
    //     : Utils.getNumber(disPrice);
    // let depreciation = calculateDepreciation(
    //   disDate,
    //   val?.title?.rate,
    //   val?.title?.price,
    //   val?.title?.method,
    //   val?.title?.residualValue,
    //   val?.title?.startDate,
    //   val?.title?.lastDepDate,
    //   val?.title?.accumulatedDepreciation,
    //   val?.title?.year,
    //   val?.title?.isDepreciable
    // );

    // form.setFieldValue(
    //   "deprOnDis",
    //   Utils.getFormattedCurrency(
    //     depreciation + val?.title?.accumulatedDepreciation
    //   )
    // );
    // form.setFieldValue(
    //   "wdv",
    //   Utils.getFormattedCurrency(
    //     val?.title?.price - (depreciation + val?.title?.accumulatedDepreciation)
    //   )
    // );
    // setPnLAmount((prevVal: any) => {
    //   let newVal = [...prevVal];
    //   if (index! > newVal.length) {
    //     newVal.push({
    //       selAsset: assestList[index!].assetNumber,
    //       value:
    //         disposalPrice -
    //         (val?.title?.price -
    //           depreciation -
    //           val?.title?.accumulatedDepreciation),
    //     });
    //   } else {
    //     newVal[index!] = {
    //       selAsset: assestList[index!].assetName,
    //       value:
    //         disposalPrice -
    //         (val?.title?.price -
    //           depreciation -
    //           val?.title?.accumulatedDepreciation),
    //     };
    //   }
    //   return newVal;
    // });

    // setLoss();
    form.setFieldValue("payDate", disDate);
  };

  console.log("data", assestList.length);

  return (
    <>
      <div>
        <Modal
          title="Add Disposed Asset"
          maskClosable={false}
          open={isDisposeOpen}
          onCancel={() => setIsDisposeOpen(false)}
          width={1300}
          footer={
            <>
              <Button onClick={() => setIsDisposeOpen(false)}>Cancel</Button>
              {assestList.length === 0 ? (
                <></>
              ) : (
                <>
                  <Button
                    loading={buttonLoading}
                    type="primary"
                    onClick={() => onSubmit()}
                  >
                    Save
                  </Button>
                </>
              )}
            </>
          }
        >
          <Spin spinning={loading} indicator={<LoadingOutlined />}>
            <Row
              gutter={10}
              justify={"end"}
              style={{
                background: "whitesmoke",
                padding: "10px 4px",
              }}
            >
              <Col>
                <Search
                  placeholder="Search asset here..."
                  onChange={(e: any) => setSearchAsset(e.target.value)}
                />
              </Col>
              <Col>
                <RangePicker
                  format="DD/MM/YYYY"
                  defaultValue={[
                    dayjs(filterDate?.from, "DD/MM/YYYY"),
                    dayjs(filterDate?.to, "DD/MM/YYYY"),
                  ]}
                  style={{
                    width:
                      width <= 464
                        ? "210px"
                        : width === 375
                        ? "200px"
                        : "250px",
                    border: "1px solid #f1f3ff",
                  }}
                  onChange={(e: any) => {
                    if (e === null) {
                      setFilterDate({});
                    } else {
                      setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                      localStorage.setItem(
                        "LockPeriod",
                        JSON.stringify({
                          ...filterDate,
                          from: dayjs(e[0]).format("DD/MM/YYYY"),
                          to: dayjs(e[1]).format("DD/MM/YYYY"),
                        })
                      );
                      openNotification("success", `Period locked.`);
                    }
                  }}
                  presets={[
                    {
                      label: "Today",
                      value: [dayjs().add(0, "d"), dayjs()],
                    },
                    {
                      label: "Last 7 Days",
                      value: getLast7Days(),
                    },
                    {
                      label: "Last 15 Days",
                      value: getLast15Days(),
                    },
                    {
                      label: "This Month",
                      value: getThisMonth(),
                    },
                    {
                      label: "Last Month",
                      value: getLastMonth(),
                    },
                    {
                      label: "Quarter 1",
                      value: getQuarterPreset(0),
                    },
                    {
                      label: "Quarter 2",
                      value: getQuarterPreset(3),
                    },
                    {
                      label: "Quarter 3",
                      value: getQuarterPreset(6),
                    },
                    {
                      label: "Quarter 4",
                      value: getQuarterPreset(9),
                    },
                    {
                      label: "This Year",
                      value: thisYear(),
                    },
                    {
                      label: "Last Year",
                      value: lastYear(),
                    },
                  ]}
                />
              </Col>
              <Col>
                <Select
                  defaultValue={assestType}
                  options={[
                    {
                      value: Asset_Type.All,
                      label: "All",
                    },
                    {
                      value: Asset_Type.Single,
                      label: "Single",
                    },
                    {
                      value: Asset_Type.Collective,
                      label: "Collective",
                    },
                  ]}
                  onChange={(val: any) => setAssestType(val)}
                  style={{ width: "200px" }}
                  placeholder="Select Type"
                />
              </Col>
            </Row>
            {assestList.length === 0 ? (
              <>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: "16px",
                  }}
                >
                  No Data
                </p>
              </>
            ) : (
              <>
                <Divider orientation="left" orientationMargin={0}>
                  Payment Details
                </Divider>
                <Form
                  name="form"
                  layout="vertical"
                  form={form}
                  autoComplete="off"
                  initialValues={{
                    disposalDate: dayjs(),
                    payDate: dayjs(),
                    items: [{}],
                  }}
                >
                  <Row gutter={10}>
                    <Col xl={6} lg={6} sm={12}>
                      <Form.Item
                        label="Date"
                        name="disposalDate"
                        className="inputBoxMb8  inputLabel33"
                        rules={[
                          {
                            required: true,
                            message: "Disposed date is required.",
                          },
                        ]}
                      >
                        <DatePicker
                          // defaultValue={dayjs()}
                          format="DD/MM/YYYY"
                          style={{ width: "100%" }}
                          onChange={(val: any) =>
                            onFieldsChange(
                              form.getFieldValue("disposalDate"),
                              val
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={6} lg={6} sm={12}>
                      <Form.Item
                        label="Payment Method"
                        className="inputBoxMb8  inputLabel33"
                        name="paymentMethod"
                        rules={[
                          {
                            required: true,
                            message: "Payment method is required.",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select payment method"
                          onChange={onPaymentModeChange}
                          options={[
                            { value: PmtMethod.bank, label: "Bank" },
                            { value: PmtMethod.cash, label: "Cash" },
                            {
                              value: PmtMethod.fullCredit,
                              label: "Credit",
                            },
                            {
                              value: PmtMethod.partialCredit,
                              label: "Partial Payment",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    {paymentMode !== PmtMethod.fullCredit && (
                      <Col xl={6} lg={6} sm={12}>
                        <Form.Item
                          label="Payment Ledger"
                          className="inputBoxMb8  inputLabel33"
                          name="disposalPaymentLedger"
                          rules={[
                            {
                              required: true,
                              message: "Payment ledger is required.",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            labelInValue
                            optionFilterProp="children"
                            placeholder="Select Ledger"
                            placement="bottomLeft"
                            className="selectedItem"
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // onSearch={(value: string) =>
                            //   setSearchCustomer({ search: value, length: 10 })
                            // }
                            // onClick={() =>
                            //   setSearchCustomer({ ...searchCustomer, search: "" })
                            // }
                            onChange={(value) => setPayLedgersVal(value)}
                            options={payLedgers?.map((value: any) => ({
                              value: value?.id,
                              label: value?.ledgerName.concat(
                                value?.aliasName
                                  ? `${" (" + value?.aliasName + ")"}`
                                  : ""
                              ),
                            }))}
                            dropdownRender={(supplierOptions) => (
                              <>
                                {supplierOptions}
                                <Divider style={{ margin: "5px 0px" }} />
                                <Button
                                  style={{ width: "100%" }}
                                  icon={<PlusOutlined />}
                                  type="link"
                                  onClick={() => {
                                    setOpenModel({
                                      type: "addLedger",
                                      param: "",
                                    });
                                    // setEditParty({ id: "" });
                                  }}
                                >
                                  New Ledger
                                </Button>
                              </>
                            )}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {paymentMode === PmtMethod.partialCredit ||
                    paymentMode === PmtMethod.fullCredit ? (
                      <>
                        <Col xl={6} lg={6} sm={12}>
                          <Form.Item
                            label="Party Ledger"
                            className="inputBoxMb8  inputLabel33"
                            name="DisposalPartyLedger"
                            rules={[
                              {
                                required: true,
                                message: "Party ledger is required.",
                              },
                            ]}
                          >
                            <Select
                              onChange={(val: any) => setPartyLedger(val)}
                              showSearch
                              filterOption={(input, option) =>
                                (option?.label?.toString() ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ marginBottom: "3px" }}
                              options={payLedgers?.map((value: any) => ({
                                value: value?.id,
                                label: value?.ledgerName.concat(
                                  value?.aliasName
                                    ? `${" (" + value?.aliasName + ")"}`
                                    : ""
                                ),
                              }))}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                    {paymentMode === PmtMethod.partialCredit && (
                      <>
                        <Col xl={6} lg={6} sm={12}>
                          <Form.Item
                            label="Partially Paid Amount"
                            className="inputBoxMb8  inputLabel33"
                            name="partiallyPaidAmount"
                            rules={[
                              {
                                required: true,
                                message: "Partially paid amount is required.",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {paymentMode !== PmtMethod.fullCredit && (
                      <Col xl={6} lg={6} sm={12}>
                        <Form.Item
                          label="Payment Date"
                          className="inputBoxMb8  inputLabel33"
                          name="payDate"
                          rules={[
                            {
                              required: true,
                              message: "Disposed date is required.",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(paymentMode === PmtMethod.bank ||
                      paymentMode === PmtMethod.partialCredit) && (
                      <Col xl={6} lg={6} sm={12}>
                        <Form.Item
                          label="Payment Reference"
                          className="inputBoxMb8  inputLabel33"
                          name="payRef"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <table
                    className="AddPageTabelDesign"
                    style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "start",
                            paddingLeft: "5px",
                            width: "15%",
                          }}
                        >
                          Asset Name
                        </th>
                        <th
                          style={{
                            textAlign: "start",
                            paddingLeft: "5px",
                            width: "15%",
                          }}
                        >
                          Asset Reference
                        </th>
                        <th
                          align="right"
                          style={{
                            padding: "2px 5px",
                            width: "10%",
                          }}
                        >
                          Price
                        </th>
                        <th
                          align="center"
                          style={{
                            padding: "2px 5px",
                            width: "5%",
                          }}
                        >
                          Depreciable?
                        </th>
                        <th
                          align="right"
                          style={{
                            padding: "2px 5px",
                            width: "12%",
                          }}
                        >
                          Written Down Value
                        </th>
                        <th
                          align="right"
                          style={{
                            padding: "2px 5px",
                            width: "8%",
                          }}
                        >
                          Depreciation
                        </th>
                        <th
                          // align="right"
                          style={{
                            padding: "2px 5px",
                            width: "12%",
                          }}
                        >
                          <Space>
                            Disposal Amount
                            <Tooltip
                              title="Please input the disposal amount excluding the GST means base disposal amount only"
                              placement="top"
                              color="#4b5b95"
                            >
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Space>
                        </th>
                        <th
                          align="right"
                          style={{
                            padding: "2px 5px",
                            width: "10%",
                          }}
                        >
                          Profit/Loss
                        </th>

                        <th
                          style={{ textAlign: "start", paddingLeft: "5px" }}
                          colSpan={2}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        <Form.List name="items">
                          {(fields: any, { add, remove }) => (
                            <>
                              {assestList?.map((item: any, index: any) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      padding: "2px 5px",
                                    }}
                                  >
                                    <p>{item?.assetName}</p>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...fields[index]}
                                      name={[
                                        fields[index]?.name,
                                        "assetNumber",
                                      ]}
                                      fieldKey={[
                                        fields[index]?.fieldKey,
                                        "assetNumber",
                                      ]}
                                      className="m-0"
                                    >
                                      <Select
                                        disabled
                                        labelInValue
                                        placeholder="Select Asset Number"
                                        style={{ width: "100%" }}
                                        // options={[
                                        //   {
                                        //     // value: item?.assetNumber,
                                        //     label: item?.assetNumber,
                                        //     key: item?.assetNumber,
                                        //     value: {
                                        //       id: item?.id,
                                        //       assetNumber: item?.assetNumber,
                                        //       invoiceKey: item?.invoiceKey,
                                        //       price: item?.assetPrice,
                                        //       rate: item?.depRate,
                                        //       year: item?.lifeCycleDuration,
                                        //       method: item?.depMethod,
                                        //       residualValue: item?.assetResidualValue,
                                        //       accumulatedDepreciation:
                                        //         item?.accumulatedDepreciation,
                                        //       lastDepDate: item?.assetDepLastDate,
                                        //       startDate: item?.assetDepStartDate,
                                        //       isDepreciable: item?.isDepreciable,
                                        //       gst: item?.gst?.rate,
                                        //     }, // Object containing the additional details
                                        //   },
                                        // ]}
                                        options={[]}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td
                                    align="right"
                                    style={{
                                      padding: "2px 5px",
                                    }}
                                  >
                                    <p>
                                      {Utils.getFormattedNumber(
                                        item?.assetPrice
                                      )}
                                    </p>
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      padding: "2px 5px",
                                    }}
                                  >
                                    {item?.isDepreciable ? "Yes" : "No"}
                                  </td>
                                  <td
                                    align="right"
                                    style={{
                                      padding: "2px 5px",
                                    }}
                                  >
                                    {Utils.getFormattedNumber(
                                      Math.round(
                                        (item?.assetPrice -
                                          item?.accumulatedDepreciation -
                                          showDepAmount(item?.assetNumber)!) *
                                          100
                                      ) / 100
                                    )}
                                  </td>
                                  <td
                                    align="right"
                                    style={{
                                      padding: "2px 5px",
                                    }}
                                  >
                                    {Utils.getFormattedNumber(
                                      Math.round(
                                        showDepAmount(item?.assetNumber)! * 100
                                      ) / 100
                                    )}
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...fields[index]}
                                      name={[
                                        fields[index]?.name,
                                        "assetDisposalValue",
                                      ]}
                                      fieldKey={[
                                        fields[index]?.fieldKey,
                                        "assetDisposalValue",
                                      ]}
                                      className="m-0"
                                    >
                                      <InputNumber
                                        onBlur={(e) => {
                                          let value = e?.target?.value
                                            ? Utils.getNumber(e?.target?.value)
                                            : 0;
                                          // if (value > 0) {
                                          let selAsset = form.getFieldValue([
                                            "items",
                                            fields[index]?.name,
                                            "assetNumber",
                                          ]);
                                          let depValue = calculateDepreciation(
                                            form.getFieldValue("disposalDate"),
                                            selAsset?.value?.rate,
                                            selAsset?.value?.price,
                                            selAsset?.value?.method,
                                            selAsset?.value?.residualValue,
                                            selAsset?.value?.startDate,
                                            selAsset?.value?.lastDepDate,
                                            selAsset?.value
                                              ?.accumulatedDepreciation,
                                            selAsset?.value?.year,
                                            selAsset?.value?.isDepreciable,
                                            item?.assetNumber
                                          );
                                          let wdv =
                                            selAsset?.value?.price -
                                            selAsset?.value
                                              ?.accumulatedDepreciation -
                                            depValue;
                                          // let gstAmount =
                                          //   value -
                                          //   (value * 100) /
                                          //     (selAsset?.value?.gst + 100);
                                          let profitLoss = value - wdv;
                                          setPnLAmount((prevVal: any) => {
                                            let newVal = prevVal
                                              ? [...prevVal]
                                              : [];
                                            if (
                                              newVal?.find(
                                                (asst) =>
                                                  asst.selAsset ===
                                                  selAsset?.value?.assetNumber
                                              )
                                            ) {
                                              newVal = newVal.map(
                                                (asst: any) => {
                                                  return asst.selAsset ===
                                                    selAsset?.value?.assetNumber
                                                    ? {
                                                        selAsset:
                                                          selAsset?.value
                                                            ?.assetNumber,
                                                        value: profitLoss,
                                                      }
                                                    : asst;
                                                }
                                              );
                                            } else {
                                              newVal.push({
                                                selAsset:
                                                  selAsset?.value?.assetNumber,
                                                value: profitLoss,
                                              });
                                            }
                                            return newVal;
                                          });
                                          // setLoss(profitLoss);
                                          // form.setFieldValue(
                                          //   "disPrice",
                                          //   Utils.getFormattedCurrency(value)
                                          // );
                                          // }
                                        }}
                                        type="number"
                                        placeholder="Disposal Value"
                                        min={0}
                                        style={{ width: "100%" }}
                                        // onChange={(value) =>
                                        //   handleInputChange(value, index)
                                        // }
                                      />
                                    </Form.Item>
                                  </td>
                                  <td
                                    align="right"
                                    style={{
                                      padding: "2px 5px",
                                    }}
                                  >
                                    {
                                      showPnLAmount(item?.assetNumber)
                                      // pnLAmount[index] &&
                                      // pnLAmount[index]?.value !== 0 ? (
                                      //   pnLAmount[index]?.value > 0 ? (
                                      // <span style={{ color: "green" }}>
                                      //   {Utils.getFormattedNumber(
                                      //     Math.round(
                                      //       (pnLAmount[index]?.value
                                      //         ? pnLAmount[index]?.value
                                      //         : 0) * 100
                                      //     ) / 100
                                      //   )}
                                      // </span>
                                      //   ) : (
                                      //     <span style={{ color: "red" }}>
                                      //       {Utils.getFormattedNumber(
                                      //         Math.round(
                                      //           (pnLAmount[index]?.value
                                      //             ? pnLAmount[index]?.value
                                      //             : 0) * 100
                                      //         ) / 100
                                      //       )}
                                      //     </span>
                                      //   )
                                      // ) : (
                                      //   0
                                      // )
                                    }
                                  </td>
                                  <td
                                    width={"4%"}
                                    style={{
                                      textAlign: "center",
                                      padding: "2px 5px",
                                    }}
                                  >
                                    <Button
                                      style={{ padding: "0px" }}
                                      type="link"
                                      onClick={() =>
                                        handleRemove(item?.assetNumber)
                                      }
                                    >
                                      <DeleteOutlined
                                        style={{ color: "red" }}
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </>
                    </tbody>
                  </table>
                </Form>
              </>
            )}

            {/* <div style={{ marginTop: "15px" }}>
            {!isNaN(loss) && loss !== 0 && (
              <>
                {loss > 0 ? (
                  <Alert
                    message={`Profit on sale of fixed asset - ${Utils.getFormattedCurrency(
                      loss
                    )}`}
                    type="success"
                    showIcon
                  />
                ) : (
                  <Alert
                    message={`Loss on sale of fixed asset - ${Utils.getFormattedCurrency(
                      Math.abs(loss)
                    )}`}
                    type="error"
                    showIcon
                  />
                )}
              </>
            )}
          </div> */}
          </Spin>
        </Modal>
      </div>

      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={true}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() =>
            onPaymentModeChange(form.getFieldValue("paymentMethod"))
          }
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
    </>
  );
};

export default DisposedAssestNewModal;
