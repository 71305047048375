import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputRef,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  //Space,
} from "antd";
import {
  DeleteTwoTone,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import ClientService, {
  BankAccountTypes,
  BusinessNatures,
} from "../../Services/ClientService";
import AddClientContacts from "./AddClientContacts";
import {
  BusinessTypeOptions,
  BusinessNaturesTypeOptions,
  GSTTypeOptions,
} from "./Clients";
//import checkbox from "antd/es/checkbox";
import { dayjs } from "../../../Utilities/dayjs";
import { TypeOfAddress } from "../../Services/LedgerService";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import { useClient } from "../../Context/ClientContext";
import GstService from "../../Services/GstService";
import { useUser } from "../../../Context/UserContext";
import { RcFile } from "antd/es/upload";

// const defaultContactOption: any = [
//   { name: "Mr Balaram Gujjar", id: "5ce8eae2bca2320eb462af90" },
//   { name: "Mr Jayesh Chauhan", id: "5ce8eae2bca2320eb462af91" },
// ];
const AddOrEditClient = ({
  open,
  setOpen,
  clt,
  setTrigger,
  notification,
  productData,
}: any) => {
  console.log("productModule", productData);

  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const [form] = Form.useForm();
  const [gstValues, setGstValues] = useState<any>([]);
  const inputRef = useRef<InputRef>(null);
  const [gSTTypeValue, setGSTTypeValue] = useState<CompanyGSTTypes>(
    CompanyGSTTypes.Regular
  );
  const [showDetailsTDS, setShowDetailsTDS] = useState<boolean>(false);
  const [showDetailsTCS, setShowDetailsTCS] = useState<boolean>(false);
  const [bankTypeValue, setBankTypeValue] = useState<BankAccountTypes[]>([]);
  const [contactOpen, setContactOpen] = useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [width, SetWidth] = React.useState<any>(window.innerWidth);
  const [bankDefaultChecked, setBankDefaultChecked] = useState<boolean[]>([
    false,
  ]);
  const [checkLogoUploadImg, setCheckLogoUploadImg] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const handleDelBankDetail = (nameIndex: number) => {
    setBankDefaultChecked((prevDetail: any) => {
      let newDetail;
      let selIndex = prevDetail.indexOf(true);
      if (selIndex === -1) {
        newDetail = prevDetail.filter(
          (_: any, index: number) => index !== nameIndex
        );
        newDetail = newDetail.map((d: any) => (d = false));
        newDetail[0] = true;
      } else if (selIndex === nameIndex) {
        newDetail = prevDetail.filter(
          (_: any, index: number) => index !== nameIndex
        );
        if (newDetail !== 0) {
          newDetail[0] = true;
        }
      } else if (selIndex < nameIndex) {
        newDetail = prevDetail.filter(
          (_: any, index: number) => index !== nameIndex
        );
      } else {
        newDetail = prevDetail.filter(
          (_: any, index: number) => index !== nameIndex
        );
        newDetail = newDetail.map((d: any) => (d = false));
        newDetail[selIndex - 1] = true;
      }
      // console.log("Updated BankDetail", newDetail);

      return newDetail;
    });
  };
  const setBankDefaultValueHandler = (val: any, nameIndex: number) => {
    setBankDefaultChecked(() => {
      let bankVal = form.getFieldValue("bankDetail");
      if (bankVal === null) {
        return [false];
      } else {
        return bankVal?.map((bankVal: any, index: number) =>
          index === nameIndex && val === true ? true : false
        );
      }
    });
  };
  const handleGSTTypeChange = (value: any) => {
    setGSTTypeValue(value);
    if (value !== CompanyGSTTypes.Regular) {
      form.setFieldValue("state", null);
      form.setFieldValue("gstin", "");
    }
  };
  const handlePANNo = (e: any) => {
    const { value } = e.target;
    form.setFieldValue("pan", value.slice(2, 12));
    form.setFieldValue("state", Number(value.slice(0, 2)));
  };
  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
      if (clt?.id !== null) {
        ClientService.getClient(clt?.id).then((res: any) => {
          if (res.result) {
            // setBankDefaultChecked(
            //   res?.result?.bankDetail !== null
            //     ? res?.result?.bankDetail?.map((bankVal: any, index: number) =>
            //         bankVal?.isDefault === true ? true : false
            //       )
            //     : [false]
            // );
            setData(res?.result);
            form.setFieldsValue({
              ...res?.result,
              // booksBegin: dayjs(res?.result?.booksBegin),
              // addresses: res?.result?.addresses?.map((a: any) => ({
              //   type: a.type,
              //   addressName: a.name,
              //   address: {
              //     building: a.address?.building || "",
              //     street: a.address?.street || "",
              //   },
              // })),
              addresses: res?.result?.addresses?.map((address: any) => ({
                type: address?.type,
                addressName: address?.addressName,
                address: {
                  building: address?.address?.building || "",
                  street: address?.address?.street || "",
                  landmark: address?.address?.landmark || "",
                  city: address?.address?.city || "",
                  state:
                    address?.address?.state === 0
                      ? undefined
                      : address?.address?.state,
                  pincode: address?.address?.pincode || "",
                  country: address?.address?.country || "",
                  district: address?.address?.district || "",
                },
              })),
              businessStart: dayjs(res?.result?.businessStart),
              gstRegistrationDate: dayjs(res?.result?.gstRegistrationDate),
              financialYearBegin: dayjs(res?.result?.financialYearBegin),
              // logo: res?.result?.logo?.path,
              businessNature:
                res?.result?.businessNature !== null
                  ? res?.result?.businessNature
                  : [],
              bankDetail: res.result?.bankDetail
                ? res.result?.bankDetail
                : [{}],
              gst: res?.result?.gst?.map((itm: any) => {
                return {
                  value: itm?.id,
                  label: itm?.name,
                  title: itm?.rate,
                };
              }),
            });
            setBankTypeValue((prevBankValue: any) => {
              let newBankValues = [...prevBankValue];
              res.result?.bankDetail.map((bank: any, index: number) => {
                if (index > newBankValues.length - 1) {
                  newBankValues.push(bank.accountType);
                } else {
                  newBankValues[index] = bank.accountType;
                }
              });
              return newBankValues;
            });
            setGSTTypeValue(res?.result?.gstType);
            setShowDetailsTDS(res?.result?.tdsDeductible);
            setShowDetailsTCS(res?.result?.tcsApplicability);
          }
        });
      }
      GstService.getGst().then((val: any) => {
        setGstValues(
          val?.items?.map((itm: any) => {
            return {
              value: itm?.id,
              label: itm?.name,
              title: itm?.rate,
            };
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  console.log("check", checkLogoUploadImg);

  const addClients = () => {
    form
      .validateFields()
      .then(async (val) => {
        setSaveButtonLoading(true);
        console.log("val", val);
        let currentFileBase64 = checkLogoUploadImg
          ? await getBase64(val.logo as RcFile)
          : "";
        const clients = {
          ...val,
          // booksBegin: dayjs(val?.booksBegin).format("YYYY/MM/DD"),
          productModule: productData.productModule,
          businessStart: val?.businessStart
            ? dayjs(val?.businessStart!).format("YYYY/MM/DD")
            : dayjs("0000-01-01"),
          financialYearBegin: dayjs(val?.financialYearBegin),
          gstRegistrationDate: dayjs(val?.gstRegistrationDate),
          // bankDetail: val?.bankDetail?.map((b: any, index: number) => {
          //   b.isDefault = bankDefaultChecked[index];
          //   return b;
          // }),
          gst: val?.gst?.map((itm: any) => {
            return {
              id: itm?.value,
              name: itm?.label,
              rate: itm?.title,
            };
          }),

          logo: checkLogoUploadImg
            ? {
                id: "",
                name: val?.logo.name,
                path: checkLogoUploadImg ? currentFileBase64 : "",
                length: val?.logo?.size,
                contentType: val?.logo?.type,
              }
            : undefined,

          // defaultContact: defaultContactOption?.find(
          //   (item: any) =>
          //     item?.id === val?.defaultContact?.id ||
          //     item.name === val?.defaultContact?.name,
          //   console.log("dataaaa", val)
          // ),
          // yearEnd:
          //   val?.yearEnd === null || val?.yearEnd === undefined
          //     ? ""
          //     : dayjs(val?.yearEnd).format("dd-MM-YYYY"),
        };

        await ClientService.post(clients, clt?.id).then((res) => {
          if (res?.status) {
            notification(
              "success",
              `Client details ${
                clt?.id === null ? "Saved" : "Updated"
              } Successfully`
            );

            form.resetFields();
            setOpen(false);
            setTrigger((x: any) => !x);
            setSaveButtonLoading(false);
            setCheckLogoUploadImg(false);
          } else {
            notification("error", res?.message);
            setSaveButtonLoading(false);
          }
        });
      })
      .catch(() => {
        return false;
        setSaveButtonLoading(false);
      });
  };

  return (
    <>
      <Modal
        open={open}
        title={clt?.id === null ? "Add Client" : "Update Client"}
        onCancel={handleCancel}
        width={1000}
        maskClosable={false}
        // onOk={addClients}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => addClients()}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {clt?.id ? "Update" : "Save"}
            </Button>
          </div>
        }
        closable={false}
      >
        <div>
          <Form
            style={{
              maxHeight: "70vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            form={form}
            name="client"
            colon={false}
            requiredMark={false}
            initialValues={{
              contactInformation: [{}],
              bankDetail: [{}],
              // businessStart: dayjs(),
              // booksBegin: dayjs(),
              // financialYearBegin: dayjs(),
              addresses: [{}],
              businessType: 0,
              gstType: CompanyGSTTypes.Regular,
            }}
            scrollToFirstError
            autoComplete="off"
            layout="vertical"
          >
            <Row
              gutter={14}
              style={{ justifyContent: width <= 375 ? "center" : "" }}
            >
              <Col lg={8} md={8} sm={12} xs={24}>
                <Form.Item
                  name="businessName"
                  className="inputBoxMb8 inputLabel33"
                  label="Business Name"
                  rules={[
                    {
                      required: true,
                      min: 6,
                      message: "Business name is required",
                      pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                    },
                  ]}
                >
                  <Input autoFocus={true} ref={inputRef} />
                </Form.Item>
              </Col>
              <Col lg={4} md={8} sm={12} xs={24}>
                <Form.Item
                  name="aliasName"
                  className="inputBoxMb8 inputLabel33"
                  label="Alias/Short Name"
                  rules={[
                    {
                      min: 3,
                      pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="businessType"
                  label="Business Type"
                  className="inputBoxMb8 inputLabel33"
                >
                  <Select
                    options={BusinessTypeOptions}
                    placeholder="Select Type"
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="businessNature"
                  label="Business Nature"
                  className="inputBoxMb8 inputLabel33"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Select Nature"
                    //onChange={handleChange}
                    options={BusinessNaturesTypeOptions}
                  />
                </Form.Item>
              </Col>

              {/* GST */}
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="gstType"
                  label="GST Type"
                  className="inputBoxMb8 inputLabel33"
                  rules={[
                    {
                      required: true,
                      message: "Please select the appropriate GST Type",
                    },
                  ]}
                >
                  <Select
                    onChange={handleGSTTypeChange}
                    // onChange={(val) => {
                    //   setGSTTypeValue(val);
                    // }}
                    options={GSTTypeOptions}
                  />
                </Form.Item>
              </Col>
              {gSTTypeValue !== CompanyGSTTypes.Unregistered &&
                gSTTypeValue !== CompanyGSTTypes.Consumer && (
                  <>
                    {" "}
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        name="gstin"
                        className="inputBoxMb8 inputLabel33"
                        label="GSTIN"
                        rules={[
                          {
                            required: true,
                            // required:
                            //   gSTTypeValue === CompanyGSTTypes.Unregistered ||
                            //   gSTTypeValue === CompanyGSTTypes.Consumer
                            //     ? false
                            //     : true,
                            pattern:
                              /^(?!00)[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/,
                            message: "Enter Valid GST Number",
                          },
                        ]}
                      >
                        <Input
                          placeholder="01AABCC1234D1ZZ"
                          maxLength={15}
                          allowClear={true}
                          onChange={(e: any) => {
                            const capitalvalue = e.target.value.toUpperCase();
                            form.setFieldValue("gstin", capitalvalue);
                          }}
                          onBlur={handlePANNo}
                          // disabled={
                          //   gSTTypeValue === CompanyGSTTypes.Unregistered ||
                          //   gSTTypeValue === CompanyGSTTypes.Consumer
                          //     ? true
                          //     : false
                          // }
                          // onChange={(e: any) => {
                          //   if (e.target.value) setTouched(true);
                          //   else setTouched(false);
                          // }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="pan"
                  className="inputBoxMb8 inputLabel33"
                  label="PAN"
                  rules={[
                    {
                      required:
                        gSTTypeValue === CompanyGSTTypes.Unregistered ||
                        gSTTypeValue === CompanyGSTTypes.Consumer
                          ? true
                          : false,
                      pattern: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
                      message: "Enter Valid PAN Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="AABCC1234D"
                    maxLength={10}
                    onChange={(e: any) => {
                      const capitalvalue = e.target.value.toUpperCase();
                      form.setFieldValue("pan", capitalvalue);
                    }}
                    // style={{
                    //   width: window.innerWidth <= 425 ? "226px" : " ",
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="state"
                  label="State"
                  className="inputBoxMb8 inputLabel33"
                  rules={[
                    {
                      required:
                        gSTTypeValue === CompanyGSTTypes.Unregistered ||
                        gSTTypeValue === CompanyGSTTypes.Consumer
                          ? true
                          : false,
                      message: "Please select the State",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Please select State"
                    options={Object.entries(stateOptions)?.map(
                      ([key, x]: any) => ({
                        value: x?.id,
                        label: x?.name,
                      })
                    )}
                    filterOption={(input, option) =>
                      (option?.label.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: window.innerWidth <= 425 ? "226px" : " ",
                    }}
                  />
                </Form.Item>
              </Col>
              {gSTTypeValue !== CompanyGSTTypes.Unregistered &&
                gSTTypeValue !== CompanyGSTTypes.Consumer && (
                  <>
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        name="gstRegistrationDate"
                        label="GST Registration Date"
                        className="inputBoxMb8 inputLabel33"
                      >
                        <DatePicker
                          // disabled={
                          //   gSTTypeValue === CompanyGSTTypes.Unregistered ||
                          //   gSTTypeValue === CompanyGSTTypes.Consumer
                          //     ? true
                          //     : false
                          // }
                          format="DD/MM/YYYY"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

              {gSTTypeValue !== CompanyGSTTypes.Unregistered &&
                gSTTypeValue !== CompanyGSTTypes.Consumer && (
                  <>
                    {" "}
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        className="inputBoxMb8 inputLabel33"
                        label="Select GST Rates"
                        name="gst"
                      >
                        <Select
                          // disabled={
                          //   gSTTypeValue === CompanyGSTTypes.Unregistered ||
                          //   gSTTypeValue === CompanyGSTTypes.Consumer
                          //     ? true
                          //     : false
                          // }
                          labelInValue
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Please select GSTs"
                          // onChange={(val) => console.log(val)}
                          options={gstValues}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        name="iec"
                        label="IEC"
                        className="inputBoxMb8 inputLabel33"
                        rules={[
                          {
                            pattern: /^[A-Za-z0-9]{10}$/,
                            message: "Enter Valid IEC Number",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                )}

              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="msmeNo"
                  label="MSME No."
                  className="inputBoxMb8 inputLabel33"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {/* TDS Details */}

            <Row gutter={14}>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="tdsDeductible"
                  label="Enable TDS deduction"
                  className="inputBoxMb8 inputLabel33"
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={(e) => setShowDetailsTDS(e.target.checked)}
                  />
                </Form.Item>
              </Col>
              {showDetailsTDS && (
                <>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="tan"
                      label="TAN"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        {
                          pattern: /^[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}$/,
                          message: "Enter Valid TAN",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="tdsDeductorType"
                      label="TDS Deductor Type"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Select />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>

            {/* TCS Details */}

            <Row gutter={14}>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="tcsApplicability"
                  label="Enable TCS collection"
                  className="inputBoxMb8 inputLabel33"
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={(e) => setShowDetailsTCS(e.target.checked)}
                  />
                </Form.Item>
              </Col>
              {showDetailsTCS && (
                <>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="tcsCollecteeType"
                      label="TCS Collectee Type"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Select />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            {/* Business Details */}
            <div>
              <Divider
                orientation="left"
                orientationMargin="0"
                className="dividertext"
                style={{ marginTop: "11px", marginBottom: "11px" }}
              >
                <p style={{ fontWeight: 600 }}>Business Details</p>
              </Divider>
              <Row gutter={14}>
                <Col lg={6} md={8} sm={12} xs={24}>
                  <Form.Item
                    name="businessStart"
                    label="Business Start Date"
                    className="inputBoxMb8 inputLabel33"
                  >
                    <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col lg={6} md={8} sm={12} xs={24}>
                  <Form.Item
                    name="financialYearBegin"
                    className="inputBoxMb8 inputLabel33"
                    label="Financial Year Begin on"
                    rules={[
                      {
                        required: true,
                        message: "Please input the financial year begin date",
                      },
                      {
                        validator: (_, value) => {
                          let selDate = Number(dayjs(value).date());
                          let selMonth = Number(dayjs(value).month()) + 1;
                          if (selDate === 1 && selMonth === 4) {
                            return Promise.resolve();
                          } else if (selDate === 1 && selMonth === 1) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error(
                                "Financial year may begin on 1st January or 1st April"
                              )
                            );
                          }
                        },

                        // !value.endsWith(" ")
                        //   ? Promise.resolve()
                        //   : Promise.reject(new Error("No spaces allowed")),
                      },
                    ]}
                  >
                    <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col lg={6} md={8} sm={12} xs={24}>
                  <Form.Item
                    name="website"
                    label="Website"
                    className="inputBoxMb8 inputLabel33"
                    rules={[
                      {
                        type: "url",
                        message: "Please enter a valid URL!",
                      },
                    ]}
                  >
                    <Input placeholder="https://www.example.com" />
                  </Form.Item>
                </Col>
                <Col lg={6} md={8} sm={12} xs={24}>
                  <Form.Item
                    name="logo"
                    label="Company's Logo"
                    className="inputBoxMb8 inputLabel33"
                    valuePropName="file"
                    getValueFromEvent={(e) =>
                      e.target.files ? e.target.files[0] : null
                    }
                    // rules={[
                    //   // {
                    //   //   required: true,
                    //   //   message: "Please upload the company's logo",
                    //   // },
                    //   {
                    //     validator(_, value) {
                    //       if (value) {
                    //         const allowedTypes = [
                    //           "image/jpeg",
                    //           "image/png",
                    //           "image/jpg",
                    //         ];
                    //         if (!allowedTypes.includes(value.type)) {
                    //           return Promise.resolve(
                    //             new Error(
                    //               "Only JPEG, JPG, or PNG formats are allowed"
                    //             )
                    //           );
                    //         }
                    //       }
                    //       return Promise.resolve();
                    //     },
                    //   },
                    // ]}
                  >
                    <Input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e: any) => setCheckLogoUploadImg(true)}
                    />
                  </Form.Item>
                </Col>
                {/* <Col lg={6} md={8} sm={12} xs={24}>
                  <Form.Item
                    name="signature"
                    label="Signature"
                    className="inputBoxMb8 inputLabel33"
                  >
                    <Input type="file" />
                  </Form.Item>
                </Col> */}
              </Row>
            </div>

            {/* Bank Details */}
            <div>
              <Form.List name="bankDetail">
                {(fields, { add, remove }) => (
                  <>
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      className="dividertext"
                      style={{ marginTop: "11px", marginBottom: "11px" }}
                    >
                      <p style={{ fontWeight: 600 }}>Bank Details</p>
                    </Divider>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div key={index}>
                        <Row gutter={14}>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "accountHolderName"]}
                              className="inputBoxMb8 inputLabel33"
                              label="Account Holder Name"
                              rules={[
                                {
                                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                                  message: "Enter Valid Account Holder Name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "ledgerName"]}
                              className="inputBoxMb8 inputLabel33"
                              label={
                                <Space>
                                  Ledger Name
                                  <Tooltip
                                    title="Entered name will be Ledger's name in COA."
                                    placement="top"
                                    color="#4b5b95"
                                  >
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </Space>
                              }
                              rules={[
                                {
                                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                                  message: "Enter Valid Ledger Name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "accountNumber"]}
                              className="inputBoxMb8 inputLabel33"
                              label="Account Number"
                              rules={[
                                {
                                  pattern: new RegExp(/^\d{6,18}$/),
                                  message: "Enter Valid Account Number",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "accountType"]}
                              label="Account Type"
                              className="inputBoxMb8 inputLabel33"
                            >
                              <Select
                                placeholder="Account Type"
                                onChange={(val) => {
                                  const prevBankTypeValue = [...bankTypeValue];
                                  prevBankTypeValue[name] = val;
                                  setBankTypeValue(prevBankTypeValue);
                                }}
                                options={[
                                  {
                                    label: "Select Account Type",
                                    value: BankAccountTypes.All,
                                  },
                                  {
                                    label: "Saving Account",
                                    value: BankAccountTypes.SavingAccount,
                                  },
                                  {
                                    label: "Current Account",
                                    value: BankAccountTypes.CurrentAccount,
                                  },
                                  {
                                    label: "Cash Credit Account",
                                    value: BankAccountTypes.CashCreditAccount,
                                  },
                                  {
                                    label: "Overdraft Account",
                                    value: BankAccountTypes.OverdraftAccount,
                                  },
                                ]}
                                style={{
                                  width:
                                    window.innerWidth <= 425 ? "226px" : " ",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "bankName"]}
                              label="Bank Name"
                              className="inputBoxMb8 inputLabel33"
                              rules={[
                                {
                                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                                  message: "Enter Valid Bank Name",
                                },
                                {
                                  min: 3,
                                  max: 50,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "ifsCode"]}
                              label="IFSC Code"
                              className="inputBoxMb8 inputLabel33"
                              rules={[
                                {
                                  pattern: /^[A-Za-z]{4}[0][0-9]{6}$/,
                                  message: "Input valid IFSC Code",
                                },
                                // {
                                //   pattern: /^[A-Z]$/,
                                //   message: "Enter Capital letters",
                                // },
                              ]}
                            >
                              <Input
                                placeholder="BANK0123456"
                                maxLength={11}
                                onChange={(e: any) => {
                                  const capitalvalue =
                                    e.target.value.toUpperCase();
                                  form.setFieldValue(
                                    [name, "ifsCode"],
                                    capitalvalue
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "branch"]}
                              label="Branch"
                              className="inputBoxMb8 inputLabel33"
                              rules={[
                                {
                                  pattern: /^[A-Za-z][ A-Za-z]*$/,
                                  message: "Enter Valid Branch Name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "uPIId"]}
                              label="UPI Id"
                              className="inputBoxMb8 inputLabel33"
                              rules={[
                                {
                                  pattern:
                                    /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/,
                                  message: "Please enter a valid UPI ID.",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={14} justify={"space-between"}>
                          {bankTypeValue[index] ===
                            BankAccountTypes.CashCreditAccount ||
                          bankTypeValue[index] ===
                            BankAccountTypes.OverdraftAccount ? (
                            <Col lg={6} md={6} sm={10} xs={22}>
                              <Form.Item
                                name={[name, "odLimit"]}
                                label={
                                  bankTypeValue[index] ===
                                  BankAccountTypes.CashCreditAccount
                                    ? "CC Limit"
                                    : "OD Limit"
                                }
                                rules={[
                                  {
                                    pattern: /^[0-9]*$/,
                                    message: "Please enter a valid amount.",
                                  },
                                ]}
                                className="inputBoxMb8 inputLabel33"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          ) : (
                            <Col lg={6} md={6} sm={10} xs={22}></Col>
                          )}
                          <Col lg={6} md={6} sm={10} xs={22}>
                            <Row justify={"space-between"}>
                              <Col lg={6} md={6} sm={10} xs={10}>
                                {/* <Form.Item
                                  name={[name, "isDefault"]}
                                  label="Default"
                                  className="inputBoxMb8 inputLabel33"
                                >
                                  <Checkbox
                                    checked={bankDefaultChecked[name]}
                                    onChange={({ target }) => {
                                      setBankDefaultValueHandler(
                                        target.checked,
                                        name
                                      );
                                      //console.log("checkbox result: ", e);
                                    }}
                                  />
                                </Form.Item> */}
                              </Col>

                              <Col
                                // span={1}
                                // className="pb"
                                style={{
                                  // textAlign: "end",
                                  marginTop: "28px",
                                  // paddingRight: "10px",
                                }}
                              >
                                <Button
                                  style={{
                                    padding: "0px",
                                    borderColor: "#fff",
                                    boxShadow: "none",
                                  }}
                                  onClick={() => {
                                    remove(name);
                                    handleDelBankDetail(name);
                                  }}
                                >
                                  <DeleteTwoTone
                                    className="ca-delete-btn"
                                    twoToneColor="#ff879d"
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Divider style={{ margin: "7px" }} />
                        </Row>
                      </div>
                    ))}
                    <Form.Item className="inputBoxMb">
                      <Row key={"add Bank Detail"}>
                        <Col>
                          <Button
                            type="link"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Bank Detail
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            {/* Address Details */}
            <div>
              <Form.List name="addresses">
                {(fields, { add, remove }) => (
                  <>
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      className="dividertext"
                      style={{
                        marginTop: "11px",
                        marginBottom: "11px",
                        fontWeight: 600,
                      }}
                    >
                      Address Details
                    </Divider>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Row
                        key={key}
                        gutter={14}
                        style={{
                          justifyContent: width <= 375 ? "center" : "",
                        }}
                      >
                        <Col lg={7} md={7} sm={12}>
                          <Form.Item
                            name={[name, "type"]}
                            label="Addresses Type"
                            className="inputBoxMb inputLabel33 inputBoxMb8"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Select Address Type",
                            //   },
                            // ]}
                          >
                            <Select
                              placeholder="Type"
                              options={[
                                {
                                  label: "Registered Address",
                                  value: TypeOfAddress.RegisteredAddress,
                                },
                                {
                                  label: "Business Address",
                                  value: TypeOfAddress.BusinessAddress,
                                },
                                {
                                  label: "Branch Address",
                                  value: TypeOfAddress.BranchAddress,
                                },
                                {
                                  label: "Unit Address",
                                  value: TypeOfAddress.UnitAddress,
                                },
                                {
                                  label: "Godown Address",
                                  value: TypeOfAddress.GodownAddress,
                                },
                              ]}
                              style={{
                                width: window.innerWidth <= 425 ? "226px" : " ",
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[name, "addressName"]}
                            label="Address Name"
                            className="inputLabel33 inputBoxMb8"
                            rules={[
                              {
                                required: true,
                                message: "Please assign a name to address",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col lg={17} md={7} sm={12}>
                          <Form.Item
                            label="Billing Address"
                            className="inputLabel33 inputBoxMb8"
                          >
                            <Row gutter={14}>
                              <Col span={8}>
                                <Form.Item
                                  name={[name, "address", "building"]}
                                  className="inputBoxMb"
                                >
                                  <Input
                                    //  className="inputBgColor"
                                    placeholder="Building"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={[name, "address", "street"]}
                                  className="inputBoxMb"
                                >
                                  <Input placeholder="Street" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={[name, "address", "landmark"]}
                                  className="inputBoxMb"
                                >
                                  <Input
                                    // className="inputBgColor"
                                    placeholder="Landmark"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={14}>
                              <Col span={8}>
                                <Form.Item
                                  name={[name, "address", "city"]}
                                  className="inputBoxMb"
                                >
                                  <Input
                                    // className="inputBgColor"
                                    placeholder="City"
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  name={[name, "address", "pincode"]}
                                  className="inputBoxMb"
                                  rules={[
                                    {
                                      pattern: /^[0-9]+$/,
                                      message: "Please enter valid pincode",
                                    },
                                  ]}
                                >
                                  <Input
                                    // controls={false}
                                    style={{ width: "100%" }}
                                    // className="inputBgColor"
                                    placeholder="Pincode"
                                    maxLength={6}
                                    // onChange={(e) => {
                                    //   const newValue = e.target.value.replace(
                                    //     /[^0-9]/g,
                                    //     ""
                                    //   );
                                    //   form.setFieldValue([name,"address", "pincode"],
                                    //     newValue
                                    //   );
                                    //   console.log(
                                    //     "Current form values:",
                                    //     form.getFieldsValue()
                                    //   );
                                    // }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  className="inputBoxMb"
                                  name={[name, "address", "district"]}
                                >
                                  <Input
                                    // className="inputBgColor"
                                    placeholder="District"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={14}>
                              <Col span={8}>
                                <Form.Item
                                  name={[name, "address", "state"]}
                                  className="inputBoxMb"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Please select State",
                                  //   },
                                  // ]}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Please select State"
                                    options={Object.entries(stateOptions)?.map(
                                      ([key, x]: any) => ({
                                        value: x?.id,
                                        label: x?.name,
                                      })
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label.toString() ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={[name, "address", "country"]}
                                  className="inputBoxMb"
                                >
                                  <Input placeholder="Country" />
                                </Form.Item>
                              </Col>
                              <Col
                                span={8}
                                className="pb"
                                style={{
                                  textAlign: "end",
                                  paddingTop: "10px",
                                  paddingRight: "19px",
                                }}
                              >
                                {name > 0 && (
                                  <Button
                                    style={{
                                      padding: "0px",
                                      borderColor: "#fff",
                                      boxShadow: "none",
                                    }}
                                    onClick={() => remove(name)}
                                  >
                                    <DeleteTwoTone
                                      className="ca-delete-btn"
                                      twoToneColor="#ff879d"
                                    />
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                        <Divider style={{ margin: "7px" }} />
                      </Row>
                    ))}
                    <Form.Item className="inputBoxMb">
                      <Row key={"add contact"}>
                        <Col>
                          <Button
                            type="link"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            //disabled={fields[4]?.name === 4 ? true : false}
                          >
                            Add Address
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            {/*  Contact */}
            <div>
              <div>
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  className="dividertext"
                  style={{ marginTop: "11px", marginBottom: "11px" }}
                >
                  <p style={{ fontWeight: 600 }}>Contact Details</p>
                </Divider>
              </div>
              <div
                style={{
                  maxWidth: window.innerWidth <= 425 ? "800px" : "",
                  overflowX: "auto",
                }}
              >
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th
                        style={{ width: "20%" }}
                        className="textStart fw-500 "
                      >
                        Name
                      </th>
                      <th
                        style={{ width: "20%", paddingLeft: "6px" }}
                        className="textStart fw-500 "
                      >
                        Designation
                      </th>
                      <th
                        style={{ width: "15%", paddingLeft: "6px" }}
                        className="textStart fw-500"
                      >
                        Phone No.
                      </th>
                      <th
                        style={{ width: "21.5%", paddingLeft: "6px" }}
                        className="textStart fw-500"
                      >
                        Email
                      </th>
                      <th
                        style={{ width: "21.5%", paddingLeft: "6px" }}
                        className="textStart fw-500"
                      >
                        CC
                      </th>
                      <th style={{ width: "2%" }}></th>
                    </tr>
                  </thead>
                  <Form.List name="contactInformation">
                    {(fields, { add, remove }) => (
                      <>
                        <tbody>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <tr key={key}>
                              <td>
                                <Form.Item
                                  name={[name, "name"]}
                                  {...restField}
                                  // className="inputBoxMb8"
                                >
                                  <Input placeholder="Name" />
                                </Form.Item>
                              </td>
                              <td style={{ paddingLeft: "6px" }}>
                                <Form.Item
                                  name={[name, "designation"]}
                                  {...restField}
                                  // className="inputBoxMb8"
                                >
                                  <Input placeholder="Designation" />
                                </Form.Item>
                              </td>
                              <td style={{ paddingLeft: "6px" }}>
                                <Form.Item
                                  name={[name, "phone"]}
                                  {...restField}
                                  // className="inputBoxMb8"
                                  rules={[
                                    {
                                      min: 10,
                                      max: 10,
                                      message: "Enter valid Phone No.",
                                      pattern: /^[6-9]{1}[0-9]{9}$/,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="9876543210"
                                    // suffix={
                                    //   <img src={phoneImg} alt="phone"></img>
                                    // }
                                    // addonBefore={
                                    //   <Form.Item
                                    //     name={[name, "prefix"]}
                                    //     {...restField}
                                    //     noStyle
                                    //   >
                                    //     <Select
                                    //       style={{ width: 67 }}
                                    //       options={[
                                    //         { value: "0", label: "+81" },
                                    //         { value: "1", label: "+91" },
                                    //       ]}
                                    //     />
                                    //   </Form.Item>
                                    // }
                                    // maxLength={10}
                                    // onChange={(e) => {
                                    //   if (e.target.value) setTouched(true);
                                    //   else setTouched(false);
                                    // }}
                                    style={{
                                      width:
                                        window.innerWidth <= 425 ? "160px" : "",
                                    }}
                                  />
                                </Form.Item>
                              </td>
                              <td style={{ paddingLeft: "6px" }}>
                                <Form.Item
                                  name={[name, "email"]}
                                  {...restField}
                                  // className="inputBoxMb8"
                                  rules={[
                                    {
                                      type: "email",
                                      message: "Enter valid email address",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="user@example.com"
                                    // suffix={
                                    //   // <img src={emailImg} alt="email"></img>
                                    // }
                                    // onChange={(e) => {
                                    //   if (e.target.value) setTouched(true);
                                    //   else setTouched(false);
                                    // }}
                                    style={{
                                      width:
                                        window.innerWidth <= 425 ? "180px" : "",
                                    }}
                                  />
                                </Form.Item>
                              </td>
                              <td style={{ paddingLeft: "6px" }}>
                                <Form.Item
                                  name={[name, "ccTo"]}
                                  {...restField}
                                  // className="inputBoxMb8"
                                  rules={[
                                    {
                                      type: "email",
                                      message: "Enter valid email address",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="user@example.com"
                                    // suffix={
                                    //   // <img src={emailImg} alt="email"></img>
                                    // }
                                    // onChange={(e) => {
                                    //   if (e.target.value) setTouched(true);
                                    //   else setTouched(false);
                                    // }}
                                    style={{
                                      width:
                                        window.innerWidth <= 425 ? "180px" : "",
                                    }}
                                  />
                                </Form.Item>
                              </td>

                              <td>
                                <Form.Item className="inputBoxMb8 mleft">
                                  <Checkbox
                                    style={{ paddingBottom: "25px" }}
                                    // checked={checkbox === index}
                                    // onChange={() => {
                                    //   if (checkbox !== index) {
                                    //     setCheckbox(index);
                                    //     console.log("checkbox", checkbox);
                                    //     console.log("index", index);
                                    //   }
                                    // }}
                                  />
                                </Form.Item>
                              </td>
                              <td className="pb">
                                {fields.length > 1 && (
                                  <Button
                                    style={{
                                      padding: "0px",
                                      borderColor: "#fff",
                                      boxShadow: "none",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <DeleteTwoTone
                                      className="ca-delete-btn"
                                      onClick={() => {
                                        // if (index === 0 && checkbox !== 0) {
                                        //   setCheckbox(checkbox - 1);
                                        // } else if (
                                        //   checkbox === 0 &&
                                        //   fields.length > 1
                                        // ) {
                                        //   // console.log("first delete");
                                        //   setCheckbox(0);
                                        // } else if (
                                        //   checkbox !== 0 &&
                                        //   checkbox !== 4
                                        // ) {
                                        //   // console.log("center delete");
                                        //   checkbox !== index - 1 &&
                                        //     setCheckbox(checkbox - 1);
                                        // } else if (
                                        //   fields.length === index + 1 &&
                                        //   fields.length !== 1
                                        // ) {
                                        //   // console.log("// last Delete");
                                        //   setCheckbox(index - 1);
                                        // } else if (
                                        //   fields.length === index + 1 &&
                                        //   fields.length !== 1
                                        // ) {
                                        //   setCheckbox(index - 1);
                                        // }
                                        return fields.length === 1
                                          ? ""
                                          : remove(name);
                                      }}
                                      twoToneColor="#ff879d"
                                    />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>

                        <tfoot>
                          <tr>
                            <td>
                              <Form.Item className="inputBoxMb">
                                <Row>
                                  <Col>
                                    <Button
                                      type="link"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}
                                      disabled={
                                        fields[4]?.name === 4 ? true : false
                                      }
                                    >
                                      Add Contact
                                    </Button>
                                  </Col>
                                </Row>
                              </Form.Item>
                            </td>
                          </tr>
                        </tfoot>
                      </>
                    )}
                  </Form.List>
                </table>
              </div>
              {/* TCS Details */}
              {/* <Divider
                orientation="left"
                orientationMargin="0"
                className="dividertext"
                style={{ marginTop: "11px", marginBottom: "11px" }}
              >
                <p style={{ fontWeight: 600 }}>TCS Detail</p>
              </Divider>*/}
              <Row
                gutter={14}
                style={{ justifyContent: width <= 375 ? "center" : "" }}
              >
                {/* ----- TCS ----- */}
                {/* <Col lg={6} md={8} sm={12} xs={24}>
                  <Form.Item
                    name="tcsApplicability"
                    label="TCS Applicability"
                    className="inputBoxMb8 inputLabel33"
                    valuePropName="checked"
                  >
                    <Checkbox
                    // onChange={(e) => setShowDetailsTSC(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
                <>
                  <Col lg={14} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="tcsNture"
                      label="TCS Nature"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={4} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="tcsRate"
                      label="TCS Rate"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}
                {/* </> */}
              </Row>
            </div>
          </Form>
        </div>
      </Modal>
      <AddClientContacts open={contactOpen} setOpen={setContactOpen} />
    </>
  );
};

export default AddOrEditClient;
