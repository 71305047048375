import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
  Typography,
  Upload,
} from "antd";

import {
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import SalesService, { CAPItemTypes } from "../../Services/SalesService";
import deleteImg from "../../../images/deletebutton.svg";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { Utils } from "../../../Utilities/Utils";
import { dayjs } from "../../../Utilities/dayjs";
import "../../Common/Acc_Common.css";
import "../../Common/AddPageCommon.css";
import noteContext from "../../Context/StyleContext";
import { notificationContext } from "../../Common/PageRoute";
import TextArea from "antd/es/input/TextArea";
import TcsModel from "./TcsModel";
import TdsModel from "./TdsModel";
import AddItems from "../Purchase/AddItems";
import PartyService from "../../Services/PartyService";
import { percentCal, percentToRs } from "../../Common/CAPComponents";
import { BodySkeleton } from "../../../Common/Skeleton";
import AddBankAccount from "../Banking/Banks/AddBankAccountModel";
import LedgerService, {
  SearchLedgerByGroupsTypes,
  TypeOfAddress,
} from "../../Services/LedgerService";
import Card from "antd/es/card/Card";
import { Footer } from "antd/es/layout/layout";
import AddSalesInvReferenceModel from "./AddSalesInvReferenceModel";
import VoucherServices, {
  AddressEnum,
  DiscountType,
  Freight_Type,
  Party_Tax,
  VoucherStatus_New,
  Voucher_Type,
  convertToDays,
} from "../../Services/VoucherServices";
import { AddLedger } from "../Master/Ledger/AddLedger";
import AddSalesInvoiceDespatchDetails from "./AddSalesInvoiceDespatchDetails";
import AddSalesInvoiceExportDetail from "./AddSalesInvoiceExportDetail";
import QuotationServices from "../../Services/QuotationServices";
// import { voucherType } from "../Reports/VoucherDetailsByLedger";
// import { CompanyGSTTypes } from "../../../Types";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import {
  PurchaseDropdownItems,
  SalesDropdownItems,
} from "./SalesandPurchaseDropdownList";
import EntryLevelKeys from "../../Common/EntryLevelKeys";
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook";
import {
  Discount_Policy,
  RoundOff_Type,
  SettingsSections,
  vouchersSettings,
} from "../../Services/SettingService";
import DraftService from "../../Services/DraftService";
import TermsAndConditionModal from "./Terms&ConditionModal";
import TermsAndConditionsService from "../../Services/TermsAndConditionsService";
import { voucherType } from "../Reports/VoucherDetailsByLedger";
import DocumentPreview from "../../Common/DocumentPreview";
import { PanCardType, Voucher_Nature } from "../../Services/PurchaseService";
import { useUser } from "../../../Context/UserContext";
import { getIndianStates } from "../../Services/ClientService";
import { LocalChunkSize } from "papaparse";
import TdsTcsServices, {
  Transaction_Type,
} from "../../Services/TdsTcsServices";
import AddTcsTaxRate from "../TcsTds/Tcs/AddTcsTaxRate";
import AddTdsTaxRate from "../TcsTds/Tds/AddTdsTaxRate";

const AddSalesInvoice = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef: any = useRef(null);
  const { windowWidth } = useContext(noteContext);
  const { currentClient, companySettings } = useClient();
  const { getAllIndStates, getUser } = useUser();
  const stateOptions: any = getAllIndStates();

  // console.log("stateOptions", stateOptions, getUser);

  const {
    updateId,
    salesOrderId,
    quotationId,
    partyId,
    purchaseOrderId,
    cloneId,
    draftId,
  } = useParams();
  const { openNotification } = useContext(notificationContext);
  const [trigger, setTrigger] = useState(false);

  let splitPathInVchType: Voucher_Type;
  // let splitPathLedgerByGroupsType: SearchLedgerByGroupsTypes;
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = Voucher_Type.SalesVoucher;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Sales;
  } else {
    splitPathInVchType = Voucher_Type.PurchaseVoucher;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Purchase;
  }

  const [dueOnSetting, setDueOnSetting] = useState<number>(
    convertToDays(
      companySettings?.setting?.dueOn?.duration!,
      companySettings?.setting?.dueOn?.durationUnit
    )
  );
  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);
  const [dueOnParty, setDueOnParty] = useState<number>(0);
  const [dateFiled, setDateFiled] = useState<any>();
  const [editDate, setEditDate] = useState<any>();
  const [editDueDate, setEditDueDate] = useState<any>();
  const [balance, setBalance] = useState<number>(0);
  const [totalTcs, setTotalTcs] = useState<number>(0);
  const [totalTds, setTotalTds] = useState<number>(0);
  const [tdsOrTcs, setTdsOrTcs] = useState<number>(
    splitPathInVchType === Voucher_Type.SalesVoucher ? 1 : 0
  );
  // const [totalgst, setTotalgst] = useState<number>(0);
  // const [totalQty, setTotalQty] = useState<number>(0);
  const [isOpenDueDate, setIsDueDate] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemLength, setItemLength] = useState<number>(0);
  const [searchItem, setSearchItem] = useState<string>("");
  // const [isInCash, setIsInCash] = useState<boolean>(false);
  const [itemsOption, setItemsOption] = useState<any[]>([]);
  const [deleteItemList, setDeleteItemList] = useState<any>();
  // const [invoiceTotal, setInvoiceTotal] = useState<number>(0.0);
  const [purchaseOrderRef, setPurchaseOrderRef] = useState<string | null>(null);
  const [salesInvoiceNo, setSalesInvoiceNo] = useState<string>("");
  const [editParty, setEditParty] = useState<{ id: string }>({ id: "" });
  const [selectedParty, setSelectedParty] = useState<string>("");
  const [isGSTEnabled, setGstEnabled] = useState<boolean>(true);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [ledgerconfirmation, setLedgerConfirmation] = useState<boolean>(false);
  const [tcsModelOpen, setTcsModelOpen] = useState<boolean>(false);
  const [tdsModelOpen, setTdsModelOpen] = useState<boolean>(false);
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  const [onlyEditTimeCustomerOptions, setOnlyEditTimeCustomerOptions] =
    useState<boolean>(false);
  // const [addressList, setAddressList] = useState<any[]>([]);
  const [effectiveLedger, setEffectiveLedger] = useState<any[]>([]);
  const [currentCustomerState, setCurrentCustomerState] = useState<number>(
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? -1
      : currentClient?.state!
  );
  const [purchasePartyState, setPurchasePartyState] = useState<number>();
  const [updateAndNewButton, setUpdateAndNewButton] = useState<boolean>(false);
  const [saveNewLoading, setSaveNewLoading] = useState<boolean>(false);
  // const [openCustomerDrawer, setOpenCustomerDrawer] = useState<boolean>(false);
  const [initialRender, seInitialRender] = useState<boolean>(false);
  const [deleteBankAccountRow, setDeleteBankAccountRow] = useState<any>();
  const [bankConfirmation, setBankConfirmation] = useState<boolean>(false);
  // const [countDraft, setCountDraft] = useState<boolean>(false);
  const [searchBankAccount, setSearchBankAccount] = useState<string>("");
  const [isSaveDraft, setIsSaveDraft] = useState<Boolean>(true);
  const [bankAccountAmt, setBankAccountAmt] = useState<number>(0);
  const [termNCondData, setTermNCondData] = React.useState<any>();
  const [voucherNumberLoader, setVoucherNumberLoader] = React.useState(false);
  const [customerAddress, setCustomerAddress] = useState<any>(
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? []
      : currentClient?.addresses
  );
  // const [addressEnum, setAddressEnum] = useState<number>(0);
  const [openModel, setOpenModel] = useState<{ type: string; param: any }>({
    type: "",
    param: {},
  });
  // const [openGroupModel, setOpenGroupModel] = React.useState<{
  //   open: boolean;
  //   param: any;
  // }>({
  //   open: false,
  //   param: "",
  // });
  const [newItem, setNewItem] = useState<any>({
    data: {},
    isSaved: false,
  });
  const [itemFields, setItemFields] = useState<any>({
    field: 0,
    index: 0,
    add: null,
  });
  // const [searchCustomer, setSearchCustomer] = useState<{
  //   search: string;
  //   length: number;
  // }>({
  //   search: " ",
  //   length: 15,
  // });

  const [presetsDueDate, setPresetsDueDate] = useState<any>({
    label: 0,
    value: "",
  });

  const [footerTotal, setFooterTotal] = useState<{
    qty: number;
    unitPrice: number;
    taxableAmount: number;
    discount: number;
    gst: number;
    amount: number;
  }>({
    qty: 0,
    unitPrice: 0,
    taxableAmount: 0,
    discount: 0,
    gst: 0,
    amount: 0,
  });

  const [footerLedger, setFooterLedger] = useState<{
    ledgerAmount: number;
    ledgerGstAmount: number;
    ledgerTotalAmount: number;
  }>({
    ledgerAmount: 0,
    ledgerGstAmount: 0,
    ledgerTotalAmount: 0,
  });
  const [roundOffAmount, setRoundoffAmount] = useState<number>(0.0);
  const [cashDiscountRs, setCashDiscountRs] = useState<number>(0.0);
  const [itemId, setItemId] = React.useState<any>();
  const [loadItemAPI, setloadItemAPI] = React.useState<boolean>(false);
  const [bankAccountList, setBankAccountList] = useState<any[]>([]);
  // const [directExpenses, setDirectExpenses] = useState<boolean>(false);
  const [ledgerLength, setLedgerLength] = useState<number>(0);
  const [freightAndInsuranceOptions, setFreightAndInsuranceOptions] =
    useState<any>([]);
  const [deleteLedgerList, setDeleteLedgerList] = useState<any>();
  const [ledgerAmount, setLedgerAmount] = useState<any>();
  const [showFreightInsurance, setShowFreightInsurance] =
    useState<boolean>(false);
  const [updateTimeShowFreightIns, setUpdateTimeShowFreightIns] =
    useState<boolean>(false);
  const [updateTimeData, setUpdateTimeData] = React.useState<{
    contactDetails: boolean;
    shippingAddress: boolean;
    friInsuranceOtherCharge: boolean;
    receipt: boolean;
    cashDiscount: boolean;
    tradeDiscount: boolean;
    isGSTVoucher: boolean;
    showItemDescription: boolean;
    roundOff: boolean;
    showDispatchDetail: boolean;
    showFreightType: boolean;
    showRCM: boolean;
  }>({
    contactDetails: false,
    shippingAddress: false,
    friInsuranceOtherCharge: false,
    receipt: false,
    cashDiscount: false,
    tradeDiscount: false,
    isGSTVoucher: false,
    showItemDescription: false,
    roundOff: false,
    showDispatchDetail: false,
    showFreightType: false,
    showRCM: false,
  });
  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<any>();
  const [oldPartyRecepitDetails, setOldPartyRecepitDetails] = useState<any[]>(
    []
  );
  const [newPartyRecepitDetails, setNewPartyRecepitDetails] = useState<any[]>(
    []
  );

  // settingsSections: SettingsSections,
  //   allVouchersSettings?: vouchersSettings[]
  // ) => {
  //   const vouchersTypeTem = allVouchersSettings?.filter(
  //     (x: vouchersSettings) => x?.settingType === settingsSections

  const getVoucherTitle =
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? companySettings?.setting?.vouchersSettings.find(
          (x: any) => x?.settingType === SettingsSections.Invoice
        )
      : companySettings?.setting?.vouchersSettings.find(
          (x: any) => x?.settingType === SettingsSections.PInvoice
        );

  const [settingControl, setSettingControl] = useState<{
    title?: string;
    showContactDetail: boolean;
    discountPolicy: Discount_Policy;
    showReceipt: boolean;
    showOtherCharges: boolean;
    showShippingAddress: boolean;
    showItemDescription: boolean;
    autoRoundOff: boolean;
    roundOffType: RoundOff_Type;
    showDispatchDetail: boolean;
    showFreightType: boolean;
    showRCM: boolean;
    showTcsTds: boolean;
  }>({
    title:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.title
        : getVoucherTitle?.settings?.billSettings.title,
    showContactDetail:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.showContactDetail!
        : getVoucherTitle?.settings?.billSettings?.showContactDetail!,
    discountPolicy:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.discountPolicy!
        : getVoucherTitle?.settings?.billSettings?.discountPolicy!,
    showReceipt:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.showReceipt!
        : getVoucherTitle?.settings?.billSettings?.showReceipt!,
    showOtherCharges:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.showOtherCharges!
        : getVoucherTitle?.settings?.billSettings?.showOtherCharges!,
    showShippingAddress:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.showShippingAddress!
        : getVoucherTitle?.settings?.billSettings?.showShippingAddress!,
    showItemDescription:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.showItemDescription!
        : getVoucherTitle?.settings?.billSettings?.showItemDescription!,
    autoRoundOff:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.autoRoundOff!
        : getVoucherTitle?.settings?.billSettings?.autoRoundOff!,
    roundOffType:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.roundOffType!
        : getVoucherTitle?.settings?.billSettings?.roundOffType!,
    showDispatchDetail:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.showDispatchDetail!
        : getVoucherTitle?.settings?.billSettings?.showDispatchDetail!,
    showFreightType:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.settings?.invoiceSettings?.showFreightType!
        : getVoucherTitle?.settings?.billSettings?.showFreightType!,
    showRCM:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? false
        : getVoucherTitle?.settings?.billSettings?.showRCM!,
    showTcsTds:
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? companySettings?.setting?.tcsDetails?.isTCSEnabled!
        : companySettings?.setting?.tdsDetails?.isTDSEnabled!,
  });
  const [voucherStatus, setVoucherStatus] = React.useState<VoucherStatus_New>(
    VoucherStatus_New.Active
  );

  const [selectCustomizationTemplete, setSelectCustomizationTemplete] =
    React.useState<string>(
      // splitPathInVchType === Voucher_Type.SalesVoucher
      //   ?  getVoucherTitle?.settings?.invoiceSettings?.title
      //   : getVoucherTitle?.settings?.billSettings?.title
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.title!
        : getVoucherTitle?.title!
    );

  // companySettings?.setting?.vouchersSettings;

  React.useEffect(() => {
    const findVoucharType = companySettings?.setting?.vouchersSettings?.find(
      (x) => x?.title === selectCustomizationTemplete
    );

    const voucherData =
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? findVoucharType?.settings?.invoiceSettings
        : findVoucharType?.settings?.billSettings;

    setSettingControl({
      title: voucherData?.title,
      showContactDetail: voucherData?.showContactDetail!,
      discountPolicy: voucherData?.discountPolicy!,
      showReceipt: voucherData?.showReceipt!,
      showOtherCharges: voucherData?.showOtherCharges!,
      showShippingAddress: voucherData?.showShippingAddress!,
      showItemDescription: voucherData?.showItemDescription!,
      autoRoundOff: voucherData?.autoRoundOff!,
      roundOffType: voucherData?.roundOffType!,
      showDispatchDetail: voucherData?.showDispatchDetail!,
      showFreightType: voucherData?.showFreightType!,
      showRCM:
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? false
          : voucherData?.showRCM!,
      showTcsTds: settingControl?.showTcsTds,
    });
    // setTermsAndConditionField({
    //   templateId:
    //     splitPathInVchType === Voucher_Type.SalesVoucher
    //       ? voucherData?.tnCTemplateId
    //       : voucherData?.tnCTemplateId,
    //   tnCText:
    //     splitPathInVchType === Voucher_Type.SalesVoucher
    //       ? voucherData?.termsnConditions
    //       : voucherData?.termsnConditions,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCustomizationTemplete]);

  const [termsAndConditionField, setTermsAndConditionField] =
    React.useState<any>({
      templateId:
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? getVoucherTitle?.settings?.invoiceSettings?.tnCTemplateId
          : getVoucherTitle?.settings?.billSettings?.tnCTemplateId,
      tnCText:
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? getVoucherTitle?.settings?.invoiceSettings?.termsnConditions
          : getVoucherTitle?.settings?.billSettings?.termsnConditions,
    });
  const [transactionId, setTransactionId] = useState<{
    partyId: string;
    effectiveId: string;
  }>({ partyId: "", effectiveId: "" });
  const [updateInPartyId, setUpdateInPartyId] = useState<string>("");

  const [invoiceDespatchDetails, setInvoiceDespatchDetails] = useState<any>();
  const [refreshNumber, setRefreshNumber] = useState<boolean>(false);
  const [saveAndNew, setSaveAndNew] = useState<boolean>(false);
  const [updateAndNew, setUpdateAndNew] = useState<boolean>(false);
  const [orderListOptions, setOrderListOptions] = useState<
    { voucherId: string; voucherNumber: string }[]
  >([]);
  const [selectedOtherExpenses, setSelectedOtherExpenses] = useState<any[]>([]);
  const [selectedBankLedger, setSelectedBankLedger] = useState<string[]>([]);
  const [selectedBankBalance, setSelectedBankBalance] = useState<number[]>([]);
  const [selectedBankPartyOrTax, setSelectedBankPartyOrTax] = useState<
    Party_Tax[]
  >([]);
  const [editInvBankDetails, setEditInvBankDetails] = useState<any[]>([]);
  const [mode, setMode] = useState<boolean>(false);
  // const [invoiceExportDetails, setInvoiceExportDetails] = useState<any>();
  // const [selectCondition, setSelectCondition] = useState<any>();
  const [isSameBillingAddress, setIsSameBillingAddress] =
    useState<boolean>(false);
  const [deleteUploadId, setDeleteUploadId] = React.useState<string[]>([]);
  const [previewImage, setPreviewImage] = React.useState<{
    isBaseConvert: boolean;
    path: any;
  }>({ isBaseConvert: false, path: "" });
  const [voucherNature, setVoucherNature] = React.useState<Voucher_Nature>(
    Voucher_Nature.Regular
  );
  const [tdsList, setTdsList] = React.useState<any[]>([]);
  const [tcsList, setTcsList] = React.useState<any[]>([]);
  const [tdsListTrigger, setTdsListTrigger] = React.useState<boolean>(false);
  const [tcsListTrigger, setTcsListTrigger] = React.useState<boolean>(false);
  const [tdsPostDetail, setTdsPostDetail] = React.useState<{
    formNo: string;
    transactionType: Transaction_Type;
  }>({ formNo: "", transactionType: Transaction_Type.Domestic });
  const [partyPanCard, setPartyPanCard] = React.useState<PanCardType>(
    PanCardType.notAvailable
  );
  const [tcsTdsPercentage, setTcsTdsPercentage] = React.useState<number>(0.0);
  const [isPartTDSOrTDS, setIsPartTDSOrTDS] = React.useState<{
    tds: Boolean;
    tcs: Boolean;
  }>({ tds: false, tcs: false });
  const [partyDropDownLoading, setPartyDropDownLoading] =
    React.useState<boolean>(false);
  const [ledgerDropDownLoading, setLedgerDropDownLoading] =
    React.useState<boolean>(false);
  const [orderDropDownLoading, setOrderDropDownLoading] =
    React.useState<boolean>(false);
  const [bankAccountDropDownLoading, setbankAccountDropDownLoading] =
    React.useState<boolean>(false);
  const [itemDropDownLoading, setItemDropDownLoading] =
    React.useState<boolean>(false);
  const [otherExpensiveDropDownLoading, setOtherExpensiveDropDownLoading] =
    React.useState<boolean>(false);

  const handleDataFromChild = (data: any) => {
    console.log("data", data);

    setInvoiceDespatchDetails(data);
    setOpenModel({ type: "", param: "" });
  };

  const gstOption =
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? companySettings?.setting?.gstDetails?.gst
      : companySettings?.gsts;

  // const purchaseGstOption = companySettings?.gsts;
  const isStateSame =
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? currentCustomerState === currentClient?.state!
      : (currentCustomerState ?? currentClient?.state!) === purchasePartyState;

  const amountInNumber = (val: any, key: any) => {
    return Utils.getNumber(val?.amount?.[key]);
  };

  /* React.useEffect(() => {
    console.log("BankTransactionData", location?.state);
  }, []);*/

  const price = (curr: any) => amountInNumber(curr, "price");
  const qty = (curr: any) => amountInNumber(curr, "qty");
  const discount = (curr: any) => amountInNumber(curr, "discount");
  const taxRate = (curr: any) => Utils.getNumber(curr?.amount?.tax?.key);
  const netAmount = (curr: any) => price(curr) * qty(curr);

  const discountRupee = (curr: any, percentDiscountAll?: number) => {
    if (percentDiscountAll || percentDiscountAll === 0) {
      return percentCal(netAmount(curr), percentDiscountAll);
    } else return percentCal(netAmount(curr), discount(curr));
  };

  const amountWithoutTax = (curr: any, percentDiscountAll?: number) =>
    netAmount(curr) - discountRupee(curr, percentDiscountAll);

  const itemTaxAmt = (curr: any, percentDiscountAll?: number) => {
    // console.log("itemTaxAmt1`", curr);
    return amountWithoutTax(curr, percentDiscountAll) * (taxRate(curr) / 100);
  };

  const amountWithTax = (curr: any, percentDiscountAll?: number) =>
    amountWithoutTax(curr, percentDiscountAll) +
    itemTaxAmt(curr, percentDiscountAll);
  // const amountWithOutTax = (curr: any) => amountWithoutTax(curr);

  const amount = (curr: any) => amountInNumber(curr, "amount");
  const gstrate = (curr: any) => Utils.getNumber(curr?.gstRate?.title);
  const gstamount = (curr: any) =>
    percentCal(Utils.getNumber(curr?.amount), gstrate(curr));

  const ledgertotalamout = (curr: any) =>
    Utils.getNumber(curr?.amount) + gstamount(curr);

  const setDefaultEffectedAccount = (voucherTitle: string) => {
    const ledger = companySettings?.setting?.defaultLedgers?.find(
      (x) => x?.voucherTitle === voucherTitle
    );
    if (ledger) {
      form.setFieldValue("effectiveLedger", {
        value: ledger?.defaultLedgerId,
      });
    }
  };

  // Save & New
  useEffect(() => {
    if (saveAndNew || updateAndNew) {
      setFooterTotal({
        qty: 0,
        unitPrice: 0,
        taxableAmount: 0,
        discount: 0,
        gst: 0,
        amount: 0,
      });
      form.resetFields();
      setFooterLedger({
        ledgerAmount: 0,
        ledgerGstAmount: 0,
        ledgerTotalAmount: 0,
      });
      setBankAccountAmt(0);
      // form.setFieldValue("dueDate", dayjs());

      form.setFieldValue("dueDate", dayjs(dateFiled).add(dueOnSetting, "d"));

      // splitPathInVchType === Voucher_Type.SalesVoucher
      //   ? setDefaultEffectedAccount(Voucher_Type.SalesVoucher)
      //   : setDefaultEffectedAccount(Voucher_Type.PurchaseVoucher);

      setDefaultEffectedAccount(selectCustomizationTemplete);

      setRefreshNumber(!refreshNumber);
      // setInvoiceTotal(0.0);
      setItemLength(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAndNew, updateAndNew]);

  console.log("termNCondData", termNCondData);

  // Date Api
  useEffect(() => {
    const getDate = async () => {
      if (currentClient?.id && !updateId && !location.state?.ledgerId) {
        await VoucherServices.getMaxVoucherDate(
          currentClient?.id!,
          splitPathInVchType
        ).then((res) => {
          const givenDate = new Date(res?.result);
          const currentTime = new Date();

          // Set the time part of givenDate to match currentTime
          givenDate?.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
          form.setFieldValue("date", dayjs(givenDate));

          // console.log("givenDate", givenDate);

          form.setFieldValue(
            "dueDate",
            dayjs(givenDate).add(
              convertToDays(
                companySettings?.setting?.dueOn?.duration!,
                companySettings?.setting?.dueOn?.durationUnit
              ),
              "d"
            )
          );
          setDateFiled(givenDate);
        });
      } else if (location.state?.ledgerId) {
        // console.log(
        //   "dataaaa..........",
        //   dayjs(location?.state?.BankTransactionData?.date, "DD/MM/YYYY")
        // );
        const givenDate = new Date(
          dayjs(
            location?.state?.BankTransactionData?.date,
            "DD/MM/YYYY"
          ).toISOString()
        );
        // dayjs(location?.state?.BankTransactionData?.date)
        const currentTime = new Date();

        // Set the time part of givenDate to match currentTime
        givenDate?.setHours(
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
          currentTime.getMilliseconds()
        );
        form.setFieldValue("date", dayjs(givenDate));

        form.setFieldValue(
          "dueDate",
          dayjs(givenDate).add(
            convertToDays(
              companySettings?.setting?.dueOn?.duration!,
              companySettings?.setting?.dueOn?.durationUnit
            ),
            "d"
          )
        );

        form.setFieldValue(["bankDts", 0], {
          name: {
            value: location.state?.ledgerId,
          },
          amount:
            location?.state?.BankTransactionData?.moneyIn ||
            location?.state?.BankTransactionData?.moneyOut,
          date: givenDate,
        });

        setBankAccountAmt(
          location?.state?.BankTransactionData?.moneyIn ||
            location?.state?.BankTransactionData?.moneyOut
        );

        setDateFiled(givenDate);
      }
    };
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, splitPathInVchType]);

  React.useEffect(() => {
    setSelectCustomizationTemplete(
      splitPathInVchType === Voucher_Type.SalesVoucher
        ? getVoucherTitle?.title!
        : getVoucherTitle?.title!
    );
  }, [splitPathInVchType]);

  useEffect(() => {
    const getInitialValue = async () => {
      // debugger;
      // setSelectCustomizationTemplete(
      //   splitPathInVchType === Voucher_Type.SalesVoucher
      //     ? getVoucherTitle?.settings?.invoiceSettings?.title
      //     : getVoucherTitle?.settings?.billSettings.title
      // );
      if (
        (currentClient?.id && !updateId && !draftId && dateFiled) ||
        (updateAndNew && dateFiled)
      ) {
        setVoucherNumberLoader(true);
        // setLoading(true);
        await VoucherServices.getVoucherMaxNo(
          currentClient?.id!,
          splitPathInVchType,
          dayjs(dateFiled).endOf("day").toISOString(),
          selectCustomizationTemplete
        )
          .then((res) => {
            // console.log("initial value", res.result);
            form.setFieldValue("number", res?.result);
            setSalesInvoiceNo(res?.result);
            setVoucherNumberLoader(false);
            setDefaultEffectedAccount(selectCustomizationTemplete);
          })
          .catch((err: any) => {
            openNotification("error", err.message);
            setVoucherNumberLoader(false);
          });
        // setVoucherNumberLoader(false);
      }
    };
    getInitialValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    refreshNumber,
    cloneId,
    dateFiled,
    // splitPathInVchType,
    selectCustomizationTemplete,
  ]);

  // console.log(
  //   "initial value1111111111",
  //   companySettings?.setting?.invoice?.showReceipt,
  //   !companySettings?.setting?.invoice?.showReceipt
  // );

  // -------- Edit Sales Invoice Get API ---------
  useEffect(() => {
    if (
      (updateId || cloneId || draftId) &&
      (settingControl?.showReceipt || editInvBankDetails?.length > 0
        ? bankAccountList?.length > 0
        : true) &&
      customerOptions?.length > 0
    ) {
      setLoading(true);
      const editData = async (id: any) => {
        try {
          const res = draftId
            ? await DraftService.getByDraftId(currentClient?.id!, draftId)
            : await VoucherServices.getVoucharById(
                currentClient?.id!,
                id,
                splitPathInVchType === Voucher_Type.SalesVoucher
                  ? Voucher_Type.SalesVoucher
                  : Voucher_Type.PurchaseVoucher
              );

          if (res?.result) {
            // debugger;
            setSelectedParty(res.result?.partyLedger[0]?.id);
            setSelectCustomizationTemplete(res.result?.voucherTitle);
            setVoucherNature(res.result?.nature);
            setTermsAndConditionField(res?.result?.termsNConditions);
            setMode(res.result?.isPurchaseJVVoucher);
            setIsSameBillingAddress(res?.result?.isShippingAddressSame);
            setSelectCustomizationTemplete(res.result?.voucherTitle);
            // CustomerSelect(res.result?.partyLedger[0]?.id);
            setUpdateInPartyId(res.result?.partyLedger[0]?.id);

            // Tcs & Tds Party condition show & hinde
            const partyDetail = customerOptions.find(
              (x) => x.id === res.result?.partyLedger[0]?.id
            );
            setIsPartTDSOrTDS({
              tds: partyDetail?.deductTDSinSameVoucher,
              tcs: partyDetail?.ledgerTCSApplicability,
            });

            setOldPartyRecepitDetails(
              res.result?.partyLedger[0]?.ref?.map((v: any, index: number) => {
                return {
                  key: index,
                  // date: dayjs(v?.date),
                  value: v?.value,
                  // id: v?.id,
                  // refNo: v?.name,
                  refType: v?.refType,
                  id:
                    index === 0
                      ? {
                          value: v?.id,
                          label:
                            v?.refType === 0 ? res?.result?.number : v?.name,
                          title: v?.parentRef,
                        }
                      : {
                          value: v?.id,
                          label: v?.name,
                          title: v?.parentRef,
                        },
                  dueAmount: v?.dueAmount,
                };
              })
            );
            // setEditParty({ id: res.result?.partyLedger[0]?.id });
            setFooterTotal({
              qty: res.result?.amountSummary?.totalQty,
              unitPrice: res.result?.amountSummary?.totalPrice,
              taxableAmount:
                res.result?.amountSummary?.taxableAmount +
                res.result?.amountSummary?.totalDis,
              discount: res.result?.amountSummary?.totalDis,
              gst: res.result?.amountSummary?.totalItemGSTAmt,
              amount:
                Math.abs(res.result?.effectiveLedger[0]?.amount) +
                res.result?.amountSummary?.totalItemGSTAmt,
            });
            setFooterLedger({
              ledgerAmount: res.result?.amountSummary?.cifExpenses ?? 0,
              ledgerGstAmount: res.result?.amountSummary?.totalCIFGSTAmt ?? 0,
              ledgerTotalAmount:
                res.result?.amountSummary?.cifExpenses +
                res.result?.amountSummary?.totalCIFGSTAmt,
            });
            setBalance(res.result.amountSummary?.receivedAmount);
            setRoundoffAmount(
              res.result?.amountSummary?.roundOff?.roundOffAmount
            );
            setCashDiscountRs(
              res.result?.amountSummary?.cashDiscount?.discountAmount ?? 0
            );

            setTotalTds(res.result?.amountSummary?.tdsAmt);
            setTotalTcs(res.result?.amountSummary?.tcsAmt);
            setTdsOrTcs(res.result?.tcsApplicable ? 1 : 0);
            setTcsTdsPercentage(
              res.result?.tcsApplicable
                ? res.result?.tcsDetail?.rate
                : res.result?.tdsDetail?.rate
            );
            res.result?.tdsApplicable &&
              setTdsPostDetail({
                formNo: res.result?.tcsDetail?.form,
                transactionType: res.result?.tcsDetail?.transactionType,
              });

            // console.log(
            //   "total,,,,,",
            //   res.result?.supplyState,
            //   Utils.getNumber(footerTotal.amount),
            //   Utils.getNumber(footerLedger.ledgerTotalAmount),
            //   roundOffAmount,
            //   Math.round(cashDiscountRs * 100) / 100,
            //   Math.abs(res.result?.effectiveLedger[0]?.amount) +
            //     res.result?.amountSummary?.totalItemGSTAmt
            // );

            setItemLength(res.result?.items?.length);

            if (splitPathInVchType === Voucher_Type.PurchaseVoucher) {
              setCurrentCustomerState(res.result?.billState);
              setPurchasePartyState(res.result?.supplyState);
            } else {
              setCurrentCustomerState(res.result?.supplyState);
            }
            splitPathInVchType === Voucher_Type.PurchaseVoucher &&
              // setGstEnabled(res?.result?.isGstEnabled);

              setTransactionId({
                partyId: res.result?.partyLedger[0]?.transactionId,
                effectiveId: res.result?.effectiveLedger[0]?.transactionId,
              });

            const Items = res.result?.items?.map((itm: any) => {
              return {
                ...itm,
                amount: {
                  ...itm?.amount,
                  unit: {
                    label: itm?.amount?.unit?.name,
                    value: itm?.amount?.unit?.id,
                  },
                  tax: {
                    key: itm?.amount?.tax?.rate,
                    label: itm?.amount?.tax?.name,
                    value: itm?.amount?.tax?.id,
                  },
                },
              };
            });

            const filterFreightInsLedger =
              res.result?.freightInsLedgers?.filter(
                (x: any) => x?.partyOrTax !== 7 && x?.partyOrTax !== 6
              );

            setShowFreightInsurance(filterFreightInsLedger?.length > 0);
            setUpdateTimeShowFreightIns(filterFreightInsLedger?.length > 0);

            const freightInsLedgers: any[] = filterFreightInsLedger?.map(
              (exp: any) => {
                setSelectedOtherExpenses((select) => [...select, exp?.id]);
                return {
                  ...exp,
                  id: {
                    value: exp?.id,
                    label: exp?.ledgerName,
                  },
                  amount: Math.abs(exp?.amount),
                  transactionId: exp?.transactionId,
                  gstRate: {
                    value: exp?.gstRate?.id,
                    title: exp?.gstRate?.rate,
                    label: exp?.gstRate?.name,
                  },
                };
              }
            );
            // console.log(
            //   "freightInsLedgers",
            //   freightInsLedgers,
            //   freightInsLedgers?.length
            // );

            setLedgerLength(freightInsLedgers?.length ?? 1);
            // debugger;
            const bankDtsGet = draftId
              ? res.result?.instantPayLedgersDetails?.map((x: any) => {
                  let bankAccount = bankAccountList?.find(
                    (y: any) => y?.id === x?.ledgerId
                  );
                  setSelectedBankBalance((y) => [...y, bankAccount?.balance]);
                  //  setSelectedBankLedger((select: any) => [
                  //    ...select,
                  //    x?.effectiveLedger[0]?.id,
                  //  ]);

                  //  setSelectedBankPartyOrTax((select: any) => [
                  //    ...select,
                  //    x?.effectiveLedger[0]?.id,
                  //  ]);
                  return {
                    name: {
                      value: x?.ledgerId,
                      label: x?.ledgerName,
                      title: Party_Tax.BanknCash,
                    },
                    // name: x?.ledgerId,
                    payRecReference: x?.payRecReference,
                    amount: x?.amount,
                  };
                })
              : res.result?.payRecVouchers?.map((x: any) => {
                  let bankAccount = bankAccountList?.find(
                    (y: any) => y.id === x?.effectiveLedger[0]?.id
                  );

                  // console.log("x........", x.date);
                  if (updateId) {
                    setSelectedBankBalance((y) => [
                      ...y,
                      splitPathInVchType === Voucher_Type.SalesVoucher
                        ? bankAccount?.balance - x?.effectiveLedger[0]?.amount
                        : Math.abs(x?.effectiveLedger[0]?.amount) +
                          bankAccount?.balance,
                    ]);
                  } else if (cloneId) {
                    setSelectedBankBalance((y) => [...y, bankAccount?.balance]);
                  }

                  setSelectedBankLedger((select: any) => [
                    ...select,
                    x?.effectiveLedger[0]?.id,
                  ]);

                  setSelectedBankPartyOrTax((select: any) => [
                    ...select,
                    x?.effectiveLedger[0]?.partyOrTax,
                  ]);

                  return {
                    editId: x?.id,
                    paymentNumber: x?.number,
                    payRecReference: x?.payRecReference,
                    date: x?.date,
                    // totalAmount: {...x?.effectiveLedger[0]?.amount, x?.effectiveLedger?.amount},
                    partyRefId: x?.partyLedger[0]?.ref[0]?.id,
                    name: {
                      value: x?.effectiveLedger[0]?.id,
                      label: x?.effectiveLedger[0]?.ledgerName,
                      title: x?.effectiveLedger[0]?.partyOrTax,
                    },
                    amount: Math.abs(x?.effectiveLedger[0]?.amount),
                    isReconciled: x.isReconciled,
                    bankImportEntryRef: x.bankImportEntryRef,
                  };
                });

            setEditInvBankDetails(bankDtsGet ?? []);

            const receivedAmount = draftId
              ? res.result?.instantPayLedgersDetails?.reduce(
                  (acc: number, curr: any) => {
                    return (acc += Utils.getNumber(Math.abs(curr?.amount)));
                  },
                  0
                )
              : res.result?.payRecVouchers?.reduce((acc: number, curr: any) => {
                  return (acc += Utils.getNumber(
                    Math.abs(curr?.effectiveLedger[0]?.amount)
                  ));
                }, 0);

            console.log("receivedAmount ", receivedAmount);

            setBankAccountAmt(receivedAmount ?? 0);

            setUpdateTimeData({
              contactDetails:
                res.result?.contactName || res.result?.contactNo ? true : false,
              shippingAddress:
                res.result?.shippingAddress?.address ||
                res.result?.shippingAddress?.type
                  ? true
                  : false,
              friInsuranceOtherCharge: filterFreightInsLedger?.length > 0,
              receipt: bankDtsGet?.length > 0,
              cashDiscount:
                res.result?.amountSummary?.cashDiscount?.discountAmount > 0,
              tradeDiscount: res.result?.amountSummary?.totalDis > 0,
              isGSTVoucher: res.result?.isGSTVoucher,
              showItemDescription: res.result?.showItemDescription,
              roundOff:
                res.result?.amountSummary?.roundOff?.roundOffApplicable ||
                res.result?.amountSummary?.roundOff?.roundOffAmount !== 0,
              showDispatchDetail: res.result?.despatchDetail ? true : false,
              showFreightType: res.result?.freightType ? true : false,
              showRCM: res.result?.nature === Voucher_Nature.RCM ? true : false,
            });

            const despatchDetail = {
              ...res.result?.despatchDetail,
              delNoteDate: dayjs(
                dayjs(res.result?.despatchDetail?.delNoteDate).format(
                  "DD/MM/YYYY"
                ),
                "DD/MM/YYYY"
              ),
            };

            // console.log("despatchDetail", despatchDetail);

            setInvoiceDespatchDetails(despatchDetail);

            form.setFieldsValue({
              ...res.result,
              billingAddress: {
                ...res.result?.billingAddress,
                type: {
                  value: res.result?.billingAddress?.addressName,
                  lable: res.result?.billingAddress?.addressName,
                  title: res.result?.billingAddress?.type,
                },
              },
              shippingAddress: {
                ...res.result?.shippingAddress,
                type: {
                  value: res.result?.shippingAddress?.addressName,
                  lable: res.result?.shippingAddress?.addressName,
                  title: res.result?.shippingAddress?.type,
                },
              },
              tdsDetail: {
                value: res.result?.tdsDetail?.id,
                label: res.result?.tdsDetail?.name,
                title: res.result?.tdsDetail?.rate,
              },
              tcsDetail: {
                value: res.result?.tcsDetail?.id,
                label: res.result?.tcsDetail?.name,
                title: res.result?.tcsDetail?.rate,
              },
              freightType: res.result?.freightType
                ? res.result?.freightType
                : null,
              roundOff: res.result?.amountSummary?.roundOff?.roundOffAmount,
              roundoffCheckbox:
                res.result?.amountSummary?.roundOff?.roundOffApplicable,
              cashDiscount:
                res.result?.amountSummary?.cashDiscount?.discountRate > 0
                  ? res.result?.amountSummary?.cashDiscount?.discountRate
                  : Number(
                      res.result?.amountSummary?.cashDiscount?.discountAmount ??
                        0
                    ),
              cashDiscountDrop:
                Number(res.result?.amountSummary?.cashDiscount?.discountRate) >
                0
                  ? DiscountType.PercentageDiscount
                  : DiscountType.RupeeDiscount,
              bankDts:
                bankDtsGet?.length > 0
                  ? [...bankDtsGet, { amount: 0.0 }]
                  : [{ amount: 0.0 }],
              showFreightInsLedgers: freightInsLedgers?.length > 0,
              items: Items?.length > 0 && [
                ...Items,
                { amount: { price: 0.0, qty: 0, grossAmount: 0 } },
              ],
              purchaseOrderRef: {
                value: res.result?.purchaseOrderRef?.id,
                label: res.result?.purchaseOrderRef?.name,
              },
              freightInsLedgers:
                freightInsLedgers?.length > 0
                  ? [
                      ...freightInsLedgers,
                      {
                        id: null,
                        gstRate: 0,
                        gstAmount: 0,
                        totalAmount: 0,
                        amount: 0,
                      },
                    ]
                  : [
                      {
                        id: null,
                        gstRate: 0,
                        gstAmount: 0,
                        totalAmount: 0,
                        amount: 0,
                      },
                    ],
              effectiveLedger: {
                value: res.result?.effectiveLedger[0]?.id,
                label: res.result?.effectiveLedger[0]?.ledgerName,
              },
              partyLedger: {
                value: res.result?.partyLedger[0]?.id,
                label: res.result?.partyLedger[0]?.ledgerName,
              },
              number: cloneId
                ? form.getFieldValue("number")
                : res.result?.number,
              receivedAmount: res.result?.amountSummary?.receivedAmount,
              paymentType: res.result?.paymentType === 1 ? true : false,
              // date: dayjs(
              //   dayjs(res.result?.date).format("DD/MM/YYYY"),
              //   "DD/MM/YYYY"
              // ),
              date: dayjs(res.result?.date),
              dueDate: dayjs(
                dayjs(res.result?.dueDate).format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ),
            });
            setVoucherStatus(res.result?.voucherStatus);
            setDateFiled(dayjs(res.result?.date));
            setEditDate(res.result?.date);
            setEditDueDate(res.result?.dueDate);
            setLoading(false);
          } else setLoading(false);
        } catch (err: any) {
          console.error("error", err);

          openNotification("error", err.message);
          setLoading(false);
        }
      };

      editData(draftId ? draftId : updateId ?? cloneId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateId, cloneId, bankAccountList, customerOptions]);

  // Get Sales Order get api to save invoice
  useEffect(() => {
    const getOrderDetails = async (
      orderId: string | undefined,
      type: Voucher_Type,
      setOrderRef: boolean
    ) => {
      if (orderId) {
        // setLoading(true);
        await QuotationServices.getQuotationId(
          currentClient?.id!,
          orderId,
          type
        )
          .then((res: any) => {
            if (res?.result) {
              // console.log("true...........", res.result?.partyLedger[0]?.ref);
              setTermsAndConditionField(res?.result?.termsNConditions);
              setFooterTotal({
                qty: res.result?.amountSummary?.totalQty,
                unitPrice: res.result?.amountSummary?.totalPrice,
                taxableAmount:
                  res.result?.amountSummary?.taxableAmount +
                  res.result?.amountSummary?.totalDis,
                discount: res.result?.amountSummary?.totalDis,
                gst: res.result?.amountSummary?.totalItemGSTAmt,
                amount:
                  Math.abs(res.result?.effectiveLedger[0]?.amount) +
                  res.result?.amountSummary?.totalItemGSTAmt,
              });
              setFooterLedger({
                ledgerAmount: res.result?.amountSummary?.cifExpenses,
                ledgerGstAmount: res.result?.amountSummary?.totalCIFGSTAmt,
                ledgerTotalAmount:
                  res.result?.amountSummary?.cifExpenses +
                  res.result?.amountSummary?.totalCIFGSTAmt,
              });
              setBalance(res.result.amountSummary?.receivedAmount);
              // setInvoiceTotal(order?.amountSummary?.totalInvoiceAmount);
              // setIsInCash(res.result?.paymentType === 1 ? true : false);
              if (splitPathInVchType === Voucher_Type.PurchaseVoucher) {
                setCurrentCustomerState(res.result?.billState);
                setPurchasePartyState(res.result?.supplyState);
              } else {
                setCurrentCustomerState(res.result?.supplyState);
              }
              // setGstEnabled(res?.result?.isGstEnabled);

              setTransactionId({
                partyId: res.result?.partyLedger[0]?.transactionId,
                effectiveId: res.result?.effectiveLedger[0]?.transactionId,
              });

              const Items = res.result?.items
                ?.filter(
                  (x: any) => x?.amount?.qty - x?.amount?.suppliedQty > 0
                )
                ?.map((itm: any) => {
                  return {
                    ...itm,
                    amount: {
                      ...itm?.amount,
                      qty: itm?.amount?.qty - itm?.amount?.suppliedQty,
                      tax: {
                        key: itm?.amount?.tax?.rate,
                        label: itm?.amount?.tax?.name,
                        value: itm?.amount?.tax?.id,
                      },
                    },
                  };
                });

              setItemLength(Items?.length);

              const filterFreightInsLedger =
                res.result?.freightInsLedgers?.filter(
                  (x: any) => x?.partyOrTax !== 7 && x?.partyOrTax !== 6
                );
              setSelectedParty(res.result?.partyLedger[0]?.id);
              setShowFreightInsurance(filterFreightInsLedger?.length > 0);
              setUpdateTimeShowFreightIns(filterFreightInsLedger?.length > 0);

              const freightInsLedgers: any[] = filterFreightInsLedger?.map(
                (exp: any) => {
                  setSelectedOtherExpenses((select) => [...select, exp?.id]);
                  return {
                    ...exp,
                    id: {
                      value: exp?.id,
                      label: exp?.ledgerName,
                    },
                    amount: Math.abs(exp?.amount),
                    transactionId: exp?.transactionId,
                    gstRate: {
                      value: exp?.gstRate?.id,
                      title: exp?.gstRate?.rate,
                      label: exp?.gstRate?.name,
                    },
                  };
                }
              );
              // console.log(
              //   "freightInsLedgers",
              //   freightInsLedgers,
              //   freightInsLedgers.length
              // );

              setLedgerLength(freightInsLedgers?.length ?? 1);
              setRoundoffAmount(
                res.result?.amountSummary?.roundOff?.roundOffAmount
              );

              setUpdateTimeData({
                contactDetails:
                  res.result?.contactName || res.result?.contactNo
                    ? true
                    : false,
                shippingAddress:
                  res.result?.shippingAddress?.address ||
                  res.result?.shippingAddress?.type
                    ? true
                    : false,
                friInsuranceOtherCharge: freightInsLedgers?.length > 0,
                receipt: false,
                cashDiscount:
                  res.result?.amountSummary?.cashDiscount?.discountAmount > 0,
                tradeDiscount: res.result?.amountSummary?.totalDis > 0,
                isGSTVoucher: res.result?.isGSTVoucher,
                showItemDescription: res.result?.showItemDescription,
                roundOff:
                  res.result?.amountSummary?.roundOff?.roundOffApplicable ||
                  res.result?.amountSummary?.roundOff?.roundOffAmount !== 0,
                showDispatchDetail: res.result?.despatchDetail ? true : false,
                showFreightType: res.result?.freightType ? true : false,
                showRCM:
                  res.result?.nature === Voucher_Nature.RCM ? true : false,
              });

              setOrderRef && setPurchaseOrderRef(orderId);

              form.setFieldsValue({
                // number: salesInvoiceNo,
                showFreightInsLedgers: freightInsLedgers?.length > 0,
                roundOff: res.result?.amountSummary?.roundOff?.roundOffAmount,
                roundoffCheckbox:
                  res.result?.amountSummary?.roundOff?.roundOffApplicable,
                // roundoffCheckbox: res.result?.amountSummary?.roundOff?.roundOffAmount !== 0,

                // roundoffCheckbox: Number(
                //   res.result?.amountSummary?.cashDiscount?.roundOffAmount
                // )
                //   ? true
                //   : false,
                cashDiscount:
                  res.result?.amountSummary?.cashDiscount?.discountRate > 0
                    ? res.result?.amountSummary?.cashDiscount?.discountRate
                    : Number(
                        res.result?.amountSummary?.cashDiscount
                          ?.discountAmount ?? 0
                      ),
                cashDiscountDrop:
                  Number(
                    res.result?.amountSummary?.cashDiscount?.discountRate
                  ) > 0
                    ? DiscountType.PercentageDiscount
                    : DiscountType.RupeeDiscount,
                items: [
                  ...Items,
                  { amount: { price: 0.0, qty: 0, grossAmount: 0 } },
                ],
                freightInsLedgers:
                  freightInsLedgers?.length > 0
                    ? [
                        ...freightInsLedgers,
                        {
                          id: null,
                          gstRate: 0,
                          gstAmount: 0,
                          totalAmount: 0,
                          amount: 0,
                        },
                      ]
                    : [
                        {
                          id: null,
                          gstRate: 0,
                          gstAmount: 0,
                          totalAmount: 0,
                          amount: 0,
                        },
                      ],
                effectiveLedger: {
                  value: res.result?.effectiveLedger[0]?.id,
                  label: res.result?.effectiveLedger[0]?.ledgerName,
                },
                phoneNo: res.result?.phoneNo,
                partyLedger: {
                  value: res.result?.partyLedger[0]?.id,
                  label: res.result?.partyLedger[0]?.ledgerName,
                },
                purchaseOrderRef: {
                  value: orderId,
                  label: res.result?.number,
                },

                // id: val?.partyLedger?.value,
                // PartyOrTax: Party_Tax.party,
                // amount:
                //   Utils.getNumber(invoiceTotal) +
                //   Utils.getNumber(footerLedger.ledgerTotalAmount),
                billingAddress: {
                  ...res.result?.billingAddress,
                  type: {
                    value: res.result?.billingAddress?.addressName,
                    lable: res.result?.billingAddress?.addressName,
                    title: res.result?.billingAddress?.type,
                  },
                },
                shippingAddress: {
                  ...res.result?.shippingAddress,
                  type: {
                    value: res.result?.shippingAddress?.addressName,
                    lable: res.result?.shippingAddress?.addressName,
                    title: res.result?.shippingAddress?.type,
                  },
                },
                supplyState: res.result?.supplyState,
                receivedAmount: res.result?.amountSummary?.receivedAmount,
                paymentType: res?.result?.paymentType === 1 ? true : false,
                date: dayjs(
                  dayjs(res.result.date).format("DD/MM/YYYY"),
                  "DD/MM/YYYY"
                ),
                dueDate: dayjs(res.result?.date).add(
                  convertToDays(
                    companySettings?.setting?.dueOn?.duration!,
                    companySettings?.setting?.dueOn?.durationUnit
                  ),
                  "d"
                ),
              });

              setLoading(false);
            } else setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
            openNotification("error", err.message);
          });
        // setLoading(false);
      }
    };

    if (quotationId) {
      getOrderDetails(quotationId, Voucher_Type.Quotation, true);
    } else if (salesOrderId) {
      getOrderDetails(salesOrderId, Voucher_Type.SalesOrder, true);
    } else if (purchaseOrderId) {
      getOrderDetails(purchaseOrderId, Voucher_Type.PurchaseOrder, true);
    } else if (purchaseOrderRef) {
      getOrderDetails(
        purchaseOrderRef,
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? Voucher_Type.SalesOrder
          : Voucher_Type.PurchaseOrder,
        false
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesOrderId, purchaseOrderId, quotationId, purchaseOrderRef]);

  // ----- Get Estimate Api (Post Sales Invoice) Api -------
  // useEffect(() => {
  //   if (quotationId) {
  //     setLoading(true);
  //     SalesService.getQuotation(
  //       currentClient?.id!,
  //       EstimateTypes.Estimate,
  //       quotationId
  //     )
  //       .then((res: any) => {
  //         const quot = res.result?.invoice;
  //         if (res.result) {
  //           setFooterTotal({
  //             qty: quot?.amountSummary?.totalQty,
  //             unitPrice: quot?.amountSummary?.totalPrice,
  //             taxableAmount: quot?.amountSummary?.taxableAmount,
  //             discount: quot?.amountSummary?.totalDis,
  //             gst: quot?.amountSummary?.igstAmt
  //               ? quot?.amountSummary?.igstAmt
  //               : quot?.amountSummary?.sgstAmt + quot?.amountSummary?.cgstAmt,
  //             amount: quot?.amountSummary?.totalInvoiceAmount,
  //           });
  //           // setInvoiceTotal(quot?.amountSummary?.totalInvoiceAmount);
  //           setItemLength(quot?.items.length);
  //           setEstimateNo(quot?.number);
  //           setEditParty({ id: quot?.partyLedger.id });
  //           setCurrentCustomerState(quot.supplyState);

  //           const Items = quot?.items?.map((itm: any) => {
  //             return {
  //               ...itm,
  //               account: {
  //                 label: itm?.account?.name,
  //                 value: itm?.account?.id,
  //               },
  //               amount: {
  //                 ...itm?.amount,
  //                 tax: {
  //                   key: itm?.amount?.tax?.id,
  //                   label: itm?.amount?.tax?.name,
  //                   value: itm?.amount?.tax?.rate,
  //                 },
  //               },
  //             };
  //           });

  //           form.setFieldsValue({
  //             items: [...Items, [{ amount: { price: 0.0, qty: 0 } }]],
  //             phoneNo: quot?.phoneNo,
  //             billingAddress: quot?.billingAddress,
  //             shippingAddress: quot?.shippingAddress,
  //             supplyState: quot?.supplyState,
  //             explanation: quot?.explanation,
  //             receivedAmount: quot?.amountSummary?.receivedAmount,
  //             partyLedger: {
  //               value: quot?.partyLedger.id,
  //               label: quot?.partyLedger.name,
  //             },
  //             date: dayjs(dayjs(quot?.date).format("DD/MM/YYYY"), "DD/MM/YYYY"),
  //             dueDate: dayjs(
  //               dayjs(quot?.dueDate).format("DD/MM/YYYY"),
  //               "DD/MM/YYYY"
  //             ),
  //           });
  //           setLoading(false);
  //         } else setLoading(false);
  //       })
  //       .catch((err: any) => {
  //         openNotification("error", err.message);
  //         console.error("Error..", err.message);
  //         setLoading(false);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [quotationId]);

  //------ Get a Include Other Expenses(Direct Income) Ledger

  useEffect(() => {
    const FreightAndInsuranceOptions = async () => {
      setOtherExpensiveDropDownLoading(true);
      await LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? SearchLedgerByGroupsTypes.SellingAndDistributionExpenses
          : mode
          ? SearchLedgerByGroupsTypes.AllExpensesWithDutiesAndTaxes
          : SearchLedgerByGroupsTypes.DirectExpenses
      )
        .then((res: any) => {
          // console.log(res, "indirExpLedgers");
          if (res) {
            setFreightAndInsuranceOptions(res);
            setOtherExpensiveDropDownLoading(false);
          }
        })
        .catch((ex: any) => {
          console.error("error", ex);
          setOtherExpensiveDropDownLoading(false);
        });
    };
    FreightAndInsuranceOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger, splitPathInVchType, mode]);

  // -------- Add Sales Invoice Post API ---------
  const addSalesInvoice = (isSaveAndNew?: boolean) => {
    form.validateFields().then(async (val: any) => {
      let bankBalanceLargeAmount: any = [];
      if (splitPathInVchType === Voucher_Type.PurchaseVoucher) {
        if (val.bankDts?.length > 0) {
          for (const index in selectedBankBalance) {
            if (val.bankDts[index]?.amount > selectedBankBalance[index]) {
              bankBalanceLargeAmount.push({
                name: val.bankDts[index]?.name?.label,
                amount: selectedBankBalance[index],
              });
              console.log("index", val.bankDts[index]?.name?.value);
            }
          }
        }
      }

      if (bankBalanceLargeAmount?.length > 0) {
        const formattedDetails = bankBalanceLargeAmount.map(
          (obj: any) =>
            `${obj?.name} -  Bank Amount: ₹ ${Utils.getFormattedNumber(
              obj?.amount
            )}`
        );
        alert(formattedDetails.join("\n"));
      } else {
        console.log("post...........", val.bankDts, bankBalanceLargeAmount);

        isSaveAndNew ? setSaveNewLoading(true) : setUpdateAndNewButton(true);

        setSaveAndNew(false);
        // setUpdateAndNew(false);
        const freightInsLedgers = val?.freightInsLedgers?.map((exp: any) => {
          return {
            ...exp,
            id: exp?.id?.value,
            gstRate: {
              id: exp?.gstRate?.value,
              rate: exp?.gstRate?.title,
              name: exp?.gstRate?.label,
            },
          };
        });

        const partyLedger = new Array({
          id: val?.partyLedger?.value,
          PartyOrTax: Party_Tax.party,
          amount: Utils.getFormattedNumber(
            Utils.getNumber(footerTotal.amount) +
              Utils.getNumber(footerLedger.ledgerTotalAmount) -
              Math.round(cashDiscountRs * 100) / 100 +
              roundOffAmount -
              (voucherNature === Voucher_Nature.RCM
                ? footerTotal?.gst + footerLedger.ledgerGstAmount
                : 0) -
              totalTds +
              totalTcs
          ),
          transactionId:
            transactionId.partyId === "" ? null : transactionId.partyId,
        });

        const effectiveLedger = new Array({
          id: val?.effectiveLedger?.value,
          PartyOrTax: Party_Tax.other,
          amount: Utils.getFormattedNumber(
            Utils.getNumber(footerTotal.taxableAmount) - footerTotal.discount
          ),
          transactionId:
            transactionId.partyId === "" ? null : transactionId.partyId,
        });

        const adjustDateToCurrentTime = (date: any) => {
          const currentTime = new Date();

          // Set the time part of the date to match the current time
          date.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
          return date;
        };

        const items = val?.items?.map((itm: any) => {
          return {
            ...itm,
            amount: {
              ...itm?.amount,
              unit: {
                id: itm.amount?.unit?.value,
                name: itm.amount?.unit?.label,
              },
              grossAmount:
                Utils.getNumber(itm?.amount?.price) * itm?.amount?.qty,
              tax: {
                id: itm?.amount?.tax?.value,
                rate: itm?.amount?.tax?.key,
                name: itm?.amount?.tax?.label,
              },
            },
          };
        });

        const despatchDetail = {
          ...invoiceDespatchDetails,
          delNoteDate: invoiceDespatchDetails?.delNoteDate
            ? dayjs(invoiceDespatchDetails?.delNoteDate).toISOString()
            : null,
        };

        let adjustedGivenDate = adjustDateToCurrentTime(new Date(val.date));
        let adjustedDueGivenDate = adjustDateToCurrentTime(
          new Date(val.dueDate)
        );

        if (updateId) {
          adjustedGivenDate = dayjs(val.date).isSame(dayjs(editDate), "day")
            ? editDate
            : adjustedGivenDate;

          adjustedDueGivenDate = dayjs(val.dueDate).isSame(
            dayjs(editDueDate),
            "day"
          )
            ? editDueDate
            : adjustedDueGivenDate;
        }

        // console.log("date",adjustedGivenDate, dayjs(adjustedGivenDate).toISOString());

        // formData.append("file", file);

        console.log(
          "val.despatchDetail",
          despatchDetail,
          invoiceDespatchDetails?.delNoteDate
        );

        const tdsRatePredentage =
          partyPanCard === PanCardType.HUF
            ? val?.tdsDetail?.title?.rates?.indHUFRate ?? val?.tdsDetail?.title
            : partyPanCard === PanCardType.Other
            ? val?.tdsDetail?.title?.rates?.otherRate ?? val?.tdsDetail?.title
            : val?.tdsDetail?.title?.rates?.ifPANNotRate ??
              val?.tdsDetail?.title;

        const invoice = {
          ...val,
          items: items,
          billingAddress: {
            ...val?.billingAddress,
            type: val?.billingAddress?.type?.title,
            addressName: val?.billingAddress?.type?.value,
          },
          shippingAddress: {
            ...val?.shippingAddress,
            type: val?.shippingAddress?.type?.title,
            addressName: val?.shippingAddress?.type?.value,
          },
          // shippingAddress: val?.shippingAddress?.type
          //   ? val.shippingAddress
          //   : { address: val?.shippingAddress?.address },
          tcsApplicable:
            tdsOrTcs && totalTcs > 0 && isPartTDSOrTDS.tcs ? true : false,
          tcsDetail:
            tdsOrTcs && totalTcs > 0 && isPartTDSOrTDS.tcs
              ? {
                  id: val?.tcsDetail?.value,
                  name: val?.tcsDetail?.label,
                  rate: val?.tcsDetail?.title,
                  value: totalTcs,
                }
              : null,
          tdsApplicable:
            tdsOrTcs === 0 && totalTds > 0 && isPartTDSOrTDS.tds ? true : false,
          tdsDetail:
            tdsOrTcs === 0 && totalTds > 0 && isPartTDSOrTDS.tds
              ? {
                  id: val?.tdsDetail?.value,
                  name: val?.tdsDetail?.label,
                  rate: tdsRatePredentage,
                  value: totalTds,
                  transactionType: tdsPostDetail.transactionType,
                  form: tdsPostDetail.formNo,
                }
              : null,
          nature: voucherNature,
          isPurchaseJVVoucher: mode,
          isShippingAddressSame: isSameBillingAddress,
          isGSTVoucher:
            companySettings?.setting?.gstDetails?.scheme ===
              CompanyGSTTypes.Regular || updateTimeData.isGSTVoucher,
          showItemDescription:
            settingControl.showItemDescription ||
            updateTimeData.showItemDescription,
          draftId: draftId,
          termsNConditions: termsAndConditionField,
          bankAmount: bankAccountAmt,
          instantPayment: bankAccountAmt > 0 ? true : false,
          purchaseOrderRef: { id: val?.purchaseOrderRef?.value },
          date: dayjs(adjustedGivenDate).toISOString(),
          dueDate: dayjs(adjustedDueGivenDate).toISOString(),
          freightInsLedgers,
          partyLedger,
          effectiveLedger,
          despatchDetail: despatchDetail,
          // exportDetail: invoiceExportDetails,

          isStateSame: isStateSame,
          billState: currentCustomerState,
          amountSummary: {
            tCSRate: val?.tcsDetail?.title,
            tCSAmt: totalTcs,
            tDSRate: tdsRatePredentage,
            tDSAmt: totalTds,
            totalQty: Utils.getFormattedNumber(footerTotal?.qty),
            taxableAmount: Utils.getFormattedNumber(
              Utils.getNumber(footerTotal?.taxableAmount) -
                footerTotal?.discount
            ), // befor gst
            totalDis: Utils.getFormattedNumber(footerTotal?.discount),
            freightAmount: 0,
            cessAmount: 0,
            igstAmt: !isStateSame
              ? footerTotal?.gst + footerLedger.ledgerGstAmount
              : 0,
            cgstAmt: isStateSame
              ? (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
              : 0,
            sgstAmt: isStateSame
              ? (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
              : 0,
            // roundOffAmount: roundOffAmount,
            roundOff: {
              roundOffApplicable: val?.roundoffCheckbox,
              roundOffAmount: roundOffAmount,
            },
            cashDiscount: {
              discountRate:
                val.cashDiscountDrop === DiscountType.PercentageDiscount
                  ? val.cashDiscount ?? 0
                  : 0,
              discountAmount: cashDiscountRs,
            },
            totalInvoiceAmount:
              Utils.getNumber(footerTotal.amount) +
              Utils.getNumber(footerLedger.ledgerTotalAmount) +
              roundOffAmount -
              cashDiscountRs -
              (voucherNature === Voucher_Nature.RCM
                ? footerTotal?.gst + footerLedger.ledgerGstAmount
                : 0) +
              totalTcs,
            // -
            // totalTds,
            receivedAmount: 0,
            voucherAmountWithoutTCS:
              Utils.getNumber(footerTotal.amount) +
              Utils.getNumber(footerLedger.ledgerTotalAmount) -
              balance,
          },
          deleteUploadId: deleteUploadId,
        };

        console.log("invoice", invoice);

        // const invoice1 = {...invoice, ...x}

        // console.log(
        //   "viewdatavoucher post...........",
        //   invoice
        //   // bankLedgerEffective
        // );

        if (footerTotal?.discount <= footerTotal?.taxableAmount) {
          if (bankAccountAmt <= invoice.amountSummary?.totalInvoiceAmount) {
            if (
              Utils.getNumber(footerTotal.amount) +
                Utils.getNumber(footerLedger.ledgerTotalAmount) >
              0
            ) {
              setOpenModel({
                type: "addInvoiceReferenceModel",
                param: {
                  open: true,
                },
              });
              setCurrentInvoiceDetails(invoice);
              // await VoucherServices.postVoucher(
              //   currentClient?.id!,
              //   Voucher_Type.SalesVoucher,
              //   invoice,
              //   location?.state?.copy ? undefined : id
              // )
              //   .then((res: any) => {
              //     if (res?.status) {
              //       sessionStorage.removeItem(`${currentClient?.id} Sales`);
              //       sessionStorage.removeItem("NotificationState");
              //       openNotification(
              //         "success",
              //         `Sales Invoice ${res.result?.name} ${
              //           id ? "Edt Successfully" : "Add Successfully"
              //         }`
              //       );
              //       quotationId
              //         ? navigate(
              //             `/accounts/clients/${currentClient?.id}/sales/estimate`
              //           )
              //         : partyId
              //         ? navigate(
              //             `/accounts/clients/${currentClient?.id}/parties/${editParty?.id}`
              //           )
              //         : !isSaveAndNew
              //         ? navigate(
              //             `/accounts/clients/${currentClient?.id}/sales/invoices`
              //           )
              //         : setFooterTotal({
              //             qty: 0,
              //             unitPrice: 0,
              //             taxableAmount: 0,
              //             discount: 0,
              //             gst: 0,
              //             amount: 0,
              //           });
              //       form.resetFields();
              //       setInvoiceTotal(0.0);
              //       setItemLength(0);
              //       setUpdateAndNewButton(false);
              //     } else if (res?.message) {
              //       setUpdateAndNewButton(false);
              //       openNotification("error", res?.message);
              //     }
              //   })
              //   .catch((ex) => openNotification("error", ex));
              // setUpdateAndNewButton(false);
              // setSaveNewLoading(false);
              // isSaveAndNew && seInitialRender(!initialRender);
            } else {
              setSaveNewLoading(false);
              openNotification("error", "Selling price must be greater than 0");
              setUpdateAndNewButton(false);
            }
          } else {
            openNotification(
              "error",
              "Bank Amount should be less than Total Invoice amount"
            );
          }
        } else {
          openNotification(
            "error",
            "discount should be less than selling price"
          );
          setUpdateAndNewButton(false);
        }
      }
    });
  };

  // Post Draft
  const saveDrift = async () => {
    // console.log(
    //   "end...",
    //   form.getFieldsValue(),
    //   form.getFieldsValue([]),
    //   form.getFieldsValue(["salesInvoice"])
    // );
    try {
      const val = form.getFieldsValue();
      console.log("post Deaft Date: " + val);

      const freightInsLedgers = val?.freightInsLedgers?.map((exp: any) => {
        return {
          ...exp,
          id: exp?.id?.value,
          gstRate: {
            id: exp?.gstRate?.value,
            rate: exp?.gstRate?.title,
            name: exp?.gstRate?.label,
          },
        };
      });

      const partyLedger = new Array({
        id: val?.partyLedger?.value,
        PartyOrTax: Party_Tax.party,
        amount: Utils.getFormattedNumber(
          Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) -
            Math.round(cashDiscountRs * 100) / 100 +
            roundOffAmount
        ),
        transactionId:
          transactionId.partyId === "" ? null : transactionId.partyId,
      });
      // debugger;

      const effectiveLedger = new Array({
        id: val?.effectiveLedger?.value,
        PartyOrTax: Party_Tax.other,
        amount: Utils.getFormattedNumber(
          Utils.getNumber(footerTotal.taxableAmount) - footerTotal.discount
        ),
        transactionId:
          transactionId.partyId === "" ? null : transactionId.partyId,
      });

      const items = val?.items?.map((itm: any) => {
        return {
          ...itm,
          amount: {
            ...itm?.amount,
            unit: {
              id: itm.amount?.unit?.value,
              name: itm.amount?.unit?.label,
            },

            grossAmount: Utils.getNumber(itm?.amount?.price) * itm?.amount?.qty,
            tax: {
              id: itm?.amount?.tax?.value,
              rate: itm?.amount?.tax?.key,
              name: itm?.amount?.tax?.label,
            },
          },
        };
      });

      const instantPayLedgersDetails = val?.bankDts?.map((itm: any) => {
        return {
          ledgerId: itm.name?.value,
          ledgerName: itm.name?.label,
          payRecReference: itm?.payRecReference,
          amount: itm?.amount,
        };
      });

      const adjustDateToCurrentTime = (date: any) => {
        const currentTime = new Date();
        // Set the time part of the date to match the current time
        date.setHours(
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
          currentTime.getMilliseconds()
        );

        return date;
      };

      let adjustedGivenDate = adjustDateToCurrentTime(new Date(val.date));
      let adjustedDueGivenDate = adjustDateToCurrentTime(new Date(val.dueDate));

      if (updateId) {
        adjustedGivenDate = dayjs(val.date).isSame(dayjs(editDate), "day")
          ? editDate
          : adjustedGivenDate;

        adjustedDueGivenDate = dayjs(val.dueDate).isSame(
          dayjs(editDueDate),
          "day"
        )
          ? editDueDate
          : adjustedDueGivenDate;
      }

      // console.log("date",adjustedGivenDate, dayjs(adjustedGivenDate).toISOString());

      const draftData = {
        ...val,
        items: items,
        tcsApplicable: tdsOrTcs === 1 ? true : false,
        billingAddress: {
          ...val?.billingAddress,
          type: val?.billingAddress?.title,
          addressName: val?.billingAddress?.value,
        },
        shippingAddress: {
          ...val?.shippingAddress,
          type: val?.shippingAddress?.title,
          addressName: val?.shippingAddress?.value,
        },
        // shippingAddress: val?.shippingAddress?.type
        //   ? val.shippingAddress
        //   : { address: val?.shippingAddress?.address },
        tcsDetail: tdsOrTcs
          ? {
              id: val?.tcsDetail?.value,
              name: val?.tcsDetail?.label,
              rate: val?.tcsDetail?.title,
              value: totalTcs,
            }
          : null,
        tdsApplicable: tdsOrTcs ? false : true,
        tdsDetail: tdsOrTcs
          ? null
          : {
              id: val?.tdsDetail?.value,
              name: val?.tdsDetail?.label,
              rate: val?.tdsDetail?.title,
              value: totalTds,
              transactionType: tdsPostDetail.transactionType,
              form: tdsPostDetail.formNo,
            },
        isPurchaseJVVoucher: mode,
        nature: voucherNature,
        isShippingAddressSame: isSameBillingAddress,
        isGSTVoucher:
          companySettings?.setting?.gstDetails?.scheme ===
            CompanyGSTTypes.Regular || updateTimeData.isGSTVoucher,
        showItemDescription:
          settingControl.showItemDescription ||
          updateTimeData.showItemDescription,
        instantPayLedgersDetails: instantPayLedgersDetails,
        bankAmount: bankAccountAmt,
        instantPayment: bankAccountAmt > 0 ? true : false,
        purchaseOrderRef: { id: val?.purchaseOrderRef?.value },
        date: dayjs(adjustedGivenDate).toISOString(),
        dueDate: dayjs(adjustedDueGivenDate).toISOString(),
        freightInsLedgers,
        partyLedger,
        effectiveLedger,
        despatchDetail: invoiceDespatchDetails,
        // exportDetail: invoiceExportDetails,

        isStateSame: isStateSame,
        billState: purchasePartyState,
        amountSummary: {
          tCSRate: val?.tcsDetail?.title,
          tCSAmt: totalTcs,
          tDSRate: val?.tdsDetail?.title,
          tDSAmt: totalTds,
          totalQty: Utils.getFormattedNumber(footerTotal?.qty),
          taxableAmount: Utils.getFormattedNumber(
            Utils.getNumber(footerTotal?.taxableAmount) - footerTotal?.discount
          ), // befor gst
          totalDis: Utils.getFormattedNumber(footerTotal?.discount),
          freightAmount: 0,
          cessAmount: 0,
          igstAmt: !isStateSame
            ? Utils.getFormattedNumber(
                footerTotal?.gst + footerLedger.ledgerGstAmount
              )
            : 0,
          cgstAmt: isStateSame
            ? (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
            : 0,
          sgstAmt: isStateSame
            ? (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
            : 0,
          // roundOffAmount: roundOffAmount,
          roundOff: {
            roundOffApplicable: val?.roundoffCheckbox,
            roundOffAmount: roundOffAmount,
          },
          cashDiscount: {
            discountRate:
              val.cashDiscountDrop === DiscountType.PercentageDiscount
                ? val.cashDiscount ?? 0
                : 0,
            discountAmount: cashDiscountRs,
          },
          totalInvoiceAmount:
            Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) +
            roundOffAmount -
            cashDiscountRs +
            totalTcs -
            totalTds,
          receivedAmount: 0,
          voucherAmountWithoutTCS:
            Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) -
            balance,
        },
      };

      const saveDraftData = await DraftService.postVoucherDraft(
        currentClient?.id!,
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? Voucher_Type.SalesVoucher
          : Voucher_Type.PurchaseVoucher,
        draftData,
        selectCustomizationTemplete,
        draftId
      );

      if (saveDraftData.result) {
        openNotification(
          "success",
          `Draft ${draftId ? "Edit Successfully" : "Add Successfully"}`
        );
      } else if (saveDraftData.message) {
        openNotification("error", saveDraftData?.message);
      }
    } catch (ex: any) {
      openNotification("error", ex);
    }

    // .then((res) => {
    //   if (res) {
    //   }
    // });
  };

  EntryLevelKeys({
    save: () => addSalesInvoice(),
    cancel: () => navigate(-1),
    saveAndNew: () => addSalesInvoice(),
  });

  // useHotkeys("shift+b", () => alert("Callback got executed"), {
  //   enabled: (event, hotkeysEvent) => {
  //     return true;
  //   },
  // });
  // useHotkeys("crtl+s", () => alert("We saved your progress!"), {
  //   // enabled: () => false,
  //   preventDefault: true,
  // });
  // useKeyboardShortcuts("s", ["altKey"], () => addSalesInvoice());
  //-------- All Item List-----------

  useEffect(() => {
    const items = async () => {
      setItemDropDownLoading(true);
      await SalesService.searchItem(
        currentClient?.id!,
        searchItem,
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? CAPItemTypes.Sales
          : CAPItemTypes.Purchase
      )
        .then((res) => {
          setItemDropDownLoading(false);
          setItemsOption(res);
        })
        .catch((err) => {
          console.log("error", err);
          setItemDropDownLoading(false);
        });
    };
    items();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, searchItem, loadItemAPI, splitPathInVchType]);

  // ----- Serch customer Api -----
  useEffect(() => {
    const customer = async () => {
      setPartyDropDownLoading(true);

      setLoading(true);
      inputRef.current?.focus();
      await LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.PartiesOnly
      )
        .then((res: any) => {
          if (res) {
            // debugger;
            // form.setFieldValue("date", dayjs());
            // form.setFieldValue("dueDate", dayjs().add(7, "d"));

            // form.setFieldValue(
            //   "dueDate",
            //   dayjs().add(
            //     companySettings?.setting?.dueOn?.duration
            //       ? companySettings?.setting?.dueOn?.duration
            //       : 0,
            //     companySettings?.setting?.dueOn?.durationUnit === TimeUnits.Day
            //       ? "d"
            //       : companySettings?.setting?.dueOn?.durationUnit ===
            //         TimeUnits.Week
            //       ? "w"
            //       : companySettings?.setting?.dueOn?.durationUnit ===
            //         TimeUnits.Month
            //       ? "month"
            //       : "y"
            //   )
            // );
            setOnlyEditTimeCustomerOptions(true);
            setCustomerOptions(res);
            setPartyDropDownLoading(false);
            // console.log(customerOptions, "getledger");
          }
          setLoading(false);
        })
        .catch((ex: any) => {
          console.error("error", ex);
          setLoading(false);
          setPartyDropDownLoading(false);
        });
    };
    customer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger]);

  // ----- Serch effectiveLedger Api ------
  useEffect(() => {
    const ledger = async () => {
      setLedgerDropDownLoading(true);

      await LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? SearchLedgerByGroupsTypes.Sales
          : SearchLedgerByGroupsTypes.Purchase
      )
        .then((res: any) => {
          if (res) {
            // console.log(res.result, "partyname");
            setEffectiveLedger(res);
            setLedgerDropDownLoading(false);
          }
        })
        .catch((ex: any) => {
          console.error(ex);
          setLedgerDropDownLoading(false);
        });
    };
    ledger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger, splitPathInVchType]);

  // ----- Set customer to party Balance  ------
  React.useEffect(() => {
    if (partyId) {
      PartyService.getPartytDetailsById(currentClient?.id!, partyId!)
        .then((result: any) => {
          if (result) {
            console.log("Result", result);
            form.setFieldsValue({
              phoneNo: result?.defaultContact?.phone,
              billingAddress: result?.bAddress,
              shippingAddress: result?.sAddress,
              supplyState: result?.state,
              receivedAmount: result.amountSummary?.receivedAmount,
              partyLedger: {
                value: result?.id,
                label: result?.bName,
              },
              narration: result?.narration,
            });
            setEditParty({ id: result?.id });
            setSelectedParty(result?.id);
            setCurrentCustomerState(result?.state);
          }
        })
        .catch((err) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyId, termsAndConditionField]);
  console.log("term", termNCondData);

  //------- Add new item ------
  useEffect(() => {
    if (
      newItem?.isSaved &&
      (splitPathInVchType === Voucher_Type.SalesVoucher
        ? newItem?.data?.salesItem?.isActive
        : newItem?.data?.purchaseItem?.isActive)
    ) {
      // Set the new item's data into the form field
      const newItemFields = {
        name: newItem.data?.name,
        isService: newItem.data?.isService,
        isTaxable: newItem.data?.isTaxable,
        id: newItem.data?.id,
        description:
          splitPathInVchType === Voucher_Type.SalesVoucher
            ? newItem.data?.salesItem?.description
            : newItem.data?.purchaseItem?.description,
        // account: {
        //   value: newItem.data?.salesItem?.account?.id,
        //   lable: newItem.data?.salesItem?.account?.name,
        // },
        amount: {
          qty: 1,
          unit: {
            value: newItem.data.unit?.id,
            label: newItem.data.unit?.name,
          },
          price: newItem.data?.isTaxable
            ? splitPathInVchType === Voucher_Type.SalesVoucher
              ? Utils.getFormattedNumber(
                  (newItem.data?.salesItem?.price /
                    (100 + newItem.data?.taxRate.rate)) *
                    100
                )
              : Utils.getFormattedNumber(
                  (newItem.data?.purchaseItem?.price /
                    (100 + newItem.data?.taxRate.rate)) *
                    100
                )
            : splitPathInVchType === Voucher_Type.SalesVoucher
            ? newItem.data?.salesItem?.price
            : newItem.data?.purchaseItem?.price,
          tax: {
            key: newItem.data?.taxRate?.rate,
            label: newItem.data?.taxRate?.name,
            value: newItem.data?.taxRate?.id,
          },
        },
      };

      // Update the current item at index itemFields.index
      setItemLength(itemFields.index + 1);
      form.setFieldValue(["items", itemFields.index], newItemFields);

      // If the current item index is at the end, add a new item with initial values
      if (itemFields?.field?.length === itemFields?.index + 1) {
        form.setFieldValue(["items", itemFields.index + 1], {
          amount: { price: 0.0, qty: 0, totalAmount: 0 },
        });
      }
      // Prepare the new item data to be added to itemsOption
      const newItemData = {
        name: newItem.data?.name,
        isService: newItem.data?.isService,
        isTaxable: newItem.data?.isTaxable,
        id: newItem.data?.id,
        description:
          splitPathInVchType === Voucher_Type.SalesVoucher
            ? newItem?.data?.salesItem?.description
            : newItem?.data?.purchaseItem?.description,

        // account: {
        //   value: newItem.data?.salesItem?.account?.id,
        //   lable: newItem.data?.salesItem?.account?.name,
        // },
        quantity: 1,
        price: newItem.data?.isTaxable
          ? splitPathInVchType === Voucher_Type.SalesVoucher
            ? Utils.getNumber(
                (newItem.data?.salesItem?.price /
                  (100 + newItem.data?.taxRate.rate)) *
                  100
              )
            : Utils.getNumber(
                (newItem.data?.purchaseItem?.price /
                  (100 + newItem.data?.taxRate.rate)) *
                  100
              )
          : splitPathInVchType === Voucher_Type.SalesVoucher
          ? newItem.data?.salesItem?.price
          : newItem.data?.purchaseItem?.price,
        taxRate: {
          rate: newItem.data?.taxRate?.rate,
          name: newItem.data?.taxRate?.name,
          id: newItem.data?.taxRate?.id,
        },
        unit: {
          value: newItem.data?.unit?.id,
          label: newItem.data?.unit?.name,
        },
      };

      // Add the new item data to itemsOption
      setItemsOption((itemsOption) => [newItemData, ...itemsOption]);

      // Call the function to handle the total values
      handleItemsNetAmmount(itemFields.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItem.data, newItem.isSaved]);

  // console.log("newItemData", itemsOption);

  //------- Set partner Data After Adding ledger ------
  useEffect(() => {
    if (addLedgerResponse) {
      // debugger;
      const exists = customerOptions.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("partyLedger", {
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        CustomerSelect(addLedgerResponse?.id);
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOptions]);

  //------- Set other expense Data After Adding ledger ------
  useEffect(() => {
    try {
      if (addLedgerResponse) {
        const exists = freightAndInsuranceOptions.some(
          (option: any) => option.id === addLedgerResponse.id
        );
        if (exists) {
          let freightInsLedgers = [
            ...(form.getFieldValue("freightInsLedgers") || []),
          ];
          freightInsLedgers.pop();
          freightInsLedgers.push({
            gstRate: 0,
            gstAmount: 0,
            totalAmount: 0,
            id: {
              label: addLedgerResponse?.ledgerName,
              value: addLedgerResponse.id,
              key: addLedgerResponse.id,
            },
          });
          freightInsLedgers.push({
            gstRate: 0,
            gstAmount: 0,
            totalAmount: 0,
            id: {
              label: "",
              value: "",
              key: "",
            },
          });
          const index = freightInsLedgers.findIndex(
            (ledgerArray) => ledgerArray?.id?.value === addLedgerResponse.id
          );
          form.setFieldValue("freightInsLedgers", freightInsLedgers);
          freightInsLedgersSelect(addLedgerResponse.id, index);
          setAddLedgerResponse(null);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freightAndInsuranceOptions]);

  //------- Set sales ledger Data After Adding ledger ------
  useEffect(() => {
    if (addLedgerResponse) {
      const exists = effectiveLedger.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("effectiveLedger", {
          ...form.getFieldValue("effectiveLedger"),
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        CustomerSelect(addLedgerResponse.id);
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectiveLedger]);

  //----- Bank (ledger) Account Get Api -----
  useEffect(() => {
    if (settingControl?.showReceipt || editInvBankDetails?.length > 0) {
      setbankAccountDropDownLoading(true);
      const bankLedger = async () => {
        await LedgerService.getLedgersByGroupName(
          currentClient?.id!,
          SearchLedgerByGroupsTypes.BankAndCash
        )
          .then((res: any) => {
            if (res) {
              console.log("data..1111.", res);
              setBankAccountList(res);
              setbankAccountDropDownLoading(false);
            }
          })
          .catch((ex: any) => {
            // setBankLoading(false);

            setBankAccountList([]);
            setbankAccountDropDownLoading(false);
          });
      };
      bankLedger();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [currentClient]);

  const handleDeleteRowButton = () => {
    handleItemsNetAmmount(0);
    setConfirmation(false);
  };

  // const handelTwoDigitAfterDecimal = (e: any) => {
  //   if (!/\d|\./.test(e.key)) e.preventDefault();
  //   if (e.key === "." && e.target.value && e.target.value.includes("."))
  //     e.preventDefault();
  // };

  React.useEffect(() => {
    // update invoice time Customer address set
    if ((updateId || cloneId) && selectedParty && onlyEditTimeCustomerOptions) {
      setOnlyEditTimeCustomerOptions(false);
      CustomerSelect(selectedParty, onlyEditTimeCustomerOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOptions, selectedParty]);

  // Customer select
  const CustomerSelect: any = async (
    value: string,
    onlyFirstEdit: boolean = false
  ) => {
    purchaseOrderRef && form.setFieldValue("purchaseOrderRef", "");
    console.log("customerAddress CustomerSelect", value, onlyFirstEdit);
    if (value) {
      // debugger;
      let findCutomerDetails;

      findCutomerDetails = customerOptions.find(
        (Ledgerlist: any) => Ledgerlist?.id === value
      );

      console.log(
        onlyFirstEdit,
        "findCutomerDetails",
        customerOptions,
        findCutomerDetails?.addresses,
        dueOnSetting
      );

      if (!onlyFirstEdit) {
        if (findCutomerDetails?.payTerms?.creditPeriod > 0) {
          form.setFieldValue(
            "dueDate",
            dayjs(dateFiled).add(
              findCutomerDetails?.payTerms?.creditPeriod,
              "d"
            )
          );
          setDueOnParty(findCutomerDetails?.payTerms?.creditPeriod);
        } else {
          form.setFieldValue(
            "dueDate",
            dayjs(dateFiled).add(dueOnSetting, "d")
          );
          setDueOnParty(0);
        }
        if (splitPathInVchType === Voucher_Type.SalesVoucher) {
          // TypeOfAddress.RegisteredAddress,
          if (findCutomerDetails?.addresses) {
            customerAddSelect(
              findCutomerDetails?.addresses[0]?.addressName,
              findCutomerDetails?.addresses[0]?.type,
              AddressEnum.BillingAddress,
              findCutomerDetails?.addresses
            );
          } else {
            form.setFieldValue(["billingAddress", "address"], "");
            form.setFieldValue(["billingAddress", "type"], {});
          }

          // setCustomerAddress(findCutomerDetails?.addresses);
        } else {
          setPurchasePartyState(findCutomerDetails?.state);
          form.setFieldValue("supplyState", findCutomerDetails?.state);
          form.validateFields(["supplyState"]);
        }
      }
      if (splitPathInVchType === Voucher_Type.SalesVoucher) {
        setCustomerAddress(findCutomerDetails?.addresses);
      }
      setEditParty({ id: value });
      setSelectedParty(value);

      // get party ref (payment invoice no.)
      await VoucherServices.getReferencesByParty(
        currentClient?.id!,
        value,
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? Voucher_Type.SalesVoucher
          : Voucher_Type.PurchaseVoucher
      )
        .then((res: any) => {
          console.log(res);
          if (res) {
            // console.log("res............", res);
            setNewPartyRecepitDetails(
              res?.result?.map((x: any, index: number) => {
                return {
                  key: index,
                  // date: dayjs(x?.date),
                  id: x?.id,
                  refNo: x?.refNo,
                  dueAmount: x?.dueAmount,
                };
              })
            );
          }
        })
        .catch((ex: any) => {
          console.log(ex.message);
        });
      setOrderDropDownLoading(true);
      //  get api order(sales & purchase order) number list
      await QuotationServices.getOrderNumber(
        currentClient?.id!,
        splitPathInVchType,
        value
      )
        .then((res: any) => {
          if (res) {
            setOrderListOptions(res?.result);
            setOrderDropDownLoading(false);

            // console.log("res.result QuotationServices......", res);
          } else {
            console.error(res?.error);
            setOrderDropDownLoading(false);
          }
        })
        .catch((err: any) => console.error(err?.message));
    }
  };

  // onChange in address type
  const customerAddSelect: any = (
    typeOfAddressName: string,
    typeOfAddress: TypeOfAddress,
    addressEnum: AddressEnum,
    addressTotal: any[]
  ) => {
    // debugger;
    console.log(
      "customerAddress",
      typeOfAddressName,
      addressTotal,
      addressEnum
    );
    if (typeOfAddressName) {
      let findAddress: any;
      if (addressTotal?.length > 0) {
        findAddress = addressTotal?.find(
          (type: any) => type?.addressName === typeOfAddressName
        );
      } else if (customerAddress?.length > 0) {
        findAddress = customerAddress?.find(
          (type: any) => type?.addressName === typeOfAddressName
        );
      }
      // console.log("Hello findAddress", findAddress);

      if (findAddress?.address) {
        // console.log("findAddress.....", findAddress?.address);
        // const objectofaddress1: any[] = [];
        let objectofaddress1: string = "";
        Object.keys(findAddress?.address).forEach((key: any) => {
          // console.log("key...........", key);
          switch (key) {
            case "building":
              if (findAddress?.address[key])
                objectofaddress1 += findAddress?.address[key] + ", ";
              break;
            case "street":
              if (findAddress?.address[key])
                objectofaddress1 += findAddress?.address[key] + ", " + "\n";
              else if (findAddress?.address[key] === "building")
                objectofaddress1 += "\n";
              break;
            case "landmark":
              if (findAddress?.address[key])
                objectofaddress1 += findAddress?.address[key] + ", ";
              break;
            case "city":
              if (findAddress?.address[key])
                objectofaddress1 += findAddress?.address[key] + ", " + "\n";
              else if (findAddress?.address[key] === "landmark")
                objectofaddress1 += "\n";
              break;
            case "district":
              if (findAddress?.address[key])
                objectofaddress1 += findAddress?.address[key] + ", ";
              break;
            case "pincode":
              if (findAddress?.address[key])
                objectofaddress1 += findAddress?.address[key] + ", " + "\n";
              else if (findAddress?.address[key] === "district")
                objectofaddress1 += "\n";
              break;
            case "state":
              objectofaddress1 +=
                getIndianStates(findAddress?.address[key]) + ", ";
              // console.log(
              //   "state",
              //   findAddress?.address[key],
              //   currentClient?.state!
              // );

              if (splitPathInVchType === Voucher_Type.PurchaseVoucher) {
                if (findAddress?.address[key]) {
                  setCurrentCustomerState(findAddress?.address[key]);
                } else {
                  setCurrentCustomerState(currentClient?.state!);
                }
              } else {
                setCurrentCustomerState(findAddress?.address[key]);
                if (
                  addressEnum === AddressEnum.BillingAddress &&
                  splitPathInVchType === Voucher_Type.SalesVoucher
                ) {
                  form.setFieldValue("supplyState", findAddress?.address[key]);
                  form.validateFields(["supplyState"]);
                }
              }

              break;
            case "country":
              if (findAddress?.address[key])
                objectofaddress1 += findAddress?.address[key];
              break;
            default:
              if (findAddress?.address[key])
                objectofaddress1 += findAddress?.address[key] + ", ";
          }
        });
        if (addressEnum === AddressEnum.BillingAddress) {
          form.setFieldValue(["billingAddress", "address"], objectofaddress1);
          form.setFieldValue(["billingAddress", "type"], {
            value: typeOfAddressName,
            label: typeOfAddressName,
            title: typeOfAddress,
          });
          if (objectofaddress1) {
            form.validateFields([["billingAddress", "address"]]);
          }
          if (isSameBillingAddress) {
            form.setFieldValue(
              ["shippingAddress", "address"],
              objectofaddress1
            );
            form.setFieldValue(["shippingAddress", "type"], {
              value: typeOfAddressName,
              label: typeOfAddressName,
              title: typeOfAddress,
            });
          }
        } else {
          form.setFieldValue(["shippingAddress", "address"], objectofaddress1);
          form.setFieldValue(["shippingAddress", "type"], {
            value: typeOfAddressName,
            label: typeOfAddressName,
            title: typeOfAddress,
          });
        }
      } else {
        if (addressEnum === AddressEnum.BillingAddress) {
          form.setFieldValue(["billingAddress", "address"], "");
          form.setFieldValue(["billingAddress", "type"], {
            value: typeOfAddressName,
            label: typeOfAddressName,
            title: typeOfAddress,
          });
          if (isSameBillingAddress) {
            form.setFieldValue(["shippingAddress", "address"], "");
            form.setFieldValue(["shippingAddress", "type"], {
              value: typeOfAddressName,
              label: typeOfAddressName,
              title: typeOfAddress,
            });
          }
        } else {
          form.setFieldValue(["shippingAddress", "address"], "");
          form.setFieldValue(["shippingAddress", "type"], {
            value: typeOfAddressName,
            label: typeOfAddressName,
            title: typeOfAddress,
          });
        }
        if (splitPathInVchType === Voucher_Type.PurchaseVoucher) {
          setCurrentCustomerState(-1);
        }
      }
    } else {
      form.setFieldValue(["billingAddress", "address"], "");
      form.setFieldValue(["billingAddress", "type"], {});
    }
  };

  // select in Items
  const ItemSelect: any = async (value: string, index: number) => {
    // console.log("Item Select ");

    setItemLength(index + 1);
    const selectedItem = itemsOption?.find((Item: any) => Item?.id === value);

    console.log("selectedItem", selectedItem);

    const IsService = selectedItem.isService;
    if (selectedItem) {
      form.setFieldValue(["items", index], {
        name: selectedItem.name,
        isService: IsService,
        isTaxable: selectedItem.isTaxable,
        id: selectedItem.id,
        description: selectedItem.description,
        hsnsac: selectedItem?.hsnCode ?? selectedItem?.sacCode,
        // account: {
        //   label: selectedItem.account?.name,
        //   value: selectedItem.account?.id,
        // },
        amount: {
          // discount: form.getFieldValue("discount"),
          qty: selectedItem.quantity,
          unit: {
            value: selectedItem.unit?.id,
            label: selectedItem.unit?.name,
          },
          price: selectedItem.isTaxable
            ? Utils.getFormattedNumber(
                (selectedItem.price / (100 + selectedItem.taxRate.rate)) * 100
              )
            : selectedItem.price,
          // discount: (settingControl.discountPolicy ===
          //                                   Discount_Policy.TradenCashDiscount ||
          //                                   settingControl.discountPolicy ===
          //                                     Discount_Policy.OnlyTradeDiscount ||
          //                                   updateTimeData.tradeDiscount)? 0 : 0,
          // discountRupee: 10,
          discount: selectedItem.discountSalesType
            ? 0
            : selectedItem.discountSalesPrice,
          discountRupee: selectedItem.discountSalesType
            ? selectedItem.discountSalesPrice
            : 0,
          tax:
            companySettings?.setting?.gstDetails?.scheme ===
              CompanyGSTTypes.Regular || updateTimeData.isGSTVoucher
              ? {
                  value: selectedItem?.taxRate?.id,
                  label: selectedItem?.taxRate?.name,
                  key: selectedItem?.taxRate?.rate,
                }
              : null,
        },
      });
      handleItemsNetAmmount(index);
    }

    const items = form.getFieldValue("items");
    const currentItems = items.filter((itm: any) => itm?.id !== undefined);

    if (value) {
      const data = itemsOption?.map((o: any) => {
        if (currentItems.some((item: any) => item?.id === o.id)) {
          return {
            ...o,
            disabled: true,
          };
        } else {
          return {
            ...o,
            disabled: false,
          };
        }
      });
      // console.log("data", data);
      setItemsOption(data);
    }
  };

  const handleAllTheTotalValues = (
    isTcsTds?: number,
    panType?: PanCardType
  ) => {
    const ItemList = form.getFieldValue(["items"]);

    // console.log("ItemList", ItemList);
    //------- Total Row Calculations -------
    const Total = ItemList.reduce(
      (
        acc: {
          TotalQty: number;
          TotalUnitPrice: number;
          ItemSubTotal: number;
          TotalDiscount: number;
          Totalgst: number;
          TotalAmount: number;
        },
        curr: any
      ) => {
        acc.TotalQty += qty(curr);
        acc.TotalUnitPrice += price(curr);
        acc.ItemSubTotal += price(curr) * qty(curr);
        acc.TotalDiscount += amountInNumber(curr, "discountRupee");
        acc.Totalgst += amountInNumber(curr, "itemTaxAmt");
        acc.TotalAmount += amountInNumber(curr, "totalAmount");
        return acc;
      },
      {
        TotalQty: 0,
        TotalUnitPrice: 0,
        ItemSubTotal: 0,
        TotalDiscount: 0,
        Totalgst: 0,
        TotalAmount: 0,
      }
    );

    console.log("Total", Total);
    setFooterTotal({
      qty: Total.TotalQty,
      unitPrice: Total.TotalUnitPrice,
      taxableAmount: Total.ItemSubTotal,
      discount: Total.TotalDiscount,
      gst: Total.Totalgst,
      amount: Total.TotalAmount,
    });

    // cash discount
    const discount = form.getFieldValue("cashDiscount");
    const discountType = form.getFieldValue("cashDiscountDrop");

    const totalSumIncludedGst =
      Total.TotalAmount + footerLedger.ledgerTotalAmount;
    const totalSumExcludedGst =
      Total.ItemSubTotal + footerLedger.ledgerAmount - Total.TotalDiscount;
    // ----- Calculate item's total amount with discount -----
    // const AmountAfterDisc = ItemList.reduce((acc: number, curr: any) => {
    //   return (acc += amountWithoutTax(curr));
    // }, 0);

    let cashDiscountAmount: number = 0.0;
    // console.log("discount", totalSum, discount, discountType, typeof discount);
    if (discount > 0) {
      if (discountType === DiscountType.PercentageDiscount) {
        // debugger;
        // if (tdsOrTcs === 0 && form.getFieldValue("tdsDetail")?.title) {
        //   cashDiscountAmount = percentCal(
        //     totalSumExcludedGst + totTcs - totTds,
        //     discount
        //   );
        // } else {
        cashDiscountAmount = percentCal(totalSumIncludedGst, discount);
        // }
        setCashDiscountRs(cashDiscountAmount);
      } else {
        cashDiscountAmount = discount;
        setCashDiscountRs(cashDiscountAmount);
      }
    } else {
      setCashDiscountRs(0.0);
    }

    let totTcs = 0;
    let totTds = 0;
    if (isTcsTds ?? tdsOrTcs) {
      //----- Calculate TCS amount -----
      const tcsRate = form.getFieldValue("tcsDetail");

      totTcs = percentCal(
        totalSumIncludedGst - cashDiscountAmount,
        tcsRate?.title
      );
      console.log("Tcs Rate", tcsRate, footerTotal.amount, tcsRate);

      setTotalTcs(totTcs);
      setTotalTds(0);
      setTcsTdsPercentage(tcsRate?.title);
    } else {
      //----- Calculate TDS amount -----
      const tdsRate = form.getFieldValue("tdsDetail");
      console.log("Tcs Rate", tdsRate, footerTotal.amount);

      const type = panType ?? partyPanCard;

      const tdsPredentage =
        type === PanCardType.HUF
          ? tdsRate.title?.rates?.indHUFRate ?? tdsRate?.title
          : type === PanCardType.Other
          ? tdsRate.title?.rates?.otherRate ?? tdsRate?.title
          : tdsRate.title?.rates?.ifPANNotRate ?? tdsRate?.title;

      setTcsTdsPercentage(tdsPredentage);
      totTds = percentCal(
        totalSumExcludedGst - cashDiscountAmount,
        tdsPredentage
      );

      // console.log("Tds Rate", tdsPredentage, totTds);
      setTotalTds(totTds);
      setTotalTcs(0);
    }

    // roundOff
    const totalAmount =
      voucherNature === Voucher_Nature.RCM
        ? Total.ItemSubTotal +
          footerLedger.ledgerAmount -
          Total.TotalDiscount -
          cashDiscountAmount +
          totTcs -
          totTds
        : Total.TotalAmount +
          footerLedger.ledgerTotalAmount -
          cashDiscountAmount +
          totTcs -
          totTds;

    console.log("roundOff11", totalAmount);

    const roundOffAmount = handleRoundoff(
      totalAmount,
      form.getFieldValue("roundoffCheckbox"),
      settingControl.roundOffType
    );

    form.setFieldValue("roundOff", roundOffAmount);
    setRoundoffAmount(roundOffAmount);
  };
  // on change in items files amount chnage
  const handleItemsNetAmmount = (index: number) => {
    // console.log(index);
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];
    console.log("items........", ItemList);
    if (currentIndexData) {
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discountRupee: Utils.getFormattedNumber(
            discountRupee(currentIndexData)
          ),
          itemTaxAmt: Utils.getFormattedNumber(itemTaxAmt(currentIndexData)),
          totalAmount: Utils.getFormattedNumber(
            amountWithTax(currentIndexData)
          ),
        },
      });
    }

    // console.log("checkinvoicedat", ItemList);
    handleAllTheTotalValues();
    // addTotalTaxAmountChange(currentIndexData);
  };

  const handleItemsAllDiscount = (discountPercentage: number) => {
    console.log("index discountPercentage", discountPercentage);
    console.log("items........", "currentIndexData", discountPercentage);
    const ItemList = form.getFieldValue(["items"]);
    if (ItemList?.length > 0) {
      ItemList?.map((x: any, index: number) => {
        // ItemList;
        const currentIndexData = ItemList[index];

        form.setFieldValue(["items", index], {
          ...currentIndexData,
          amount: {
            ...currentIndexData.amount,
            discount: discountPercentage ?? 0,
            discountRupee: Utils.getFormattedNumber(
              discountRupee(currentIndexData, discountPercentage ?? 0)
            ),
            itemTaxAmt: Utils.getFormattedNumber(
              itemTaxAmt(currentIndexData, discountPercentage ?? 0)
            ),
            totalAmount: Utils.getFormattedNumber(
              amountWithTax(currentIndexData, discountPercentage ?? 0)
            ),
          },
        });
      });
    }
    // console.log("checkinvoicedat", ItemList);
    handleAllTheTotalValues();
    // addTotalTaxAmountChange(currentIndexData);
  };

  const onDiscountAmountChange = (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    if (currentIndexData) {
      // Calculate the new values based on the discount change
      const newDiscountAmount = percentToRs(
        amountInNumber(currentIndexData, "discountRupee"),
        netAmount(currentIndexData)
      );

      const newTaxRs = percentCal(
        netAmount(currentIndexData) -
          amountInNumber(currentIndexData, "discountRupee"),
        taxRate(currentIndexData)
      );

      const newTotalAmount =
        netAmount(currentIndexData) -
        amountInNumber(currentIndexData, "discountRupee") +
        newTaxRs;

      // Update the form field with the new calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discount: Utils.getFormattedNumber(newDiscountAmount),
          itemTaxAmt: Utils.getFormattedNumber(newTaxRs),
          totalAmount: Utils.getFormattedNumber(newTotalAmount),
        },
      });

      // Call the function to handle the total values if needed
      handleAllTheTotalValues();
    }
  };

  const onItemsTotalAmountChange = async (index: number) => {
    // console.log("index...........", index);

    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    // Calculate the denominator for various calculations
    const denominator =
      100 -
      discount(currentIndexData) +
      percentCal(100 - discount(currentIndexData), taxRate(currentIndexData));

    // Calculate the price based on the given formula
    const calculatedPrice =
      (amountInNumber(currentIndexData, "totalAmount") * 100) /
      (denominator * qty(currentIndexData));

    // Calculate the discountRupee based on the given formula
    const calculatedDiscountRupee =
      (amountInNumber(currentIndexData, "totalAmount") *
        discount(currentIndexData)) /
      denominator;

    // Calculate the itemTaxAmt based on the given formula
    const calculatedTaxRs =
      (amountInNumber(currentIndexData, "totalAmount") *
        percentCal(
          100 - discount(currentIndexData),
          taxRate(currentIndexData)
        )) /
      denominator;

    if (denominator > 0 && currentIndexData?.amount?.qty > 0) {
      // Update the form field values with the calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: Utils.getFormattedNumber(calculatedPrice),
          discountRupee: Utils.getFormattedNumber(calculatedDiscountRupee),
          itemTaxAmt: Utils.getFormattedNumber(calculatedTaxRs),
        },
      });
    } else {
      // Denominator is zero or negative, set all fields to 0
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: 0,
          discountRupee: 0,
          itemTaxAmt: 0,
          totalAmount: 0,
        },
      });
    }
    handleAllTheTotalValues();
  };

  // Freight And InsLedger total in fotter
  const handleFreightAndInsLedgerTotal = () => {
    const LedgerAllList = form.getFieldValue(["freightInsLedgers"]);

    //----- Total Row Calculations---------
    const AmountLedger = LedgerAllList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.amount));
    }, 0);

    const Total = LedgerAllList.reduce(
      (
        acc: {
          amount: number;
          gstAmount: number;
          totalAmount: number;
        },
        curr: any
      ) => {
        acc.amount += Utils.getNumber(curr?.amount);
        acc.gstAmount += Utils.getNumber(curr?.gstAmount);
        acc.totalAmount += Utils.getNumber(curr?.totalAmount);
        return acc;
      },
      {
        amount: 0,
        gstAmount: 0,
        totalAmount: 0,
      }
    );
    // console.log("totalvalueoftheledger", Total);

    setFooterLedger({
      ledgerAmount: Total.amount,
      ledgerGstAmount: Total.gstAmount,
      ledgerTotalAmount: Total.totalAmount,
    });

    // ----- Calculate items total unit Price -----
    const TotalLedgerAmount = LedgerAllList.reduce((acc: number, curr: any) => {
      return (acc += amountInNumber(curr, "totalAmount"));
    }, 0);
    setLedgerAmount(TotalLedgerAmount);

    // cash discount
    const discount = form.getFieldValue("cashDiscount");
    const discountType = form.getFieldValue("cashDiscountDrop");

    const totalSum = footerTotal.amount + Total.totalAmount;
    let cashDiscountAmount: number = 0.0;
    // console.log("discount", totalSum, discount, discountType, typeof discount);
    if (discount > 0) {
      if (discountType === DiscountType.PercentageDiscount) {
        cashDiscountAmount = percentCal(totalSum, discount);
        setCashDiscountRs(cashDiscountAmount);
      } else {
        cashDiscountAmount = discount;
        setCashDiscountRs(cashDiscountAmount);
      }
    } else {
      setCashDiscountRs(0.0);
    }

    let totTcs = 0;
    let totTds = 0;
    if (tdsOrTcs) {
      //----- Calculate TCS amount -----
      const tcsRate = form.getFieldValue("tcsDetail");

      totTcs = percentCal(totalSum, tcsRate?.title);
      console.log("Tcs Rate", tcsRate, footerTotal.amount, totTcs);

      setTotalTcs(totTcs);
    } else {
      //----- Calculate TDS amount -----
      const tdsRate = form.getFieldValue("tdsDetail");

      totTds = percentCal(totalSum, tdsRate?.title);
      console.log("Tds Rate", tdsRate, totTds);
      setTotalTds(totTds);
    }

    // roundOff
    const totalAmount =
      voucherNature === Voucher_Nature.RCM
        ? footerTotal.taxableAmount +
          Total.ledgerAmount -
          footerTotal.discount -
          cashDiscountAmount +
          totTcs -
          totTds
        : footerTotal.amount +
          Total.totalAmount -
          cashDiscountAmount +
          totTcs -
          totTds;

    // -
    // (voucherNature === Voucher_Nature.RCM
    //   ? footerTotal.gst + Total.gstAmount
    //   : 0);

    const roundOffAmount = handleRoundoff(
      totalAmount,
      form.getFieldValue("roundoffCheckbox"),
      settingControl.roundOffType
    );

    form.setFieldValue("roundOff", roundOffAmount);
    setRoundoffAmount(roundOffAmount);
  };

  // on chnage in Freight And InsLedger unit price & gst
  const handleLedgerNetAmount = (index: number) => {
    const Ledgerlist = form.getFieldValue(["freightInsLedgers"]);
    const currentLedgerIndexData = Ledgerlist[index];
    // console.log(
    //   "currentLedgerIndexData",
    //   currentLedgerIndexData
    // currentLedgerIndexData,
    // Utils.getFormattedNumber(gstamount(currentLedgerIndexData)),
    // ledgertotalamout(currentLedgerIndexData)
    // );
    if (currentLedgerIndexData) {
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentLedgerIndexData,
        gstAmount: Utils.getFormattedNumber(gstamount(currentLedgerIndexData)),
        // totalAmount: Utils.getFormattedNumber(currentLedgerIndexData?.amount),
        totalAmount: Utils.getFormattedNumber(
          ledgertotalamout(currentLedgerIndexData)
        ),
      });
      handleFreightAndInsLedgerTotal();
    }

    Ledgerlist?.length === 0 && setShowFreightInsurance(false);
  };

  const onLedgerTotalAmountChange = async (index: number) => {
    const LedgerList = form.getFieldValue(["freightInsLedgers"]);
    const currentIndexData = LedgerList[index];
    // console.log("currentIndexData", currentIndexData.totalAmount);

    //Calculate the denominator for various calculations
    const denominators = gstrate(currentIndexData);
    // console.log("denominators", denominators);

    const ledgernetAmount =
      (Utils.getNumber(currentIndexData.totalAmount) / (100 + denominators)) *
      100;
    // console.log("gstAmount", ledgernetAmount);

    const gstAmount =
      Utils.getNumber(currentIndexData.totalAmount) - ledgernetAmount;
    // console.log("ledgernetAmount...", gstAmount);

    if (ledgernetAmount > 0) {
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentIndexData,
        amount: Utils.getFormattedNumber(ledgernetAmount),
        gstAmount: Utils.getFormattedNumber(gstAmount),
        // totalAmount: Utils.getFormattedNumber(
        //   ledgertotalamout(currentIndexData)
        // ),
      });
    } else {
      // ledgernetAmount is zero or negative, set all fields to 0
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentIndexData,
        amount: Utils.getFormattedNumber(ledgernetAmount),
        gstAmount: Utils.getFormattedNumber(gstAmount),
      });
    }
    handleFreightAndInsLedgerTotal();
  };

  // Include Other Expenses (Indirect Income expenses) select
  const freightInsLedgersSelect: any = async (value: any, index: number) => {
    // console.log("value11.............", value, index);

    const currentSelectLedger = freightAndInsuranceOptions.find(
      (x: any) => x.id === value
    );

    // console.log("currentSelectLedger.............", currentSelectLedger);
    if (currentSelectLedger) {
      const currentData = form.getFieldValue(["freightInsLedgers", index]);

      form.setFieldValue(["freightInsLedgers", index], {
        id: {
          value: currentSelectLedger?.id,
          label: currentSelectLedger.ledgerName,
        },
        amount: currentData?.amount,
        gstRate: {
          value: currentSelectLedger?.gstRate?.id,
          label: currentSelectLedger?.gstRate?.name,
          title: currentSelectLedger?.gstRate?.rate,
        },
        gstAmount: Utils.getFormattedNumber(gstamount(currentData)),
        totalAmount: Utils.getFormattedNumber(ledgertotalamout(currentData)),
      });
    }
    setLedgerLength(index + 1);
    handleFreightAndInsLedgerTotal();
    setSelectedOtherExpenses((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      return updatedSelectedOptions;
    });
    // console.log("currentSelectLedger", currentSelectLedger);
  };

  const hangleCashDisCount = () => {
    const discount = form.getFieldValue("cashDiscount");
    const discountType = form.getFieldValue("cashDiscountDrop");
    const totalSum =
      voucherNature === Voucher_Nature.RCM
        ? footerTotal.taxableAmount +
          footerLedger.ledgerAmount -
          footerTotal.discount
        : footerTotal.amount + footerLedger.ledgerTotalAmount;
    // console.log("discount", totalSum, discount, discountType, typeof discount);

    let totalSumIncludedGstLessCashDiscount =
      footerTotal.amount + footerLedger.ledgerTotalAmount;

    let totalSumExcludedGstLessCashDiscount =
      footerTotal.taxableAmount +
      footerLedger.ledgerAmount -
      footerTotal.discount;

    let roundOffAmount = 0;
    if (discount > 0) {
      if (discountType === DiscountType.PercentageDiscount) {
        const percentDis = percentCal(totalSum, discount);
        setCashDiscountRs(percentDis);

        totalSumIncludedGstLessCashDiscount =
          totalSumIncludedGstLessCashDiscount - percentDis;
        totalSumExcludedGstLessCashDiscount =
          totalSumExcludedGstLessCashDiscount - percentDis;

        roundOffAmount = handleRoundoff(
          totalSum - percentDis,
          form.getFieldValue("roundoffCheckbox"),
          settingControl.roundOffType
        );

        form.setFieldValue("roundOff", roundOffAmount);
        setRoundoffAmount(roundOffAmount);
        // console.log("")
        // console.log("footerTotal.taxableAmount", percentCal(totalSum, discount));
      } else {
        setCashDiscountRs(discount);
        roundOffAmount = handleRoundoff(
          totalSum - discount,
          form.getFieldValue("roundoffCheckbox"),
          settingControl.roundOffType
        );

        form.setFieldValue("roundOff", roundOffAmount);
        setRoundoffAmount(roundOffAmount);

        totalSumIncludedGstLessCashDiscount =
          totalSumIncludedGstLessCashDiscount - discount;
        totalSumExcludedGstLessCashDiscount =
          totalSumExcludedGstLessCashDiscount - discount;
        // console.log("RupeeDiscount", totalSum - discount);
      }
    } else {
      setCashDiscountRs(0.0);

      roundOffAmount = handleRoundoff(
        totalSum,
        form.getFieldValue("roundoffCheckbox"),
        settingControl.roundOffType
      );

      form.setFieldValue("roundOff", roundOffAmount);
      setRoundoffAmount(roundOffAmount);
    }

    let totTcs = 0;
    let totTds = 0;
    if (tdsOrTcs) {
      //----- Calculate TCS amount -----
      const tcsRate = form.getFieldValue("tcsDetail");

      totTcs = percentCal(totalSumIncludedGstLessCashDiscount, tcsRate?.title);
      console.log("Tcs Rate", tcsRate, footerTotal.amount, tcsRate);

      setTotalTcs(totTcs);
      setTotalTds(0);
      setTcsTdsPercentage(tcsRate?.title);
    } else {
      //----- Calculate TDS amount -----
      const tdsRate = form.getFieldValue("tdsDetail");
      console.log("Tcs Rate", tdsRate, footerTotal.amount);

      const type = partyPanCard;

      const tdsPredentage =
        type === PanCardType.HUF
          ? tdsRate.title?.rates?.indHUFRate ?? tdsRate?.title
          : type === PanCardType.Other
          ? tdsRate.title?.rates?.otherRate ?? tdsRate?.title
          : tdsRate.title?.rates?.ifPANNotRate ?? tdsRate?.title;

      setTcsTdsPercentage(tdsPredentage);
      totTds = percentCal(totalSumExcludedGstLessCashDiscount, tdsPredentage);

      // console.log("Tds Rate", tdsPredentage, totTds);
      setTotalTds(totTds);
      setTotalTcs(0);
    }
  };

  console.log("ItemId", itemsOption);

  const handleNetAmmount = (index: number) => {
    const value = (val: any, key: any) => Utils.getNumber(val?.amount[key]);
    let currentIndexData = form.getFieldValue(["productItems"])[index];
    // setSubTotal(
    //   form
    //     .getFieldValue(["productItems"])
    //     .reduce((acc: number, curr: any) => {
    //       return (acc += value(curr, "price") * value(curr, "qty"));
    //     }, 0)
    // );

    form.setFieldValue(["productItems", index], {
      ...currentIndexData,
      amount: {
        ...currentIndexData.amount,
        net: value(currentIndexData, "price") * value(currentIndexData, "qty"),
      },
    });

    // gst total before and after discount
    let productData = form.getFieldValue(["productItems"]);
    let disValue = form.getFieldValue(["amount", "dics"]);
    let preValue: number = 0;

    for (let i = 0; i < productData?.length - 1; i++) {
      let replace = productData[i]?.amount?.rate?.label.replace("%", "");
      let netAfterDis =
        Utils.getNumber(productData[i]?.amount?.net) -
        (Utils.getNumber(productData[i]?.amount?.net) *
          Utils.getNumber(disValue)) /
          100;
      let gst =
        ((disValue === "" || 0
          ? Utils.getNumber(productData[i]?.amount?.net)
          : netAfterDis) *
          Utils.getNumber(replace)) /
        100;
      preValue = preValue + gst;
      // setGstTotal(preValue);
    }

    // const dis = (Utils.getNumber(subTotal) * Utils.getNumber(disValue)) / 100;
    // setDiscount(dis);
  };

  const [selectedInput, setSelectedInput] = React.useState<any>([
    "description",
    "hsnCode",
    "unit",
    "discount",
  ]);

  const handleCheckboxChange = (value: string) => {
    setSelectedInput((prev: any) =>
      prev.includes(value)
        ? prev.filter((col: any) => col !== value)
        : [...prev, value]
    );
  };

  const columnOptions = [
    { label: "Discription", value: "description" },
    // { label: "HSN Code", value: "hsnCode" },
    { label: "Unit", value: "unit" },
    { label: "discount", value: "discount" },
    // { label: "GST", value: "gst" },
  ];

  const content = (
    <Space direction="vertical" style={{ padding: "0px 15px 0px 0px" }}>
      <>
        {columnOptions?.map((option) => (
          <Checkbox
            // style={{ paddingRight: "4px" }}
            key={option.value}
            checked={selectedInput.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
          >
            <span style={{ paddingLeft: "4px" }}> {option.label}</span>
          </Checkbox>
        ))}
        <Row justify={"end"}>
          <Col>
            <Button size="small" type="primary">
              Save
            </Button>
          </Col>
        </Row>
      </>
    </Space>
  );

  const voucherNatureOptions = [
    { label: "Regular", value: Voucher_Nature.Regular },
    { label: "Export", value: Voucher_Nature.ImportExport },
    { label: "Deemed Export", value: Voucher_Nature.DeemedExport },
    { label: "SEZ", value: Voucher_Nature.SEZ },
  ];

  const addVoucherNatureOptions = {
    label: "Reverse Charge",
    value: Voucher_Nature.RCM,
  };

  // --------- Get TDS Option List API ---------
  React.useEffect(() => {
    if (splitPathInVchType === Voucher_Type.PurchaseVoucher) {
      const getTDSList = async (
        start: number,
        length: number,
        isActive?: boolean,
        search?: string
      ) => {
        await TdsTcsServices.getCompanyTDSList(
          currentClient?.id!,
          start,
          length,
          search
        )
          .then((res: any) => {
            if (res.length > 0) {
              setTdsList(res);
              // setTotalRecords(res?.totalRecords);
            } else if (res.length === 0) {
              setTdsList([]);
              // setTotalRecords(res?.totalRecords);
            }
            setLoading(false);
          })
          .catch((ex: any) => {
            console.error(ex.message);
            // openNotification("error", ex.message);
            setLoading(false);
            setTdsList([]);
          });
      };

      getTDSList(0, 0);
    }
  }, [splitPathInVchType, tdsListTrigger]);

  // --------- Get TCS TableData API ---------
  React.useEffect(() => {
    const getList = async (
      start: number,
      length: number,
      isActive?: boolean,
      search?: string
    ) => {
      await TdsTcsServices.getTCSList(start, length, isActive, search)
        .then((res: any) => {
          // console.log("res", res);

          if (res.length > 0) {
            setTcsList(res);
            // setTotalRecords(res?.totalRecords);
          } else if (res.length === 0) {
            setTcsList([]);
            // setTotalRecords(res?.totalRecords);
          }
          setLoading(false);
        })
        .catch((ex: any) => {
          console.error(ex.message);
          // openNotification("error", ex.message);
          setLoading(false);
          setTcsList([]);
        });
    };
    getList(0, 0);
  }, [tcsListTrigger]);

  console.log("data", isPartTDSOrTDS.tcs, settingControl.showTcsTds);

  console.log("alldata", form.getFieldsValue(["items"]));

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 0px 10px 0px #96A1C84D",
          marginTop: 7,
          height: "100%",
          overflowY: "auto",
        }}
        title={
          <Row
            // justify="space-between"
            style={{ display: "flex", justifyContent: "space-between" }}
            // style={{ backgroundColor: "whitesmoke", padding: 0 }}
          >
            <Col className="fs-20 fw-200 themeColor">
              <span>{settingControl?.title} </span>

              <Dropdown
                //overlay={itemsabc}
                menu={{
                  items:
                    splitPathInVchType === Voucher_Type.SalesVoucher
                      ? SalesDropdownItems()
                      : PurchaseDropdownItems(),
                  selectable: true,
                  defaultSelectedKeys: [
                    location?.pathname?.split("/")[5] === "addInvoice" ||
                    "editInvoice"
                      ? "0"
                      : "-1",
                  ],
                }}
                trigger={["click"]}
                autoFocus={true}
              >
                <Typography.Link>
                  <Space>
                    <DownOutlined
                    // style={{
                    //   fontSize: "12px",
                    //   color: "black",
                    //   padding: "0px 0px 10px 10px",
                    // }}
                    />
                  </Space>
                </Typography.Link>
              </Dropdown>
            </Col>
            <Col>
              <div>
                <Select
                  style={{ width: "150px", marginRight: "10px" }}
                  // labelInValue
                  // options={
                  //   settingControl.showRCM || updateTimeData.showRCM
                  //     ? [...voucherNatureOptions, addVoucherNatureOptions]
                  //     : voucherNatureOptions
                  // }
                  // defaultValue={{
                  //   value: SettingsSections.Invoice,
                  //   label: "TAX INVOICE",
                  // }}
                  options={findVoucharCustomizationTemplete(
                    splitPathInVchType === Voucher_Type.SalesVoucher
                      ? SettingsSections.Invoice
                      : SettingsSections.PInvoice,
                    companySettings?.setting?.vouchersSettings
                  )}
                  value={selectCustomizationTemplete}
                  onChange={(e: string) => {
                    console.log("alll", e);
                    setSelectCustomizationTemplete(e);
                    setDefaultEffectedAccount(e);
                  }}
                />

                {splitPathInVchType === Voucher_Type.PurchaseVoucher && (
                  <>
                    <Select
                      style={{ width: "150px", marginRight: "10px" }}
                      options={
                        settingControl.showRCM || updateTimeData.showRCM
                          ? [...voucherNatureOptions, addVoucherNatureOptions]
                          : voucherNatureOptions
                      }
                      defaultValue={Voucher_Nature.Regular}
                      value={voucherNature}
                      onChange={(e: Voucher_Nature) => {
                        // console.log(e);
                        setVoucherNature(e);
                        const roundOffAmount = handleRoundoff(
                          e === Voucher_Nature.RCM
                            ? footerTotal.taxableAmount +
                                footerLedger.ledgerAmount -
                                footerTotal.discount -
                                Math.round(cashDiscountRs * 100) / 100
                            : footerTotal.amount +
                                footerLedger.ledgerTotalAmount -
                                Math.round(cashDiscountRs * 100) / 100,
                          form.getFieldValue("roundoffCheckbox"),
                          settingControl.roundOffType
                        );

                        form.setFieldValue("roundOff", roundOffAmount);
                        setRoundoffAmount(roundOffAmount);
                      }}
                    />

                    <Select
                      style={{ width: "150px", margin: "0px" }}
                      options={[
                        {
                          value: false,
                          label: "Item Mode",
                        },
                        {
                          value: true,
                          label: "Expenses Mode",
                        },
                      ]}
                      defaultValue={false}
                      value={mode}
                      onChange={(e: boolean) => {
                        setMode(e);

                        e && form.validateFields(["effectiveLedger"]);
                        e && setShowFreightInsurance(e);
                        form.setFieldValue("items", [{}]);
                        setFooterTotal({
                          qty: 0,
                          unitPrice: 0,
                          taxableAmount: 0,
                          discount: 0,
                          gst: 0,
                          amount: 0,
                        });
                      }}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        }
      >
        <div>
          {/* bankAccountList?.length === 0 || */}
          {loading ||
          (updateId || cloneId || draftId
            ? customerOptions?.length === 0
            : false) ||
          (settingControl?.showReceipt || editInvBankDetails?.length > 0
            ? bankAccountList?.length === 0
            : false) ? (
            <BodySkeleton
              loading={
                loading ||
                (updateId || cloneId || draftId
                  ? customerOptions?.length === 0
                  : false) ||
                (settingControl?.showReceipt || editInvBankDetails?.length > 0
                  ? bankAccountList?.length === 0
                  : false)
              }
              setLoading={setLoading}
            />
          ) : (
            <Form
              form={form}
              autoComplete="off"
              name="salesInvoice"
              requiredMark={false}
              initialValues={{
                roundoffCheckbox: settingControl.autoRoundOff,
                tcsDetail: 0,
                tdsDetail: 0,
                showFreightInsLedgers: showFreightInsurance,
                // isPurchaseJVVoucher: false,
                items: [
                  {
                    amount: { price: 0.0, qty: 0, totalAmount: 0 },
                  },
                ],
                cashDiscountDrop: DiscountType.PercentageDiscount,
                freightInsLedgers: [
                  {
                    gstRate: 0,
                    gstAmount: 0,
                    totalAmount: 0,
                    // amount: 0,
                  },
                ],
                // bankDts: location.state?.ledgerId
                //   ? [
                //       {
                //         name: {
                //           value: location.state?.ledgerId,
                //         },
                //         amount:
                //           location?.state?.BankTransactionData?.moneyIn ||
                //           location?.state?.BankTransactionData?.moneyOut,
                //         date:

                //       },
                //     ]
                //   : [{}],

                bankDts: [{}],
              }}
            >
              <Row gutter={10}>
                <Col xl={12} lg={10} sm={24}>
                  <Row gutter={10}>
                    {/* select party */}
                    <Col sm={12} xs={24}>
                      <Row justify="space-between">
                        <Col className=" fw-500">Party</Col>
                        {selectedParty && (
                          <Col className="fw-500">
                            <Tooltip title="View Party">
                              <EyeOutlined
                                onClick={() => {
                                  setEditParty({ id: selectedParty });
                                  setOpenModel({
                                    type: "addLedger",
                                    param: {
                                      open: true,
                                      ledgerByGroupsType:
                                        SearchLedgerByGroupsTypes.PartiesOnly,
                                      showName: "Party",
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </Col>
                        )}
                      </Row>

                      <Form.Item
                        name="partyLedger"
                        className="m-0 selectPlaceholder selectedItem errormsg"
                        rules={[
                          { required: true, message: "Select a customer" },
                        ]}
                        style={{
                          paddingBottom:
                            selectedParty || updateId || cloneId || draftId
                              ? undefined
                              : "54px",
                        }}
                      >
                        <Select
                          ref={inputRef}
                          loading={partyDropDownLoading}
                          showSearch
                          allowClear
                          labelInValue
                          optionFilterProp="children"
                          placeholder="Party Name"
                          // filterOption={false}
                          className="selectedItem"
                          disabled={
                            salesOrderId || purchaseOrderId || quotationId
                              ? true
                              : false
                          }
                          // loading
                          autoFocus={true}
                          // onSearch={(value: string) =>
                          //   setSearchCustomer({ search: value, length: 15 })
                          // }
                          filterOption={(input, option) =>
                            (option?.label?.toString() ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          // onClick={() =>
                          //   setSearchCustomer({ ...searchCustomer, search: "" })
                          // }
                          onChange={(e: any) => {
                            const party = customerOptions.find(
                              (x: any) => x.id === e?.value
                            );
                            let PanType: PanCardType;
                            if (party.pan) {
                              if (
                                party?.pan?.split("")[3]?.toUpperCase() ===
                                  "H" ||
                                party?.pan?.split("")[3]?.toUpperCase() === "P"
                              ) {
                                PanType = PanCardType.HUF;
                              } else {
                                PanType = PanCardType.Other;
                              }
                            } else {
                              PanType = PanCardType.notAvailable;
                            }
                            setPartyPanCard(PanType);

                            if (form.getFieldValue("tdsDetail")?.value) {
                              handleAllTheTotalValues(tdsOrTcs, PanType);
                            }

                            form.setFieldValue(
                              ["shippingAddress", "address"],
                              ""
                            );
                            form.setFieldValue(["shippingAddress", "type"], "");
                            console.log(
                              "dataField",
                              party?.ledgerTCSApplicability
                            );

                            setIsPartTDSOrTDS({
                              tds: party?.deductTDSinSameVoucher,
                              tcs: party?.ledgerTCSApplicability,
                            });
                            // Party Wise Tcs & Tds condition

                            if (
                              splitPathInVchType === Voucher_Type.SalesVoucher
                            ) {
                              if (
                                party?.ledgerTCSApplicability &&
                                settingControl?.showTcsTds
                              ) {
                                setTotalTcs(totalTcs);
                                setTcsTdsPercentage(tcsTdsPercentage);

                                form.setFieldValue(
                                  "tcsDetail",
                                  form.getFieldValue("tcsDetail")
                                );
                                handleAllTheTotalValues();
                              } else {
                                setTotalTcs(0);
                                // form.setFieldValue("tcsDetail", {});
                                // setTcsTdsPercentage(0);
                              }
                            } else {
                              // debugger;
                              if (
                                party?.deductTDSinSameVoucher &&
                                settingControl?.showTcsTds
                              ) {
                                setTotalTds(totalTds);
                                setTcsTdsPercentage(tcsTdsPercentage);
                                // setTdsPostDetail({
                                //   formNo: tdsSelect.form,
                                //   transactionType: tdsSelect.transactionType,
                                // });
                                form.setFieldValue(
                                  "tdsDetail",
                                  form.getFieldValue("tdsDetail")
                                );
                                handleAllTheTotalValues();
                              } else {
                                setTotalTds(0);
                                // form.setFieldValue("tdsDetail", {});
                                // setTcsTdsPercentage(0);
                              }
                            }
                            CustomerSelect(e?.value);
                            // }
                          }}
                          options={customerOptions?.map((value: any) => ({
                            value: value?.id,
                            label: value?.ledgerName.concat(
                              value?.aliasName
                                ? `${" (" + value?.aliasName + ")"}`
                                : ""
                            ),
                          }))}
                          dropdownRender={(customerOptions) => (
                            <>
                              {customerOptions}
                              <Divider style={{ margin: "5px 0px" }} />
                              <Button
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() => {
                                  setEditParty({ id: "" });
                                  setOpenModel({
                                    type: "addLedger",
                                    param: {
                                      open: true,
                                      defaultGroup:
                                        splitPathInVchType ===
                                        Voucher_Type.SalesVoucher
                                          ? {
                                              id: "6603afaa99242bad67d446cd",
                                              ledgerName:
                                                "Trade Receivables - Sundry Debtors",
                                              parent:
                                                "65af9ce61a48db26effbb598",
                                            }
                                          : {
                                              id: "6603afe599242bad67d446d3",
                                              ledgerName:
                                                "Trade Payables - Sundry Creditors",
                                              parent:
                                                "65af9df71a48db26effbb5a3",
                                            },
                                      ledgerByGroupsType:
                                        splitPathInVchType ===
                                        Voucher_Type.SalesVoucher
                                          ? SearchLedgerByGroupsTypes.DebtorsOnly
                                          : SearchLedgerByGroupsTypes.CreditorsOnly,
                                      showName:
                                        splitPathInVchType ===
                                        Voucher_Type.SalesVoucher
                                          ? "Customers"
                                          : "Suppliers",
                                    },
                                  });
                                }}
                              >
                                {splitPathInVchType ===
                                Voucher_Type.SalesVoucher
                                  ? "Customers"
                                  : "Suppliers"}
                              </Button>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    {/* select ledger */}
                    {/* {mode ? (
                      <Col sm={12}></Col>
                    ) : ( */}
                    <Col sm={12} xs={24}>
                      <span className="fw-500">
                        {splitPathInVchType === Voucher_Type.SalesVoucher
                          ? "Sales Ledger"
                          : "Purchase Ledger"}
                      </span>
                      <Form.Item
                        name="effectiveLedger"
                        className="mb-3 errormsg"
                        rules={[
                          {
                            required: mode ? false : true,
                            message: `Please select ${
                              splitPathInVchType === Voucher_Type.SalesVoucher
                                ? "Sales Ledger"
                                : "Purchase Ledger"
                            }`,
                          },
                        ]}
                      >
                        <Select
                          disabled={mode}
                          loading={ledgerDropDownLoading}
                          labelInValue
                          showSearch
                          allowClear={false}
                          placeholder="Select ledger"
                          popupMatchSelectWidth={false}
                          placement="bottomLeft"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label?.toString() ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={effectiveLedger?.map((x: any) => ({
                            value: x?.id,
                            label: x?.ledgerName.concat(
                              x?.aliasName ? `${" (" + x?.aliasName + ")"}` : ""
                            ),
                          }))}
                          dropdownRender={(effectiveLedgerOptions) => (
                            <>
                              {effectiveLedgerOptions}
                              <Divider style={{ margin: "5px 0px" }} />
                              <Button
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() => {
                                  setEditParty({ id: "" });
                                  setOpenModel({
                                    type: "addLedger",
                                    param: {
                                      open: true,
                                      defaultGroup:
                                        splitPathInVchType ===
                                        Voucher_Type.SalesVoucher
                                          ? {
                                              id: "6603b14799242bad67d446ee",
                                              ledgerName: "Domestic Sales",
                                              parent:
                                                "65af9be21a48db26effbb591",
                                            }
                                          : {
                                              id: "6603b13f99242bad67d446ed",
                                              ledgerName: "Domestic Purchases",
                                              parent:
                                                "65af9b7d1a48db26effbb58e",
                                            },
                                      ledgerByGroupsType:
                                        splitPathInVchType ===
                                        Voucher_Type.SalesVoucher
                                          ? SearchLedgerByGroupsTypes.Sales
                                          : SearchLedgerByGroupsTypes.Purchase,
                                      showName:
                                        splitPathInVchType ===
                                        Voucher_Type.SalesVoucher
                                          ? "Sales"
                                          : "Purchase",
                                    },
                                  });
                                  // setEditParty({ id: "" });
                                }}
                              >
                                New Ledger
                              </Button>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    {/* )} */}

                    {/* Billing Address */}
                    {(selectedParty || updateId || cloneId || draftId) && (
                      <>
                        <Col sm={12} xs={24}>
                          <span className="fw-500">Billing Address</span>

                          <Form.Item
                            name={["billingAddress", "type"]}
                            className="inputBoxMb"
                            // rules={[
                            //   {
                            //     required:
                            //       splitPathInVchType ===
                            //       Voucher_Type.PurchaseVoucher
                            //         ? true
                            //         : false,
                            //     message: "Address type is required ",
                            //   },
                            // ]}
                          >
                            <Select
                              showSearch
                              allowClear
                              optionFilterProp="children"
                              placeholder="Select type"
                              filterOption={false}
                              className="selectedItem"
                              labelInValue
                              options={customerAddress?.map(
                                (x: any, index: number) => ({
                                  value: x?.addressName,
                                  label: x?.addressName,
                                  title: x?.type,
                                  key: index,
                                })
                              )}
                              onChange={(e: any) =>
                                customerAddSelect(
                                  e.value,
                                  e.title,
                                  AddressEnum.BillingAddress,
                                  []
                                )
                              }
                            />
                          </Form.Item>
                        </Col>

                        {/* Shipping address */}
                        {(settingControl.showShippingAddress ||
                          updateTimeData.shippingAddress) && (
                          <Col sm={12} xs={24}>
                            <Row>
                              <Col>
                                <span className=" fw-500">
                                  Shipping Address
                                </span>
                              </Col>

                              <Col style={{ paddingLeft: "10px" }}>
                                <Checkbox
                                  className="checkBox-custom"
                                  // style={{ margin: "0px", minHeight: "0px" }}
                                  checked={isSameBillingAddress}
                                  onChange={(e) => {
                                    // console.log("e", e.target.checked);
                                    setIsSameBillingAddress(e.target.checked);
                                    if (e.target.checked) {
                                      form.setFieldValue(
                                        ["shippingAddress", "type"],
                                        form.getFieldValue([
                                          "billingAddress",
                                          "type",
                                        ])
                                      );

                                      form.setFieldValue(
                                        ["shippingAddress", "address"],
                                        form.getFieldValue([
                                          "billingAddress",
                                          "address",
                                        ])
                                      );
                                    }
                                  }}
                                />
                                {/* </Form.Item> */}
                              </Col>
                            </Row>
                            <Form.Item
                              name={["shippingAddress", "type"]}
                              className="inputBoxMb"
                            >
                              <Select
                                disabled={isSameBillingAddress}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                placeholder="Select type"
                                filterOption={false}
                                className="selectedItem"
                                labelInValue
                                options={customerAddress?.map(
                                  (x: any, index: number) => ({
                                    value: x?.addressName,
                                    label: x?.addressName,
                                    title: x?.type,
                                    key: index,
                                  })
                                )}
                                onChange={(e: any) =>
                                  customerAddSelect(
                                    e.value,
                                    e.title,
                                    AddressEnum.ShippingAddress,
                                    []
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                        )}

                        {/* Billing Address TextArea */}
                        <Col sm={12} xs={24}>
                          <Form.Item
                            name={["billingAddress", "address"]}
                            className="inputBoxMb"
                            rules={[
                              {
                                required: true,
                                message: "Billing address is required",
                              },
                            ]}
                            style={{ paddingTop: "4px" }}
                          >
                            <TextArea
                              placeholder="Billing address"
                              rows={3}
                              onBlur={() => {
                                if (isSameBillingAddress) {
                                  form.setFieldValue(
                                    ["shippingAddress", "type"],
                                    form.getFieldValue([
                                      "billingAddress",
                                      "type",
                                    ])
                                  );

                                  form.setFieldValue(
                                    ["shippingAddress", "address"],
                                    form.getFieldValue([
                                      "billingAddress",
                                      "address",
                                    ])
                                  );
                                }
                              }}
                              // style={{ borderTop: "none",  }}
                            />
                          </Form.Item>
                        </Col>
                        {/* Shipping Address TextArea */}
                        {(settingControl?.showShippingAddress ||
                          updateTimeData.shippingAddress) && (
                          <Col sm={12} xs={24}>
                            <Form.Item
                              name={["shippingAddress", "address"]}
                              className="inputBoxMb"
                              style={{ paddingTop: "4px" }}
                            >
                              <TextArea
                                rows={3}
                                placeholder="Shipping address"
                                disabled={isSameBillingAddress}
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </Col>

                <Col xl={12} lg={14} sm={24}>
                  <Row gutter={10}>
                    {/* Invoice Number */}
                    <Col md={8} sm={12} xs={24}>
                      <div>
                        <span className=" fw-500">
                          {splitPathInVchType === Voucher_Type.SalesVoucher
                            ? " Invoice No. "
                            : "Bill No. "}
                        </span>
                        <ReloadOutlined
                          style={{ fontSize: "12px" }}
                          className="textEnd"
                          onClick={() => setRefreshNumber(!refreshNumber)}
                        />
                      </div>
                      <Form.Item
                        name="number"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Invoice No.",
                          },
                        ]}
                        style={{ width: "100%" }}
                      >
                        {voucherNumberLoader ? (
                          <Skeleton.Input
                            active={true}
                            size="default"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <Input
                            disabled
                            value="Invoice No."
                            style={{ width: "100%" }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {/* Date */}
                    <Col md={8} sm={12} xs={24}>
                      <span className=" fw-500">Date</span>
                      <Form.Item
                        name="date"
                        className="m-0"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Select Date",
                        //   },
                        // ]}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          // disabledDate={(current) =>
                          //   current && current > dayjs().endOf("day")
                          // }
                          style={{ width: "100%" }}
                          allowClear={false}
                          onChange={(val) => {
                            // console.log("val",  val.toISOString());

                            if (dueOnParty) {
                              form.setFieldValue(
                                "dueDate",
                                dayjs(val).add(dueOnParty, "d")
                              );
                            } else {
                              form.setFieldValue(
                                "dueDate",
                                dayjs(val).add(dueOnSetting, "d")
                              );
                            }
                            setDateFiled(dayjs(val).toISOString());
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {/* Due Date */}
                    <Col md={8} sm={12} xs={24}>
                      <span className=" fw-500">Due Date</span>
                      <Form.Item
                        name="dueDate"
                        className="m-0"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Select Due Date",
                        //   },
                        // ]}
                      >
                        <DatePicker
                          disabledDate={(current) =>
                            // current &&
                            current <=
                            form.getFieldValue("date")?.startOf("day")
                          }
                          allowClear={false}
                          format="DD/MM/YYYY"
                          style={{ width: "100%" }}
                          open={isOpenDueDate}
                          onClick={() => {
                            setIsDueDate((x) => !x);
                          }}
                          // onBlur={(e) => {
                          //   e.relatedTarget === null
                          //     ? setIsDueDate(false)
                          //     : setIsDueDate(true);
                          //   // e.target.id === "sales Invoice_dueDate"
                          //   //   ? setIsDueDate(false)
                          //   //   : setIsDueDate(true);
                          // }}
                          // onMouseEnter={(e) => setIsDueDate(false)}

                          onChange={(e) =>
                            inputRef ? setIsDueDate(false) : setIsDueDate(true)
                          }
                          presets={
                            windowWidth > 550
                              ? [
                                  {
                                    label: "Tomorrow",
                                    value: dayjs().add(1, "d"),
                                  },
                                  {
                                    label: "After 7 days",
                                    value: dayjs().add(7, "d"),
                                  },
                                  {
                                    label: "After 15 days",
                                    value: dayjs().add(15, "d"),
                                  },
                                  {
                                    label: "After 30 days",
                                    value: dayjs().add(30, "d"),
                                  },
                                  {
                                    label: "After 45 days",
                                    value: dayjs().add(45, "d"),
                                  },
                                  {
                                    label: "After 60 days",
                                    value: dayjs().add(60, "d"),
                                  },
                                  {
                                    label: "After 90 days",
                                    value: dayjs().add(90, "d"),
                                  },
                                  {
                                    label: "After 180 days",
                                    value: dayjs().add(180, "d"),
                                  },
                                  {
                                    label: "Due on Receipt",
                                    value: dayjs(),
                                  },
                                ]
                              : []
                          }
                          renderExtraFooter={() => (
                            <Space
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <InputNumber
                                placeholder="After"
                                style={{ width: "60px" }}
                                controls={false}
                                onChange={(e) =>
                                  setPresetsDueDate({
                                    ...presetsDueDate,
                                    label: e,
                                  })
                                }
                                onBlur={(e) => {
                                  e.relatedTarget === null
                                    ? setIsDueDate(false)
                                    : setIsDueDate(true);
                                }}
                                onClick={() =>
                                  inputRef.current && inputRef.current.focus()
                                }
                                ref={inputRef}
                                // onBlur={async (e) => {
                                //   e.relatedTarget === null
                                //     ? setIsDueDate(false)
                                //     : setIsDueDate(true);
                                //   //  e.target.id ===
                                //   //   "sales Invoice_dueDate" ||
                                //   // e.target.placeholder === "After"
                                //   //   ? setIsDueDate(false)
                                //   //   : setIsDueDate(true);
                                // }}
                                // onMouseDown={(e) => setIsDueDate(true)}
                              />
                              <Select
                                allowClear
                                onBlur={(e) => {
                                  e.relatedTarget === null
                                    ? setIsDueDate(false)
                                    : setIsDueDate(true);
                                }}
                                style={{ width: "100px" }}
                                // onMouseDown={(e) => e.stopPropagation()}
                                onChange={(e: any) => {
                                  setPresetsDueDate({
                                    ...presetsDueDate,
                                    value: e,
                                  });
                                }}
                                options={[
                                  { value: "d", label: "Days" },
                                  { value: "w", label: "Weeks" },
                                  { value: "month", label: "Months" },
                                  { value: "year", label: "Years" },
                                ]}
                              />
                              <Tooltip title="Save" placement="bottom">
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setIsDueDate(false);
                                    form.setFieldValue(
                                      "dueDate",
                                      form
                                        .getFieldValue("date")
                                        .add(
                                          presetsDueDate.label,
                                          presetsDueDate.value
                                        )
                                    );
                                  }}
                                  icon={<SaveOutlined />}
                                />
                              </Tooltip>
                              <Tooltip title="Cancel" placement="right">
                                <Button
                                  onClick={() => {
                                    setIsDueDate(false);
                                    // form.setFieldValue(
                                    //   "dueDate",
                                    //   dayjs().add(0, "d")
                                    // );
                                  }}
                                  icon={<CloseOutlined />}
                                />
                              </Tooltip>
                            </Space>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    {/* Place Of Supply */}
                    {selectedParty && (
                      <Col md={8} sm={12} xs={24}>
                        <span className="fw-500">
                          {splitPathInVchType === Voucher_Type.SalesVoucher
                            ? "Place of Supply"
                            : "Origin of Supply"}
                        </span>
                        <Form.Item
                          name="supplyState"
                          className="mb-3 errormsg"
                          rules={[
                            {
                              required: true,
                              message: "Place of supply is required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear={false}
                            placeholder="Select State"
                            popupMatchSelectWidth={false}
                            placement="bottomLeft"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onSelect={(val) => {
                              console.log("val", val);

                              if (
                                splitPathInVchType === Voucher_Type.SalesVoucher
                              ) {
                                setCurrentCustomerState(val);
                              } else {
                                setPurchasePartyState(val);
                              }
                            }}
                            options={Object?.entries(stateOptions)?.map(
                              ([key, x]: any) => ({
                                value: x?.id,
                                label: x?.name,
                              })
                            )}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {/*  Phone No.*/}
                    {(settingControl.showContactDetail ||
                      updateTimeData.contactDetails) && (
                      <>
                        <Col md={8} sm={12} xs={24}>
                          <span className=" fw-500">Phone No.</span>
                          <Form.Item
                            name="contactNo"
                            className="inputBoxMb"
                            rules={[
                              {
                                min: 10,
                                message: "phone no. must be 10 digits",
                              },
                            ]}
                          >
                            <Input
                              placeholder="9876543210"
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                            />
                          </Form.Item>
                        </Col>
                        {/* Contact Name  */}
                        <Col md={8} sm={12} xs={24}>
                          <span className=" fw-500">Contact Name</span>
                          <Form.Item name="contactName" className="inputBoxMb">
                            <Input placeholder="Enter name" />
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    {/* order no */}
                    {selectedParty && (
                      <Col md={8} sm={12} xs={24}>
                        <span className=" fw-500">
                          {splitPathInVchType === Voucher_Type.SalesVoucher
                            ? "Sales Order No."
                            : "Purchase Order No."}
                        </span>
                        <Form.Item
                          name="purchaseOrderRef"
                          className="mb-3 errormsg"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Place of supply is required",
                          //   },
                          // ]}
                        >
                          <Select
                            disabled={salesOrderId ? true : false}
                            loading={orderDropDownLoading}
                            showSearch
                            allowClear
                            placeholder="Select Order"
                            popupMatchSelectWidth={false}
                            placement="bottomLeft"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(e: any) => {
                              setPurchaseOrderRef(e);
                              if (e === undefined || e === null) {
                                form.setFieldValue("items", [
                                  {
                                    amount: {
                                      price: 0.0,
                                      qty: 0,
                                      totalAmount: 0,
                                    },
                                  },
                                ]);
                                form.setFieldValue("freightInsLedgers", [
                                  {
                                    gstRate: 0,
                                    gstAmount: 0,
                                    totalAmount: 0,
                                    amount: 0,
                                  },
                                ]);
                                setFooterTotal({
                                  qty: 0,
                                  unitPrice: 0,
                                  taxableAmount: 0,
                                  discount: 0,
                                  gst: 0,
                                  amount: 0,
                                });
                                setFooterLedger({
                                  ledgerAmount: 0,
                                  ledgerGstAmount: 0,
                                  ledgerTotalAmount: 0,
                                });
                              }
                            }}
                            options={orderListOptions?.map((x: any) => ({
                              value: x?.voucherId,
                              label: x?.voucherNumber,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {/* Add Receipt Note */}
                    {(settingControl.showDispatchDetail ||
                      updateTimeData.showDispatchDetail) && (
                      <Col md={8} sm={12} xs={24}>
                        <span className="fw-500">
                          {splitPathInVchType === Voucher_Type.PurchaseVoucher
                            ? "Receipt Note"
                            : "Despatch Details"}
                        </span>
                        <Button
                          icon={<PlusOutlined />}
                          style={{
                            width: "100%",
                            backgroundColor: "ButtonFace",
                          }}
                          onClick={() =>
                            setOpenModel({
                              type: "addDespatchDetail",
                              param: { open: true },
                            })
                          }
                        >
                          {splitPathInVchType === Voucher_Type.PurchaseVoucher
                            ? "Add Receipt Note"
                            : " Add Despatch Details"}
                        </Button>
                      </Col>
                    )}

                    {/* Freight Type */}
                    {(settingControl.showFreightType ||
                      updateTimeData.showFreightType) && (
                      <Col md={8} sm={12} xs={24}>
                        <span className="fw-500">Freight Type</span>
                        <Form.Item name="freightType" className="mb-3 errormsg">
                          <Select
                            // disabled={salesOrderId ? true : false}
                            showSearch
                            options={[
                              {
                                value: Freight_Type.ExWorks,
                                label: "Ex-Works",
                              },
                              {
                                value: Freight_Type.CIF,
                                label: "Cost, Insurance and Freight",
                              },
                              {
                                value: Freight_Type.FOB,
                                label: "Freight on Board",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              {/* Item Table */}

              <div
                // className="tableWrapper"
                style={{ overflowY: "auto" }}
              >
                <Row
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    backgroundColor: "whitesmoke",
                    padding: "5px 10px",
                    fontSize: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  <Col span={12}>
                    <span>Item Detail</span>
                  </Col>

                  {(settingControl.discountPolicy ===
                    Discount_Policy.TradenCashDiscount ||
                    settingControl.discountPolicy ===
                      Discount_Policy.OnlyTradeDiscount ||
                    updateTimeData.tradeDiscount) &&
                    !mode && (
                      <Col span={12}>
                        <Row
                          gutter={6}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Col style={{ paddingTop: "4px" }}>
                            <span>Discount (%)</span>
                          </Col>

                          <Col>
                            <Form.Item
                              className="discountInput inputBoxMb"
                              // name={["amount", "dics"]}
                              name="discount"
                            >
                              <InputNumber
                                placeholder="0.0"
                                controls={false}
                                min={0.0}
                                max={100}
                                // max={
                                //   form.getFieldValue("discountDrop") ===
                                //   DiscountType.PercentageDiscount
                                //     ? 100
                                //     : Infinity
                                // }
                                style={{
                                  width: "100%",
                                  // margin: "0px 30px 0px 10px",
                                  // width: 60,
                                }}
                                onChange={(e: any) => {
                                  // console.log(e);
                                  handleItemsAllDiscount(e);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        {/* <Col style={{ paddingTop: "4px" }}>Discount (%)</Col>
                      <Col>
                        <Form.Item
                          className="discountInput inputBoxMb"
                          // name={["amount", "dics"]}
                          name="discount"
                          style={{ margin: "0px" }}
                        >
                          <InputNumber
                            placeholder="0.0"
                            controls={false}
                            min={0.0}
                            max={100}
                            // max={
                            //   form.getFieldValue("discountDrop") ===
                            //   DiscountType.PercentageDiscount
                            //     ? 100
                            //     : Infinity
                            // }
                            style={{
                              width: "100%",
                              // margin: "0px 30px 0px 10px",
                              // width: 60,
                            }}
                            onChange={(e: any) => {
                              // console.log(e);
                              handleItemsAllDiscount(e);
                            }}
                          />
                        </Form.Item>
                      </Col> */}
                      </Col>
                    )}
                </Row>
                <div>
                  <table
                    // dashedBorderTop
                    className="AddPageTabelDesign"
                    style={{
                      fontSize: windowWidth <= 1024 ? "10.3px" : "",
                      // border: "5px solid black",
                    }}
                  >
                    {mode ? (
                      <thead>
                        {" "}
                        <tr>
                          <th rowSpan={2} style={{ width: "5%" }}>
                            S No.
                          </th>
                          <th
                            rowSpan={2}
                            style={{
                              width: "30%",
                              // maxWidth: "14%",
                              borderLeft: "1px solid rgb(240, 245, 255)",
                            }}
                          >
                            Item(s)
                          </th>
                          <th rowSpan={2} style={{ width: "9%" }}>
                            Price
                          </th>
                          {(companySettings?.setting?.gstDetails?.scheme ===
                            CompanyGSTTypes.Regular ||
                            updateTimeData.isGSTVoucher) && (
                            <th colSpan={2} style={{ width: "15%" }}>
                              GST
                            </th>
                          )}
                          <th
                            colSpan={2}
                            rowSpan={2}
                            style={{
                              width: "14%",
                              // display: "flex",
                              // justifyContent: "space-between",
                            }}
                          >
                            Gross Amount
                          </th>
                        </tr>
                      </thead>
                    ) : (
                      <thead>
                        <tr>
                          <th colSpan={2} rowSpan={2} style={{ width: "5%" }}>
                            S No.
                          </th>
                          <th
                            rowSpan={2}
                            style={{
                              width: "30%",
                              // maxWidth: "14%",
                              borderLeft: "1px solid rgb(240, 245, 255)",
                            }}
                          >
                            Item(s)
                          </th>
                          {/* {selectedInput.includes("description") && (
                        <th
                          rowSpan={2}
                          style={{
                                                       // width: windowWidth <= 1024 ? "17%" : "29%",
                            width: "18%",
                          }}
                        >
                          Description
                        </th>
                      )} */}
                          {/* {selectedInput.includes("hsnCode") && (
                        <th
                          rowSpan={2}
                          style={{
                                                       // width: windowWidth <= 1024 ? "17%" : "29%",
                            width: "7%",
                          }}
                        >
                          HSN Code
                        </th>
                      )} */}
                          <th rowSpan={3} style={{ width: "7%" }}>
                            Qty
                          </th>
                          {/* {selectedInput.includes("unit") && (
                        <th
                          rowSpan={2}
                          style={{
                                                       // width: windowWidth <= 1024 ? "17%" : "29%",
                            width: "7%",
                          }}
                        >
                          Unit
                        </th>
                      )} */}

                          <th rowSpan={2} style={{ width: "9%" }}>
                            Unit Price
                          </th>
                          {(settingControl.discountPolicy ===
                            Discount_Policy.TradenCashDiscount ||
                            settingControl.discountPolicy ===
                              Discount_Policy.OnlyTradeDiscount ||
                            updateTimeData.tradeDiscount) && (
                            <th colSpan={2} style={{ width: "15%" }}>
                              Discount
                            </th>
                          )}
                          {(companySettings?.setting?.gstDetails?.scheme ===
                            CompanyGSTTypes.Regular ||
                            updateTimeData.isGSTVoucher) && (
                            <th colSpan={2} style={{ width: "15%" }}>
                              GST
                            </th>
                          )}
                          <th
                            colSpan={2}
                            rowSpan={2}
                            style={{
                              width: "14%",
                              // display: "flex",
                              // justifyContent: "space-between",
                            }}
                          >
                            <Col span={13}>
                              <span>Gross Amount</span>
                            </Col>
                            {/* <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        > */}

                            {/* <Col
                            span={10}
                            // style={{ display: "flex", textAlign: "end" }}
                          >
                            <Popover
                              content={content}
                              // title="Title"
                              trigger="click"
                            >
                              <PlusCircleTwoTone
                                style={{
                                  fontSize: "17px",
                                  margin: "5px",
                                  // width: "40px",
                                }}
                              />
                            </Popover>
                          </Col> */}
                            {/* </Row> */}
                          </th>
                        </tr>
                        <tr>
                          {(settingControl.discountPolicy ===
                            Discount_Policy.TradenCashDiscount ||
                            settingControl.discountPolicy ===
                              Discount_Policy.OnlyTradeDiscount ||
                            updateTimeData.tradeDiscount) && (
                            <>
                              <th style={{ width: "5%" }}>%</th>
                              <th style={{ width: "5%" }}>₹</th>
                            </>
                          )}
                          {(companySettings?.setting?.gstDetails?.scheme ===
                            CompanyGSTTypes.Regular ||
                            updateTimeData.isGSTVoucher) && (
                            <>
                              <th style={{ width: "6%" }}>%</th>
                              <th style={{ width: "6%" }}>₹</th>
                            </>
                          )}
                        </tr>
                      </thead>
                    )}

                    <tbody>
                      {!mode && (
                        <>
                          <Form.List name="items">
                            {(fields, { add, remove, move }) => {
                              return (
                                <>
                                  {confirmation && (
                                    <ConfirmationModal
                                      open={confirmation}
                                      text={`Do you really want to delete this item "${form.getFieldValue(
                                        ["items", deleteItemList, "name"]
                                      )}" ?`}
                                      // text="Do you really want to delete this row?"
                                      onNo={() => setConfirmation(false)}
                                      onYes={() => {
                                        remove(deleteItemList);
                                        handleDeleteRowButton();
                                        setItemLength(fields?.length - 2);
                                      }}
                                    />
                                  )}

                                  {fields?.map(
                                    ({ key, name, ...restField }, index) => {
                                      return (
                                        <tr key={key}>
                                          <td style={{ verticalAlign: "top" }}>
                                            {fields?.length !== index + 1 && (
                                              <div>{index + 1}.</div>
                                            )}
                                          </td>
                                          <td style={{ verticalAlign: "top" }}>
                                            {fields.length !== index + 1 && (
                                              <Form.Item
                                                name={[name, "isService"]}
                                                className="m-0"
                                                {...restField}
                                              >
                                                {form.getFieldValue([
                                                  "items",
                                                  index,
                                                  "isService",
                                                ]) ? (
                                                  <Tooltip
                                                    title="Service"
                                                    placement="left"
                                                    color="#1d39c4"
                                                  >
                                                    <Avatar
                                                      style={{
                                                        cursor: "pointer",
                                                        verticalAlign: "middle",
                                                        color: "#1d39c4",
                                                        backgroundColor:
                                                          "#f0f5ff",
                                                        borderColor: "#adc6ff",
                                                        fontWeight: "bold",
                                                      }}
                                                      size="small"
                                                    >
                                                      S
                                                    </Avatar>
                                                  </Tooltip>
                                                ) : (
                                                  <Tooltip
                                                    title="Goods"
                                                    placement="left"
                                                    color="#389e0d"
                                                  >
                                                    <Avatar
                                                      style={{
                                                        cursor: "pointer",
                                                        verticalAlign: "middle",
                                                        color: "#389e0d",
                                                        backgroundColor:
                                                          "#f6ffed",
                                                        borderColor: "#b7eb8f",
                                                        fontWeight: "bold",
                                                      }}
                                                      size="small"
                                                    >
                                                      G
                                                    </Avatar>
                                                  </Tooltip>
                                                )}
                                              </Form.Item>
                                            )}

                                            {fields.length !== index + 1 && (
                                              <>
                                                <Form.Item
                                                  name={[name, "isTaxable"]}
                                                  className="m-0"
                                                  {...restField}
                                                >
                                                  {form.getFieldValue([
                                                    "items",
                                                    index,
                                                    "isTaxable",
                                                  ]) ? (
                                                    <Tooltip
                                                      title="Inclusive Tax"
                                                      placement="left"
                                                      color="#08979C"
                                                    >
                                                      <Avatar
                                                        style={{
                                                          cursor: "pointer",
                                                          verticalAlign:
                                                            "middle",
                                                          color: "#08979C",
                                                          backgroundColor:
                                                            "#E6FFFB",
                                                          borderColor:
                                                            "rgb(127 231 235)",
                                                          fontWeight: "bold",
                                                        }}
                                                        size="small"
                                                      >
                                                        IT
                                                      </Avatar>
                                                    </Tooltip>
                                                  ) : (
                                                    <Tooltip
                                                      title="Exclusive Tax"
                                                      placement="left"
                                                      color="#531DAB"
                                                    >
                                                      <Avatar
                                                        style={{
                                                          cursor: "pointer",
                                                          verticalAlign:
                                                            "middle",
                                                          color: "#531DAB",
                                                          backgroundColor:
                                                            "#F9F0FF",
                                                          borderColor:
                                                            "rgb(193 166 239)",
                                                          fontWeight: "bold",
                                                        }}
                                                        size="small"
                                                      >
                                                        ET
                                                      </Avatar>
                                                    </Tooltip>
                                                  )}
                                                </Form.Item>
                                              </>
                                            )}
                                          </td>
                                          {/* <td style={{ verticalAlign: "top" }}>
                                      {fields.length !== index + 1 && (
                                        <>
                                          <Form.Item
                                            name={[name, "isTaxable"]}
                                            className="m-0"
                                            {...restField}
                                          >
                                            {form.getFieldValue([
                                              "items",
                                              index,
                                              "isTaxable",
                                            ]) ? (
                                              <Tooltip
                                                title="Inclusive Tax"
                                                placement="right"
                                                color="#08979C"
                                              >
                                                <Avatar
                                                  style={{
                                                    cursor: "pointer",
                                                    verticalAlign: "middle",
                                                    color: "#08979C",
                                                    backgroundColor: "#E6FFFB",
                                                    borderColor:
                                                      "rgb(127 231 235)",
                                                    fontWeight: "bold",
                                                  }}
                                                  size="small"
                                                >
                                                  IT
                                                </Avatar>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="Exclusive Tax"
                                                placement="right"
                                                color="#531DAB"
                                              >
                                                <Avatar
                                                  style={{
                                                    cursor: "pointer",
                                                    verticalAlign: "middle",
                                                    color: "#531DAB",
                                                    backgroundColor: "#F9F0FF",
                                                    borderColor:
                                                      "rgb(193 166 239)",
                                                    fontWeight: "bold",
                                                  }}
                                                  size="small"
                                                >
                                                  ET
                                                </Avatar>
                                              </Tooltip>
                                            )}
                                          </Form.Item>
                                        </>
                                      )}
                                    </td> */}

                                          <td style={{ verticalAlign: "top" }}>
                                            <Form.Item
                                              name={[name, "name"]}
                                              className="m-0"
                                              {...restField}
                                              // rules={}
                                            >
                                              <Select
                                                bordered={false}
                                                loading={itemDropDownLoading}
                                                variant="filled"
                                                className="variantFilledSelect"
                                                // style={{  }}
                                                // allowClear={
                                                //   fields.length !== index + 1 && true
                                                // }
                                                // clearIcon={<span>{<EyeOutlined />}</span>}
                                                // onClear={() => remove(name)}
                                                // setConfirmation(true);
                                                showSearch
                                                // .ant-select-selector
                                                placeholder="Search for Item"
                                                optionFilterProp="children"
                                                filterOption={false}
                                                popupMatchSelectWidth={false}
                                                disabled={
                                                  salesOrderId ||
                                                  purchaseOrderId ||
                                                  quotationId ||
                                                  purchaseOrderRef
                                                    ? true
                                                    : false
                                                }
                                                listItemHeight={1}
                                                style={{
                                                  backgroundColor: "white",
                                                  minWidth: "100%",
                                                  maxWidth: "240px",
                                                }}
                                                dropdownStyle={{
                                                  width: "250px",
                                                }}
                                                placement="bottomRight"
                                                notFoundContent={
                                                  <Empty
                                                    image={
                                                      Empty.PRESENTED_IMAGE_SIMPLE
                                                    }
                                                    description={
                                                      <span>
                                                        Sales item not found.
                                                      </span>
                                                    }
                                                  />
                                                }
                                                // prefixCls={<EyeOutlined />}
                                                onSearch={(value: string) =>
                                                  setSearchItem(value)
                                                }
                                                onClick={() => {
                                                  setSearchItem("");
                                                  setNewItem({
                                                    data: {},
                                                    isSaved: false,
                                                  });
                                                }}
                                                onChange={(value: any) => {
                                                  if (
                                                    fields.length ===
                                                    index + 1
                                                  )
                                                    add();
                                                  ItemSelect(value, index);
                                                }}
                                                options={itemsOption?.map(
                                                  (itm: any) => ({
                                                    value: itm?.id,
                                                    label: (
                                                      <div>
                                                        <Row
                                                          justify={
                                                            "space-between"
                                                          }
                                                        >
                                                          <Col>
                                                            <p
                                                              style={{
                                                                fontWeight:
                                                                  "bold",
                                                                color:
                                                                  "dimgray",
                                                              }}
                                                            >
                                                              {itm?.name}
                                                            </p>
                                                          </Col>
                                                          <Col>
                                                            <EditOutlined
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                              }}
                                                              onClick={() => {
                                                                setItemId(
                                                                  itm?.id
                                                                );
                                                                setOpenModel({
                                                                  type: "addItem",
                                                                  param: {
                                                                    open: true,
                                                                  },
                                                                });
                                                              }}
                                                            />
                                                          </Col>
                                                        </Row>
                                                        <div>
                                                          {itm?.hsnCode && (
                                                            <span>
                                                              <span
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                  color:
                                                                    "dimgray",
                                                                }}
                                                              >
                                                                HSN Code -
                                                              </span>
                                                              {itm?.hsnCode}
                                                            </span>
                                                          )}
                                                          {itm?.sacCode && (
                                                            <span>
                                                              <span
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                  color:
                                                                    "dimgray",
                                                                }}
                                                              >
                                                                SAC Code -
                                                              </span>
                                                              {itm?.sacCode}
                                                            </span>
                                                          )}
                                                          {itm?.description && (
                                                            <span>
                                                              <span
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                  color:
                                                                    "dimgray",
                                                                }}
                                                              >
                                                                {" "}
                                                                Des. -
                                                              </span>
                                                              {itm?.description}
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    ),
                                                  })
                                                )}
                                                dropdownRender={(
                                                  itemsOption
                                                ) => (
                                                  <>
                                                    {itemsOption}
                                                    <Divider
                                                      style={{
                                                        margin: "5px 0px",
                                                      }}
                                                    />
                                                    <Button
                                                      style={{ width: "100%" }}
                                                      icon={<PlusOutlined />}
                                                      type="link"
                                                      onClick={() => {
                                                        setItemId(null);
                                                        setOpenModel({
                                                          type: "addItem",
                                                          param: {
                                                            open: true,
                                                          },
                                                        });
                                                        setNewItem({
                                                          data: {},
                                                          isSaved: false,
                                                        });
                                                        setItemFields({
                                                          field: fields,
                                                          index: index,
                                                        });
                                                      }}
                                                    >
                                                      New Item
                                                    </Button>
                                                  </>
                                                )}
                                              />
                                            </Form.Item>
                                            {(settingControl.showItemDescription ||
                                              updateTimeData.showItemDescription) && (
                                              <Form.Item
                                                className="m-0"
                                                {...restField}
                                                name={[name, "description"]}
                                              >
                                                <Input
                                                  variant="filled"
                                                  disabled={
                                                    fields.length === index + 1
                                                  }
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                  placeholder="Description"
                                                  bordered={false}
                                                />
                                              </Form.Item>
                                            )}
                                          </td>
                                          {/* {selectedInput.includes("description") && (
                                      <td>
                                        <Form.Item
                                          className="m-0"
                                          {...restField}
                                          name={[name, "description"]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </td>
                                    )} */}
                                          {/* {selectedInput.includes("hsnCode") && (
                                      <td>
                                        <Form.Item
                                          className="m-0"
                                          {...restField}
                                          name={[name, "hsn"]}
                                        >
                                          <Input disabled />
                                        </Form.Item>
                                      </td>
                                    )} */}

                                          {/* Qty */}
                                          <td style={{ verticalAlign: "top" }}>
                                            <Form.Item
                                              className="m-0"
                                              {...restField}
                                              name={[name, "amount", "qty"]}
                                              initialValue={0.0}
                                            >
                                              <InputNumber
                                                variant="filled"
                                                bordered={false}
                                                disabled={
                                                  fields.length === index + 1
                                                }
                                                min={0}
                                                step={0.01}
                                                style={{
                                                  width: "100%",
                                                  backgroundColor:
                                                    fields.length === index + 1
                                                      ? "#f0f8ff"
                                                      : "#FFFFFF",
                                                }}
                                                controls={false}
                                                className="inputNumberTextEnd"
                                                onChange={() =>
                                                  handleItemsNetAmmount(index)
                                                }
                                                // formatter={(value: any) =>
                                                //   Utils.getInputNumberFormat(value)
                                                // }
                                                // onKeyPress={(e) =>
                                                //   handelTwoDigitAfterDecimal(e)
                                                // }
                                                // formatter={(value) =>
                                                //   `${value}`.replace(/^(.*\.\d{2}).*$/, "$1")
                                                // }
                                                // parser={(value: any) =>
                                                //   isNaN(parseFloat(value)) ? '' : parseFloat(value).toFixed(2)
                                                // }
                                              />
                                            </Form.Item>

                                            {selectedInput.includes("unit") && (
                                              <Form.Item
                                                name={[name, "amount", "unit"]}
                                                className="m-0"
                                                {...restField}
                                              >
                                                <Select
                                                  bordered={false}
                                                  // variant="filled"
                                                  disabled
                                                  labelInValue
                                                  showSearch
                                                  popupMatchSelectWidth={false}
                                                  // dropdownStyle={{ width: "250px" }}
                                                  // style={{ width: "140px" }}
                                                  style={{
                                                    minWidth: "100%",
                                                    maxWidth: "240px",
                                                    // backgroundColor: "#FFFFFF",
                                                  }}
                                                  placement="bottomRight"
                                                  // filterOption={(input, option) =>
                                                  //   (option?.label ?? "")
                                                  //     .toLowerCase()
                                                  //     .includes(input.toLowerCase())
                                                  // }
                                                  // options={account}
                                                />
                                              </Form.Item>
                                            )}
                                          </td>

                                          {/* <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "unit"]}
                                >
                                  <Select
                                    popupMatchSelectWidth={false}
                                    placement="bottomRight"
                                  />
                                </Form.Item>
                              </td> */}
                                          {/* Unit Price */}
                                          <td style={{ verticalAlign: "top" }}>
                                            <Form.Item
                                              className="m-0"
                                              {...restField}
                                              name={[name, "amount", "price"]}
                                              initialValue={0.0}
                                            >
                                              <InputNumber
                                                bordered={false}
                                                variant="filled"
                                                disabled={
                                                  salesOrderId ||
                                                  purchaseOrderId ||
                                                  quotationId
                                                    ? true
                                                    : fields.length ===
                                                      index + 1
                                                }
                                                min={0}
                                                step={0.01}
                                                style={{
                                                  width: "100%",
                                                  backgroundColor:
                                                    fields.length === index + 1
                                                      ? "#f0f8ff"
                                                      : "#FFFFFF",
                                                }}
                                                controls={false}
                                                className="inputNumberTextEnd"
                                                // formatter={(value: any) =>
                                                //   Utils.getInputNumberFormat(value)
                                                // }
                                                // onKeyPress={(e) =>
                                                //   handelTwoDigitAfterDecimal(e)
                                                // }
                                                onChange={() =>
                                                  handleItemsNetAmmount(index)
                                                }
                                              />
                                            </Form.Item>
                                          </td>
                                          {(settingControl.discountPolicy ===
                                            Discount_Policy.TradenCashDiscount ||
                                            settingControl.discountPolicy ===
                                              Discount_Policy.OnlyTradeDiscount ||
                                            updateTimeData.tradeDiscount) && (
                                            <>
                                              <td
                                                style={{ verticalAlign: "top" }}
                                              >
                                                <Form.Item
                                                  className="m-0"
                                                  {...restField}
                                                  name={[
                                                    name,
                                                    "amount",
                                                    "discount",
                                                  ]}
                                                >
                                                  <InputNumber
                                                    variant="filled"
                                                    bordered={false}
                                                    disabled={
                                                      fields.length ===
                                                      index + 1
                                                    }
                                                    className="inputNumberTextEnd"
                                                    style={{
                                                      width: "100%",
                                                      backgroundColor:
                                                        fields.length ===
                                                        index + 1
                                                          ? "#f0f8ff"
                                                          : "#FFFFFF",
                                                    }}
                                                    controls={false}
                                                    min={0}
                                                    max={100}
                                                    // disabled
                                                    // formatter={(value: any) =>
                                                    //   Utils.getInputNumberFormat(value)
                                                    // }
                                                    // onKeyPress={(e) =>
                                                    //   handelTwoDigitAfterDecimal(e)
                                                    // }
                                                    onChange={() =>
                                                      handleItemsNetAmmount(
                                                        index
                                                      )
                                                    }
                                                  />
                                                </Form.Item>
                                              </td>
                                              <td
                                                style={{ verticalAlign: "top" }}
                                              >
                                                <Form.Item
                                                  className="m-0"
                                                  {...restField}
                                                  name={[
                                                    name,
                                                    "amount",
                                                    "discountRupee",
                                                  ]}
                                                  initialValue={0}
                                                >
                                                  <InputNumber
                                                    variant="filled"
                                                    bordered={false}
                                                    disabled={
                                                      fields.length ===
                                                      index + 1
                                                    }
                                                    min={0}
                                                    step={0.01}
                                                    style={{
                                                      width: "100%",
                                                      backgroundColor:
                                                        fields.length ===
                                                        index + 1
                                                          ? "#f0f8ff"
                                                          : "#FFFFFF",
                                                    }}
                                                    controls={false}
                                                    // disabled
                                                    className="inputNumberTextEnd"
                                                    max={returnNetAmount(
                                                      index,
                                                      form.getFieldValue([
                                                        "items",
                                                      ])
                                                    )}
                                                    // formatter={(value: any) =>
                                                    //   Utils.getInputNumberFormat(value)
                                                    // }
                                                    // onKeyPress={(e) =>
                                                    //   handelTwoDigitAfterDecimal(e)
                                                    // }F
                                                    onChange={() => {
                                                      onDiscountAmountChange(
                                                        index
                                                      );
                                                    }}
                                                  />
                                                </Form.Item>
                                              </td>
                                            </>
                                          )}
                                          {(companySettings?.setting?.gstDetails
                                            ?.scheme ===
                                            CompanyGSTTypes.Regular ||
                                            updateTimeData.isGSTVoucher) && (
                                            <>
                                              <td
                                                style={{ verticalAlign: "top" }}
                                              >
                                                <Form.Item
                                                  name={[name, "amount", "tax"]}
                                                  className="m-0"
                                                  {...restField}
                                                >
                                                  <Select
                                                    variant="filled"
                                                    bordered={false}
                                                    disabled
                                                    labelInValue
                                                    placeholder="%"
                                                    popupMatchSelectWidth={
                                                      false
                                                    }
                                                    style={
                                                      {
                                                        // backgroundColor: "#FFFFFF",
                                                      }
                                                    }
                                                    placement="bottomRight"
                                                    onChange={() => {
                                                      handleItemsNetAmmount(
                                                        index
                                                      );
                                                    }}
                                                    options={gstOption?.map(
                                                      (gst: any) => ({
                                                        key: gst.value,
                                                        value: gst.id,
                                                        label: gst.name,
                                                      })
                                                    )}
                                                    /* {gstSlabs?.map((option: any) => (
                                        <Select.Option
                                          key={option?.id}
                                          value={option?.rate}
                                          title={option?.name}
                                        >
                                          {option?.rate + `%`}
                                        </Select.Option>
                                      ))} */
                                                  />
                                                </Form.Item>
                                              </td>

                                              <td
                                                style={{ verticalAlign: "top" }}
                                              >
                                                <Tooltip
                                                  title={form.getFieldValue([
                                                    "items",
                                                    index,
                                                    "amount",
                                                    "itemTaxAmt",
                                                  ])}
                                                  placement="top"
                                                >
                                                  <Form.Item
                                                    className="m-0"
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "amount",
                                                      "itemTaxAmt",
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      variant="filled"
                                                      disabled
                                                      // bordered={false}
                                                      step={0.01}
                                                      style={{
                                                        width: "100%",
                                                        backgroundColor:
                                                          "#f0f8ff",
                                                      }}
                                                      // controls={false}
                                                      className="inputNumberTextEnd"
                                                      // formatter={(value: any) =>
                                                      //   Utils.getInputNumberFormat(value)
                                                      // }
                                                      // onKeyPress={(e) =>
                                                      //   handelTwoDigitAfterDecimal(e)
                                                      // }
                                                      // onChange={() => handleItemsNetAmmount(index)}
                                                    />
                                                  </Form.Item>
                                                </Tooltip>
                                              </td>
                                            </>
                                          )}
                                          {/* Total Amount */}
                                          <td style={{ verticalAlign: "top" }}>
                                            <Form.Item
                                              className="m-0"
                                              {...restField}
                                              name={[
                                                name,
                                                "amount",
                                                "totalAmount",
                                              ]}
                                              initialValue={0}
                                            >
                                              <InputNumber
                                                variant="filled"
                                                bordered={false}
                                                disabled={
                                                  fields.length === index + 1
                                                }
                                                // min={0}
                                                step={0.01}
                                                style={{
                                                  width: "100%",
                                                  backgroundColor:
                                                    fields.length === index + 1
                                                      ? "#f0f8ff"
                                                      : "#FFFFFF",
                                                }}
                                                controls={false}
                                                className="inputNumberTextEnd"
                                                // formatter={(value: any) =>
                                                //   Utils.getInputNumberFormat(value)
                                                // }
                                                // onKeyPress={(e) =>
                                                //   handelTwoDigitAfterDecimal(e)
                                                // }
                                                onChange={(value) => {
                                                  onItemsTotalAmountChange(
                                                    index
                                                  );
                                                }}
                                              />
                                            </Form.Item>
                                          </td>
                                          <td
                                            style={{
                                              width: 24,
                                              verticalAlign: "top",
                                              textAlign: "center",
                                            }}
                                          >
                                            {fields.length !== index + 1 && (
                                              <img
                                                className="cursor mx-1"
                                                onClick={() => {
                                                  setDeleteItemList(name);
                                                  setConfirmation(true);
                                                }}
                                                src={deleteImg}
                                                alt="Delete"
                                              />
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </>
                              );
                            }}
                          </Form.List>

                          <tr>
                            <td colSpan={3}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  // alignContent:"space-between"
                                  // verticalAlign: "inherit",
                                }}
                              >
                                <div>
                                  {(settingControl.showOtherCharges ||
                                    updateTimeData.friInsuranceOtherCharge) && (
                                    <Button
                                      icon={<PlusOutlined />}
                                      size="small"
                                      style={{ backgroundColor: "ButtonFace" }}
                                      onClick={() => {
                                        setShowFreightInsurance(true);
                                        setSelectedOtherExpenses([]);
                                        setFooterLedger({
                                          ledgerAmount: 0,
                                          ledgerGstAmount: 0,
                                          ledgerTotalAmount: 0,
                                        });

                                        form.setFieldValue(
                                          ["freightInsLedgers"],
                                          [
                                            {
                                              gstRate: 0,
                                              gstAmount: 0,
                                              totalAmount: 0,
                                              amount: 0,
                                            },
                                          ]
                                        );
                                      }}
                                      disabled={showFreightInsurance}
                                    >
                                      Other Expenses
                                    </Button>
                                  )}
                                </div>

                                <div
                                  style={{
                                    fontWeight: "bold",
                                    padding: "0px 10px",
                                  }}
                                >
                                  Sub Total
                                </div>
                              </div>
                            </td>

                            {/* {selectedInput.includes("description") && <td> </td>} */}
                            {/* {selectedInput.includes("hsnCode") && <td></td>} */}
                            <td
                              colSpan={1}
                              className="textEnd pe-14"
                              style={{ fontWeight: "bold" }}
                            >
                              {Utils.getFormattedNumber(footerTotal.qty)}
                            </td>

                            {/* {selectedInput.includes("unit") && <td></td>} */}
                            <td></td>
                            {(settingControl.discountPolicy ===
                              Discount_Policy.TradenCashDiscount ||
                              settingControl.discountPolicy ===
                                Discount_Policy.OnlyTradeDiscount ||
                              updateTimeData.tradeDiscount) && (
                              <>
                                <td></td>

                                <td
                                  className="textEnd pe-12"
                                  style={{ fontWeight: "bold" }}
                                >
                                  ₹{" "}
                                  {Utils.getFormattedNumber(
                                    footerTotal.discount
                                  )}
                                </td>
                              </>
                            )}
                            <td></td>
                            {(companySettings?.setting?.gstDetails?.scheme ===
                              CompanyGSTTypes.Regular ||
                              updateTimeData.isGSTVoucher) && (
                              <>
                                <td
                                  className="textEnd pe-14"
                                  style={{ fontWeight: "bold" }}
                                >
                                  ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                                </td>
                                <td
                                  className="textEnd pe-14"
                                  style={{ fontWeight: "bold" }}
                                >
                                  ₹{" "}
                                  {Utils.getFormattedNumber(footerTotal.amount)}
                                </td>
                              </>
                            )}
                            <td></td>
                          </tr>
                        </>
                      )}

                      {/* Show Include Other Expenses */}
                      {/* showFreightInsurance */}
                      {showFreightInsurance && (
                        <>
                          <Form.List name="freightInsLedgers">
                            {(fields, { add, remove, move }) => {
                              return (
                                <>
                                  {ledgerconfirmation && (
                                    <ConfirmationModal
                                      open={ledgerconfirmation}
                                      text={
                                        "Do you really want to delete this item"
                                      }
                                      onNo={() => setLedgerConfirmation(false)}
                                      onYes={() => {
                                        setLedgerConfirmation(false);
                                        setLedgerLength(fields.length - 2);
                                        setSelectedOtherExpenses((prev) => {
                                          const newDisabledInputs = [...prev];
                                          newDisabledInputs.splice(
                                            deleteItemList,
                                            1
                                          );
                                          return newDisabledInputs;
                                        });
                                        fields.length === 0 &&
                                          setShowFreightInsurance(false);
                                        remove(deleteLedgerList);
                                        console.log("lengt", fields.length);

                                        handleLedgerNetAmount(0);
                                      }}
                                    />
                                  )}
                                  {/* <tbody> */}
                                  {fields?.map(
                                    ({ key, name, ...restField }, index) => {
                                      return (
                                        <tr key={key}>
                                          <td
                                            style={{
                                              verticalAlign: "top",
                                              textAlign: "center",
                                            }}
                                          >
                                            {index + 1}.
                                          </td>
                                          {!mode && <td></td>}
                                          <td>
                                            <Form.Item
                                              name={[name, "id"]}
                                              className="m-0"
                                              {...restField}
                                            >
                                              <Select
                                                labelInValue
                                                bordered={false}
                                                loading={
                                                  otherExpensiveDropDownLoading
                                                }
                                                variant="filled"
                                                className="variantFilledSelect"
                                                // style={{ width: "338px" }}
                                                placeholder="Search for Other Expenses"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  (
                                                    option?.label?.toString() ??
                                                    ""
                                                  )
                                                    .toLowerCase()
                                                    .includes(
                                                      input.toLowerCase()
                                                    )
                                                }
                                                // onSearch={}
                                                onChange={(e: any) => {
                                                  // console.log(
                                                  //   "form",
                                                  //   form.getFieldValue(
                                                  //     "freightInsLedgers"
                                                  //   )
                                                  // );

                                                  if (
                                                    fields.length ===
                                                    index + 1
                                                  )
                                                    add();
                                                  freightInsLedgersSelect(
                                                    e.value,
                                                    index
                                                  );
                                                }}
                                                options={freightAndInsuranceOptions
                                                  ?.filter(
                                                    (option: any) =>
                                                      !selectedOtherExpenses.includes(
                                                        option.id
                                                      )
                                                  )
                                                  ?.map((value: any) => ({
                                                    value: value?.id,
                                                    label:
                                                      value?.ledgerName.concat(
                                                        value?.aliasName
                                                          ? `${
                                                              " (" +
                                                              value?.aliasName +
                                                              ")"
                                                            }`
                                                          : ""
                                                      ),
                                                  }))}
                                                style={{
                                                  width: "100%",
                                                  minWidth: "100%",
                                                  maxWidth: "240px",
                                                  backgroundColor: "#FFFFFF",
                                                }}
                                                dropdownRender={(
                                                  customerOptions
                                                ) => (
                                                  <>
                                                    {customerOptions}
                                                    <Divider
                                                      style={{
                                                        margin: "5px 0px",
                                                      }}
                                                    />
                                                    <Button
                                                      // style={{ width: "100%" }}
                                                      icon={<PlusOutlined />}
                                                      type="link"
                                                      onClick={() => {
                                                        setOpenModel({
                                                          type: "addLedger",
                                                          param: {
                                                            open: true,
                                                            ledgerByGroupsType:
                                                              splitPathInVchType ===
                                                              Voucher_Type.SalesVoucher
                                                                ? SearchLedgerByGroupsTypes.SellingAndDistributionExpenses
                                                                : mode
                                                                ? SearchLedgerByGroupsTypes.AllExpensesWithDutiesAndTaxes
                                                                : SearchLedgerByGroupsTypes.DirectExpenses,
                                                            showName:
                                                              "Expenses",
                                                          },
                                                        });
                                                        setEditParty({
                                                          id: "",
                                                        });
                                                      }}
                                                    >
                                                      New Ledger
                                                    </Button>
                                                  </>
                                                )}
                                              />
                                            </Form.Item>
                                          </td>
                                          {/* {selectedInput.includes(
                                          "description"
                                        ) && <td></td>}

                                        {selectedInput.includes("hsnCode") && (
                                          <td></td>
                                        )} */}
                                          {!mode && <td></td>}

                                          {/* {selectedInput.includes("unit") && (
                                          <td></td>
                                        )} */}
                                          <td>
                                            <Form.Item
                                              name={[name, "amount"]}
                                              className="m-0"
                                              {...restField}

                                              // initialValue={0.0}
                                            >
                                              <InputNumber
                                                variant="filled"
                                                bordered={false}
                                                disabled={
                                                  fields.length === index + 1
                                                }
                                                placeholder="Amount"
                                                min={0}
                                                step={0.01}
                                                style={{
                                                  width: "100%",
                                                  // width: "125px",
                                                  // marginLeft: "10px",
                                                  backgroundColor:
                                                    fields.length === index + 1
                                                      ? "#f0f8ff"
                                                      : "#FFFFFF",
                                                }}
                                                controls={false}
                                                className="inputNumberTextEnd"
                                                onChange={() => {
                                                  handleLedgerNetAmount(index);
                                                }}
                                              />
                                            </Form.Item>
                                          </td>
                                          {(settingControl.discountPolicy ===
                                            Discount_Policy.TradenCashDiscount ||
                                            settingControl.discountPolicy ===
                                              Discount_Policy.OnlyTradeDiscount ||
                                            updateTimeData.tradeDiscount) &&
                                            !mode && (
                                              <>
                                                <td></td>
                                                <td></td>
                                              </>
                                            )}
                                          {/* <td style={{ width: "10%" }}></td> */}
                                          {(companySettings?.setting?.gstDetails
                                            ?.scheme ===
                                            CompanyGSTTypes.Regular ||
                                            updateTimeData.isGSTVoucher) && (
                                            <>
                                              <td>
                                                <Form.Item
                                                  name={[name, "gstRate"]}
                                                  className="m-0"
                                                  {...restField}
                                                >
                                                  <Select
                                                    // style={
                                                    //   {
                                                    //     // backgroundColor: "#FFFFFF",
                                                    //   }
                                                    // }
                                                    placement="bottomRight"
                                                    labelInValue
                                                    bordered={false}
                                                    disabled={
                                                      fields.length ===
                                                      index + 1
                                                    }
                                                    variant="filled"
                                                    className={
                                                      fields.length ===
                                                      index + 1
                                                        ? "variantFilledSelectDisable"
                                                        : "variantFilledSelect"
                                                    }
                                                    // style={{
                                                    //   width: "100%",
                                                    //   backgroundColor:
                                                    //     fields.length ===
                                                    //     index + 1
                                                    //       ? "#f0f8ff"
                                                    //       : "#FFFFFF",
                                                    // }}
                                                    placeholder={"%"}
                                                    popupMatchSelectWidth={
                                                      false
                                                    }
                                                    filterOption={(
                                                      input,
                                                      option
                                                    ) =>
                                                      (
                                                        option?.label?.toString() ??
                                                        ""
                                                      )
                                                        .toLowerCase()
                                                        .includes(
                                                          input.toLowerCase()
                                                        )
                                                    }
                                                    onChange={() => {
                                                      handleLedgerNetAmount(
                                                        index
                                                      );
                                                    }}
                                                    options={gstOption?.map(
                                                      (gst: any) => ({
                                                        value: gst?.id,
                                                        title: gst?.rate,
                                                        label: gst?.name,
                                                      })
                                                    )}
                                                  />
                                                </Form.Item>
                                              </td>
                                              <td>
                                                <Form.Item
                                                  className="m-0 inputNumberTextEnd"
                                                  name={[name, "gstAmount"]}
                                                  {...restField}
                                                  initialValue={0.0}
                                                >
                                                  <InputNumber
                                                    bordered={false}
                                                    variant="filled"
                                                    controls={false}
                                                    disabled={
                                                      fields.length ===
                                                      index + 1
                                                    }
                                                    step={0.01}
                                                    className="inputNumberTextEnd"
                                                    style={{
                                                      width: "100%",
                                                      backgroundColor:
                                                        fields.length ===
                                                        index + 1
                                                          ? "#f0f8ff"
                                                          : "#FFFFFF",
                                                    }}
                                                    onChange={() => {
                                                      handleLedgerNetAmount(
                                                        index
                                                      );
                                                    }}
                                                  />
                                                </Form.Item>
                                              </td>
                                            </>
                                          )}
                                          <td>
                                            {/* <div
                                            style={{
                                              display: "flex",
                                              alignItems: "baseline",
                                            }}
                                          > */}
                                            {/* <div> */}
                                            <Form.Item
                                              name={[name, "totalAmount"]}
                                              className="m-0 inputNumberTextEnd"
                                              {...restField}
                                              initialValue={0.0}
                                            >
                                              <InputNumber
                                                // disabled
                                                bordered={false}
                                                disabled={
                                                  fields.length === index + 1
                                                }
                                                variant="filled"
                                                style={{
                                                  width: "100%",
                                                  backgroundColor:
                                                    fields.length === index + 1
                                                      ? "#f0f8ff"
                                                      : "#FFFFFF",
                                                }}
                                                className="inputNumberTextEnd"
                                                controls={false}
                                                step={0.01}
                                                onChange={(value) => {
                                                  onLedgerTotalAmountChange(
                                                    index
                                                  );
                                                }}
                                              />
                                            </Form.Item>
                                          </td>
                                          <td>
                                            {/* {fields.length !== index + 1 && ( */}
                                            <img
                                              className="cursor mx-1"
                                              onClick={() => {
                                                setDeleteLedgerList(name);
                                                setLedgerConfirmation(true);
                                              }}
                                              src={deleteImg}
                                              alt="Delete"
                                            />
                                            {/* )} */}
                                          </td>
                                          {/* </div> */}
                                          {/* <td style={{ width: "24px" }}></td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                                  {/* </tbody> */}
                                </>
                              );
                            }}
                          </Form.List>

                          <>
                            {/* <tr>
                            <td colSpan={24}>
                              <Divider className="my-5 grayBg" />
                            </td>
                          </tr> */}

                            {/* <tr>
                              <th colSpan={5} className="textEnd pe-14">
                                Total
                              </th>
                              <th colSpan={2} className="textEnd pe-12">
                                {companySettings?.setting?.gstDetails
                                  ?.scheme === CompanyGSTTypes.Regular && (
                                  <>
                                    ₹{" "}
                                    {Utils.getFormattedNumber(
                                      footerLedger.ledgerAmount
                                    )}
                                  </>
                                )}
                              </th>
                              {companySettings?.setting?.gstDetails?.scheme ===
                                CompanyGSTTypes.Regular && (
                                <th colSpan={2} className="textEnd pe-12">
                                  ₹{" "}
                                  {Utils.getFormattedNumber(
                                    footerLedger.ledgerGstAmount
                                  )}
                                </th>
                              )}
                              <th
                                className="textEnd pe-12"
                                // style={{ paddingRight: "35px" }}
                                colSpan={
                                  companySettings?.setting?.gstDetails
                                    ?.scheme === CompanyGSTTypes.Regular
                                    ? 0
                                    : 1
                                }
                              >
                                ₹{" "}
                                {Utils.getFormattedNumber(
                                  footerLedger.ledgerTotalAmount
                                )}
                              </th>
                            </tr> */}

                            <tr>
                              <td
                                colSpan={mode ? 2 : 3}
                                style={{ fontWeight: "bold", textAlign: "end" }}
                              >
                                Sub Total
                              </td>
                              {/* <td></td> */}
                              {/* <td></td> */}
                              {/* <td></td> */}
                              {/* {selectedInput.includes("description") && (
                                <td> </td>
                              )}
                              {selectedInput.includes("hsnCode") && <td></td>} */}
                              {!mode && (
                                <td colSpan={1} className="textEnd pe-14"></td>
                              )}

                              {/* {selectedInput.includes("unit") && <td></td>} */}
                              <td
                                className="textEnd pe-14"
                                style={{ fontWeight: "bold" }}
                              >
                                ₹{" "}
                                {Utils.getFormattedNumber(
                                  footerLedger.ledgerAmount
                                )}
                              </td>
                              {(settingControl.discountPolicy ===
                                Discount_Policy.TradenCashDiscount ||
                                settingControl.discountPolicy ===
                                  Discount_Policy.OnlyTradeDiscount ||
                                updateTimeData.tradeDiscount) &&
                                !mode && (
                                  <>
                                    <td></td>
                                    <td></td>
                                  </>
                                )}
                              <td></td>
                              {(companySettings?.setting?.gstDetails?.scheme ===
                                CompanyGSTTypes.Regular ||
                                updateTimeData.isGSTVoucher) && (
                                <>
                                  <td
                                    className="textEnd pe-14"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    ₹{" "}
                                    {Utils.getFormattedNumber(
                                      footerLedger.ledgerGstAmount
                                    )}
                                  </td>
                                  <td
                                    className="textEnd pe-14"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    ₹{" "}
                                    {Utils.getFormattedNumber(
                                      footerLedger.ledgerTotalAmount
                                    )}
                                  </td>
                                  <td></td>
                                </>
                              )}
                            </tr>
                          </>
                        </>
                      )}

                      {/* <tr>
                      <td colSpan={24}>
                        <Divider className="my-5 grayBg" />
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>

              {(footerTotal.taxableAmount > 0 ||
                footerLedger.ledgerAmount > 0 ||
                updateId ||
                cloneId ||
                draftId) && (
                <Row gutter={20}>
                  {/*----- Bank Account Details ----- */}

                  {(settingControl?.showReceipt ||
                    editInvBankDetails?.length > 0) && (
                    <Col xl={10} lg={16} md={24} xs={24} className="pt-10">
                      <div style={{ overflowY: "scroll", maxHeight: "200px" }}>
                        <table className="AddPageTabelDesign">
                          <Form.List name="bankDts">
                            {(fields, { add, remove }) => {
                              return (
                                <>
                                  {bankConfirmation && (
                                    <ConfirmationModal
                                      open={true}
                                      text="Do you really want to delete this Row ?"
                                      onNo={() => setBankConfirmation(false)}
                                      onYes={() => {
                                        remove(deleteBankAccountRow);
                                        setBankAccountAmt(
                                          form
                                            .getFieldValue("bankDts")
                                            .reduce(
                                              (acc: number, x: any) =>
                                                acc + x?.amount,
                                              0
                                            )
                                        );
                                        setBankConfirmation(false);
                                      }}
                                    />
                                  )}
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          textAlign: "start",
                                          paddingLeft: "8px",
                                          width: "33%",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Select Account
                                      </th>
                                      <th
                                        style={{
                                          textAlign: "start",
                                          paddingLeft: "8px",
                                          width: "20%",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Balance
                                      </th>
                                      <th
                                        style={{
                                          textAlign: "start",
                                          paddingLeft: "8px",
                                          width: "20%",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Reference
                                      </th>
                                      <th
                                        style={{
                                          textAlign: "end",
                                          paddingRight: "8px",
                                          width: "20%",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Amount
                                      </th>

                                      <th style={{ width: "7%" }}>
                                        <Form.Item className="m-0">
                                          <PlusOutlined onClick={() => add()} />
                                        </Form.Item>
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td>
                                        <Input
                                          disabled
                                          bordered={false}
                                          // variant="filled"
                                          style={{
                                            backgroundColor: "#F0F8FF",
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          disabled
                                          bordered={false}
                                          // variant="filled"
                                          // style={{
                                          //   backgroundColor: "#FFFFFF",
                                          // }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          disabled
                                          bordered={false}
                                          // variant="filled"
                                          style={
                                            {
                                              // backgroundColor: "#FFFFFF",
                                            }
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          bordered={false}
                                          // variant="filled"
                                          style={{
                                            textAlign: "end",
                                            width: "100%",
                                            backgroundColor: "#F0F8FF",
                                            // backgroundColor: "#FFFFFFF",
                                          }}
                                          min={0}
                                          disabled
                                          value={
                                            voucherNature === Voucher_Nature.RCM
                                              ? Utils.getFormattedNumber(
                                                  footerTotal.taxableAmount +
                                                    footerLedger.ledgerAmount +
                                                    roundOffAmount -
                                                    Math.round(
                                                      cashDiscountRs * 100
                                                    ) /
                                                      100 -
                                                    bankAccountAmt -
                                                    footerTotal.discount +
                                                    totalTcs -
                                                    totalTds
                                                )
                                              : Utils.getFormattedNumber(
                                                  Utils.getNumber(
                                                    footerTotal.amount
                                                  ) +
                                                    Utils.getNumber(
                                                      footerLedger.ledgerTotalAmount
                                                    ) +
                                                    roundOffAmount -
                                                    Math.round(
                                                      cashDiscountRs * 100
                                                    ) /
                                                      100 -
                                                    bankAccountAmt +
                                                    totalTcs -
                                                    totalTds
                                                  // -
                                                  // (voucherNature ===
                                                  // Voucher_Nature.RCM
                                                  //   ? footerTotal.gst +
                                                  //     footerLedger.ledgerGstAmount
                                                  //   : 10)
                                                )
                                          }
                                        />
                                      </td>
                                      <td></td>
                                    </tr>
                                    {fields?.map(
                                      ({ key, name, ...restField }, index) => {
                                        return (
                                          <tr key={key}>
                                            <td>
                                              <Form.Item
                                                name={[name, "name"]}
                                                className="m-0"
                                                {...restField}
                                              >
                                                <Select
                                                  bordered={false}
                                                  loading={
                                                    bankAccountDropDownLoading
                                                  }
                                                  variant="filled"
                                                  className="variantFilledSelect"
                                                  disabled={
                                                    location.state?.ledgerId &&
                                                    index === 0
                                                      ? true
                                                      : false
                                                  }
                                                  labelInValue
                                                  showSearch
                                                  placeholder="Search for Bank"
                                                  optionFilterProp="children"
                                                  filterOption={false}
                                                  popupMatchSelectWidth={false}
                                                  style={{
                                                    width: "100%",
                                                    minWidth: "100%",
                                                    maxWidth: "240px",
                                                    backgroundColor: "#FFFFFF",
                                                  }}
                                                  // dropdownStyle={{ width: "250px" }}
                                                  // placement="bottomRight"
                                                  // notFoundContent={
                                                  //   <Empty
                                                  //     image={
                                                  //       Empty.PRESENTED_IMAGE_SIMPLE
                                                  //     }
                                                  //     description={
                                                  //       <span>
                                                  //         Sales item not found.
                                                  //       </span>
                                                  //     }
                                                  //   />
                                                  // }
                                                  // prefixCls={<EyeOutlined />}
                                                  onSearch={(value: string) =>
                                                    setSearchBankAccount(value)
                                                  }
                                                  // onClick={(e) => {
                                                  //   setSearchItem("");
                                                  //   setNewItem({
                                                  //     data: {},
                                                  //     isSaved: false,
                                                  //   });
                                                  // }}
                                                  options={bankAccountList
                                                    ?.filter(
                                                      (option: any) =>
                                                        !selectedBankLedger.includes(
                                                          option.id
                                                        )
                                                    )
                                                    ?.map(
                                                      (
                                                        value: any,
                                                        index: number
                                                      ) => ({
                                                        key: index,
                                                        value: value?.id,
                                                        label:
                                                          value?.ledgerName.concat(
                                                            value?.aliasName
                                                              ? `${
                                                                  " (" +
                                                                  value?.aliasName +
                                                                  ")"
                                                                }`
                                                              : ""
                                                          ),
                                                        title:
                                                          value?.partyOrTax,
                                                      })
                                                    )}
                                                  onChange={(e: any) => {
                                                    // console.log(
                                                    //   "key....................",
                                                    //   key,
                                                    //   name,
                                                    //   index,
                                                    //   e.value,
                                                    //   form.getFieldValue(
                                                    //     "bankDts"
                                                    //   )
                                                    // );

                                                    if (
                                                      fields.length ===
                                                      index + 1
                                                    )
                                                      add();

                                                    let findBalance =
                                                      bankAccountList?.find(
                                                        (x: any) =>
                                                          x.id === e.value
                                                      );

                                                    console.log(
                                                      "Data",
                                                      // findBalance,
                                                      findBalance?.balance
                                                    );

                                                    if (updateId) {
                                                      const editBankAccountFind =
                                                        editInvBankDetails.find(
                                                          (x) =>
                                                            x.name?.value ===
                                                            e.value
                                                        );
                                                      console.log(
                                                        "editBankAccountFind",
                                                        editBankAccountFind,
                                                        editBankAccountFind?.amount ??
                                                          0,
                                                        typeof editBankAccountFind?.amount
                                                      );

                                                      if (editBankAccountFind) {
                                                        // form.setFieldValue(["freightInsLedgers", index], {/
                                                        form.setFieldValue(
                                                          ["bankDts", index],
                                                          {
                                                            ...editBankAccountFind,
                                                            name: {
                                                              label:
                                                                editBankAccountFind
                                                                  .name?.label,
                                                              value:
                                                                editBankAccountFind
                                                                  .name.value,
                                                              title:
                                                                editBankAccountFind
                                                                  .name?.title,
                                                            },
                                                          }
                                                        );

                                                        setBankAccountAmt(
                                                          bankAccountAmt +
                                                            editBankAccountFind?.amount
                                                        );
                                                      }
                                                      setSelectedBankBalance(
                                                        (x: any) => {
                                                          const updatedSelectedOptions =
                                                            [...x];
                                                          updatedSelectedOptions[
                                                            index
                                                          ] =
                                                            findBalance?.balance ??
                                                            0 +
                                                              editBankAccountFind?.amount ??
                                                            0;
                                                          return updatedSelectedOptions;
                                                        }
                                                      );
                                                    } else {
                                                      setSelectedBankBalance(
                                                        (x: any) => {
                                                          const updatedSelectedOptions =
                                                            [...x];
                                                          updatedSelectedOptions[
                                                            index
                                                          ] =
                                                            findBalance?.balance;
                                                          return updatedSelectedOptions;
                                                        }
                                                      );
                                                    }

                                                    setSelectedBankLedger(
                                                      (prevState) => {
                                                        const updatedSelectedOptions =
                                                          [...prevState];
                                                        updatedSelectedOptions[
                                                          index
                                                        ] = e.value;
                                                        return updatedSelectedOptions;
                                                      }
                                                    );

                                                    setSelectedBankPartyOrTax(
                                                      (prevState) => {
                                                        const updatedSelectedOptions =
                                                          [...prevState];
                                                        updatedSelectedOptions[
                                                          index
                                                        ] = e.title;
                                                        return updatedSelectedOptions;
                                                      }
                                                    );
                                                  }}
                                                  // options={freightAndInsuranceOptions
                                                  //   ?.filter(
                                                  //     (option: any) =>
                                                  //       !selectedOtherExpenses.includes(
                                                  //         option.id
                                                  //       )
                                                  //   )
                                                  //   ?.map((value: any) => ({
                                                  //     value: value?.id,
                                                  //     label:
                                                  //       value?.ledgerName.concat(
                                                  //         value?.aliasName
                                                  //           ? `${
                                                  //               " (" +
                                                  //               value?.aliasName +
                                                  //               ")"
                                                  //             }`
                                                  //           : ""
                                                  //       ),
                                                  //   }))}

                                                  dropdownRender={(
                                                    bankAccountList
                                                  ) => (
                                                    <>
                                                      {bankAccountList}
                                                      <Divider
                                                        style={{
                                                          margin: "5px 0px",
                                                        }}
                                                      />
                                                      <Button
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        icon={<PlusOutlined />}
                                                        type="link"
                                                        onClick={() => {
                                                          setOpenModel({
                                                            type: "addBankAccount",
                                                            param: {
                                                              open: true,
                                                              defaultGroup: {
                                                                id: "6603af4b99242bad67d446c5",
                                                                ledgerName:
                                                                  "Bank Account",
                                                                parent:
                                                                  "65af9ce61a48db26effbb598",
                                                              },
                                                            },
                                                          });
                                                          // setNewItem({
                                                          //   data: {},
                                                          //   isSaved: false,
                                                          // });
                                                          // setItemFields({
                                                          //   field: fields,
                                                          //   index: index,
                                                          // });
                                                        }}
                                                      >
                                                        New Bank
                                                      </Button>
                                                    </>
                                                  )}
                                                />
                                              </Form.Item>
                                            </td>

                                            <td>
                                              <Form.Item
                                                className="m-0"
                                                {...restField}
                                              >
                                                <InputNumber
                                                  bordered={false}
                                                  // variant="filled"
                                                  style={{
                                                    width: "100%",
                                                    backgroundColor: "#FFFFFF",
                                                  }}
                                                  disabled
                                                  value={
                                                    selectedBankBalance[index]
                                                  }
                                                />
                                              </Form.Item>
                                            </td>
                                            <td>
                                              <Form.Item
                                                name={[name, "payRecReference"]}
                                                className="m-0"
                                                {...restField}
                                              >
                                                <Input
                                                  bordered={false}
                                                  variant="filled"
                                                  style={{
                                                    width: "100%",
                                                    backgroundColor: "#FFFFFF",
                                                  }}
                                                />
                                              </Form.Item>
                                            </td>

                                            <td>
                                              <Form.Item
                                                name={[name, "amount"]}
                                                className="m-0"
                                                {...restField}
                                                initialValue={0.0}
                                              >
                                                <InputNumber
                                                  bordered={false}
                                                  variant="filled"
                                                  step={0.01}
                                                  min={0}
                                                  // Invoice Bank Amount add & Short term borring account valid negitive balance
                                                  max={
                                                    splitPathInVchType ===
                                                    Voucher_Type.SalesVoucher
                                                      ? Infinity
                                                      : selectedBankPartyOrTax[
                                                          index
                                                        ] ===
                                                        Party_Tax.BanknCash
                                                      ? selectedBankBalance[
                                                          index
                                                        ]
                                                      : Infinity
                                                  }
                                                  disabled={
                                                    location.state?.ledgerId &&
                                                    index === 0
                                                      ? true
                                                      : false
                                                  }
                                                  onBlur={(x) => {
                                                    console.log(x.target.value);
                                                    if (
                                                      Utils.getNumber(
                                                        footerTotal.amount
                                                      ) +
                                                        Utils.getNumber(
                                                          footerLedger.ledgerTotalAmount
                                                        ) -
                                                        form
                                                          .getFieldValue(
                                                            "bankDts"
                                                          )
                                                          .reduce(
                                                            (
                                                              acc: number,
                                                              x: any
                                                            ) =>
                                                              acc + x?.amount,
                                                            0
                                                          ) <
                                                      0
                                                    ) {
                                                      form.setFieldValue(
                                                        [
                                                          "bankDts",
                                                          index,
                                                          "amount",
                                                        ],
                                                        0.0
                                                      );
                                                      setBankAccountAmt(
                                                        form
                                                          .getFieldValue(
                                                            "bankDts"
                                                          )
                                                          .reduce(
                                                            (
                                                              acc: number,
                                                              x: any
                                                            ) =>
                                                              acc + x?.amount,
                                                            0
                                                          )
                                                      );
                                                    } else
                                                      setBankAccountAmt(
                                                        form
                                                          .getFieldValue(
                                                            "bankDts"
                                                          )
                                                          .reduce(
                                                            (
                                                              acc: number,
                                                              x: any
                                                            ) =>
                                                              acc + x?.amount,
                                                            0
                                                          )
                                                      );
                                                  }}
                                                  // min={1}
                                                  className="inputNumberTextEnd"
                                                  controls={false}
                                                  style={{
                                                    width: "100%",
                                                    backgroundColor: "#FFFFFF",
                                                  }}
                                                />
                                              </Form.Item>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {fields.length !== index + 1 && (
                                                <img
                                                  className="cursor mx-1"
                                                  onClick={() => {
                                                    if (
                                                      location.state
                                                        ?.ledgerId &&
                                                      index !== 0
                                                    )
                                                      remove(name);
                                                    else if (
                                                      !location.state?.ledgerId
                                                    )
                                                      remove(name);

                                                    setSelectedBankLedger(
                                                      (prev) => {
                                                        const newDisabledInputs =
                                                          [...prev];
                                                        newDisabledInputs.splice(
                                                          name,
                                                          1
                                                        );
                                                        return newDisabledInputs;
                                                      }
                                                    );

                                                    setSelectedBankPartyOrTax(
                                                      (prev) => {
                                                        const newDisabledInputs =
                                                          [...prev];
                                                        newDisabledInputs.splice(
                                                          name,
                                                          1
                                                        );
                                                        return newDisabledInputs;
                                                      }
                                                    );

                                                    setSelectedBankBalance(
                                                      (x: any) => {
                                                        const allBankAmount = [
                                                          ...x,
                                                        ];
                                                        allBankAmount.splice(
                                                          name,
                                                          1
                                                        );
                                                        return allBankAmount;
                                                      }
                                                    );

                                                    setBankAccountAmt(() =>
                                                      form
                                                        .getFieldValue(
                                                          "bankDts"
                                                        )
                                                        .reduce(
                                                          (
                                                            acc: number,
                                                            x: any
                                                          ) => acc + x?.amount,
                                                          0
                                                        )
                                                    );

                                                    // setDeleteBankAccountRow(name);
                                                    // setBankConfirmation(true);
                                                  }}
                                                  src={deleteImg}
                                                  alt="Delete"
                                                />
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </>
                              );
                            }}
                          </Form.List>
                        </table>
                      </div>
                      {/* //commented for temporary basis  */}
                      <div style={{ marginTop: "10px" }}>
                        <Button
                          style={{ backgroundColor: "ButtonFace" }}
                          onClick={() => {
                            setOpenModel({
                              type: "termsAndConditionsModal",
                              param: { open: true },
                            });
                          }}
                        >
                          View and edit Terms and Conditions
                        </Button>
                      </div>

                      <div>
                        <Form.Item
                          name="attachments"
                          valuePropName="fileList"
                          getValueFromEvent={(e) => {
                            return e.fileList;
                          }}
                          // className="m-0"
                          style={{ paddingTop: "10px", margin: "0px" }}
                        >
                          <Upload
                            multiple
                            maxCount={5}
                            accept=".doc,.docx,.pdf,.jpg,.jpeg,.png,.txt,.csv,.gif,.xls,.xlsx"
                            beforeUpload={(file) => {
                              // const attachments =
                              //   form.getFieldValue("attachments");
                              // console.log("preview", attachments);
                              // const maxCount = 5;
                              if (file.size > 5242880) {
                                openNotification(
                                  "error",
                                  `"${file?.name}" size must be smaller than 5MB!`
                                );
                                return Upload.LIST_IGNORE;
                              } else return false;
                            }}
                            onRemove={(e: any) => {
                              if (updateId && e?.id) {
                                setDeleteUploadId([...deleteUploadId, e?.id]);
                              }
                            }}
                            onPreview={(e: any) => {
                              console.log("preview", e);
                              if (updateId && e?.path) {
                                setPreviewImage({
                                  isBaseConvert: false,
                                  path: e,
                                });
                              } else if (e.type?.split("/")[0] === "image") {
                                setPreviewImage({
                                  isBaseConvert: true,
                                  path: e,
                                });
                              }
                            }}
                          >
                            <Button
                              icon={<UploadOutlined />}
                              style={{ backgroundColor: "ButtonFace" }}
                              disabled={
                                form.getFieldValue("attachments")?.length > 5
                                  ? true
                                  : false
                              }
                            >
                              Upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>
                    </Col>
                  )}

                  <Col
                    style={{ marginTop: "9px" }}
                    xl={6}
                    lg={8}
                    md={24}
                    xs={24}
                    className="pt-10"
                  >
                    <Form.Item name="narration" className="m-0">
                      <TextArea placeholder="Write Narration" rows={4} />
                    </Form.Item>

                    {(!settingControl?.showReceipt ||
                      editInvBankDetails?.length < 0) && (
                      <>
                        {/* //commented for temporary basis  */}
                        <div style={{ marginTop: "10px" }}>
                          <Button
                            style={{ backgroundColor: "ButtonFace" }}
                            onClick={() => {
                              setOpenModel({
                                type: "termsAndConditionsModal",
                                param: { open: true },
                              });
                            }}
                          >
                            View and edit Terms and Conditions
                          </Button>
                        </div>
                        <Form.Item
                          name="attachments"
                          valuePropName="fileList"
                          getValueFromEvent={(e) => {
                            return e.fileList;
                          }}
                          // className="m-0"
                          style={{ paddingTop: "10px", margin: "0px" }}
                        >
                          <Upload
                            multiple
                            // accept={".png, .jpg, .jpeg, .pdf"}
                            // accept={".png, .jpg, .jpeg"}
                            beforeUpload={() => {
                              return false;
                            }}
                            onRemove={(e: any) => {
                              if (updateId && e?.id) {
                                setDeleteUploadId([...deleteUploadId, e?.id]);
                              }
                            }}
                            onPreview={(e: any) => {
                              console.log("preview", e);
                              if (updateId && e?.path) {
                                setPreviewImage({
                                  isBaseConvert: false,
                                  path: e,
                                });
                              } else if (e.type?.split("/")[0] === "image") {
                                setPreviewImage({
                                  isBaseConvert: true,
                                  path: e,
                                });
                              }
                            }}
                          >
                            <Button
                              icon={<UploadOutlined />}
                              style={{ backgroundColor: "ButtonFace" }}
                            >
                              Upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </>
                    )}
                  </Col>

                  {updateId ? (
                    editInvBankDetails?.length < 0 ? (
                      <Col xl={10} xs={0}></Col>
                    ) : editInvBankDetails?.length < 0 &&
                      !settingControl?.showReceipt ? (
                      <Col xl={10} xs={0}></Col>
                    ) : editInvBankDetails?.length === 0 &&
                      !settingControl?.showReceipt ? (
                      <Col xl={10} xs={0}></Col>
                    ) : (
                      <></>
                    )
                  ) : (
                    !settingControl?.showReceipt && <Col xl={10} xs={0}></Col>
                  )}

                  <Col
                    xl={8}
                    lg={
                      settingControl?.showReceipt ||
                      editInvBankDetails?.length > 0
                        ? 24
                        : 16
                    }
                    md={24}
                    xs={24}
                    // style={{ paddingRight: 30 }}
                    className="pt-10"
                  >
                    {/* <Col lg={17} md={24}>
                    <div style={{ overflowY: "auto" }}></div>
                  </Col> */}

                    <Row gutter={[0, 8]} style={{ marginTop: "5px" }}>
                      {!mode && (
                        <>
                          <Col lg={9} span={9}>
                            <span>
                              {splitPathInVchType === Voucher_Type.SalesVoucher
                                ? "Selling Price"
                                : "Purchase price"}
                            </span>
                          </Col>
                          <Col lg={15} span={15} className="textEnd">
                            <span>
                              ₹{" "}
                              {Utils.getFormattedNumber(
                                footerTotal.taxableAmount
                              )}
                            </span>
                          </Col>
                        </>
                      )}
                      {showFreightInsurance && (
                        <>
                          <Col lg={9} span={9}>
                            <span>
                              {mode ? "Total Gross Amount" : "Other Expenses"}
                            </span>
                          </Col>
                          <Col lg={15} span={15} className="textEnd">
                            <span>
                              ₹{" "}
                              {Utils.getFormattedNumber(
                                footerLedger.ledgerAmount
                              )}
                            </span>
                          </Col>
                        </>
                      )}
                      {footerTotal.discount > 0 && (
                        <>
                          <Col lg={9} span={9}>
                            <span>Trade Discount</span>
                          </Col>
                          <Col lg={15} span={15} className="textEnd">
                            <span>
                              - ₹{" "}
                              {Utils.getFormattedNumber(footerTotal.discount)}
                            </span>
                          </Col>
                        </>
                      )}
                      {(companySettings?.setting?.gstDetails?.scheme ===
                        CompanyGSTTypes.Regular ||
                        updateTimeData.isGSTVoucher) && (
                        <>
                          {isStateSame ? (
                            <>
                              <Col lg={9} span={9}>
                                <span>CGST</span>
                              </Col>
                              <Col lg={15} span={15} className="textEnd">
                                <span>
                                  ₹{" "}
                                  {Utils.getFormattedNumber(
                                    (footerTotal.gst +
                                      footerLedger.ledgerGstAmount) /
                                      2
                                  )}
                                </span>
                              </Col>
                              <Col lg={9} span={9}>
                                <span>SGST</span>
                              </Col>
                              <Col lg={15} span={15} className="textEnd">
                                <span>
                                  ₹{" "}
                                  {Utils.getFormattedNumber(
                                    (footerTotal.gst +
                                      footerLedger.ledgerGstAmount) /
                                      2
                                  )}
                                </span>
                              </Col>
                            </>
                          ) : currentCustomerState ? (
                            <>
                              <Col lg={9} span={9}>
                                <span>IGST</span>
                              </Col>
                              <Col lg={15} span={15} className="textEnd">
                                <span>
                                  ₹{" "}
                                  {Utils.getFormattedNumber(
                                    footerTotal.gst +
                                      footerLedger.ledgerGstAmount
                                  )}
                                </span>
                              </Col>
                            </>
                          ) : (
                            <></>
                          )}

                          {(isStateSame || currentCustomerState) &&
                            voucherNature === Voucher_Nature.RCM && (
                              <>
                                <Col lg={12} span={11}>
                                  <span>Tax Under Reverse Charge</span>
                                </Col>
                                <Col lg={12} span={13} className="textEnd">
                                  <span>
                                    ₹{" "}
                                    {Utils.getFormattedNumber(
                                      footerTotal.gst +
                                        footerLedger.ledgerGstAmount
                                    )}
                                  </span>
                                </Col>
                              </>
                            )}
                        </>
                      )}
                      <Divider className="grayBg" style={{ margin: "0px" }} />
                      <>
                        {/* <Divider className="my-0 grayBg" /> */}
                        <Col lg={9} span={9}>
                          <span>Gross Total</span>
                        </Col>
                        <Col lg={15} span={15} className="textEnd">
                          <span>
                            ₹{" "}
                            {Utils.getFormattedNumber(
                              footerTotal.taxableAmount +
                                footerLedger.ledgerAmount +
                                footerTotal.gst +
                                footerLedger.ledgerGstAmount -
                                footerTotal.discount
                            )}
                          </span>
                        </Col>
                      </>
                      {(settingControl.discountPolicy ===
                        Discount_Policy.OnlyCashDiscount ||
                        settingControl.discountPolicy ===
                          Discount_Policy.TradenCashDiscount) && (
                        <>
                          <Col lg={6} span={6}>
                            <span>Cash Discount</span>
                          </Col>

                          <Col lg={3} span={3}>
                            <Form.Item
                              // style={{flex:'end'}}
                              name="cashDiscountDrop"
                              className="inputBoxMb"
                            >
                              <Select
                                size="small"
                                options={[
                                  {
                                    value: DiscountType.PercentageDiscount,
                                    label: "%",
                                  },
                                  {
                                    value: DiscountType.RupeeDiscount,
                                    label: "₹",
                                  },
                                ]}
                                onChange={() => hangleCashDisCount()}
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={6} span={6}>
                            <Form.Item
                              className="discountInput inputBoxMb textEnd"
                              name="cashDiscount"
                            >
                              <InputNumber
                                step={0.01}
                                controls={false}
                                className="inputNumberTextEnd"
                                size="small"
                                placeholder="0.0"
                                min={0.0}
                                max={
                                  form.getFieldValue("cashDiscountDrop") ===
                                  DiscountType.PercentageDiscount
                                    ? 100
                                    : Utils.getNumber(
                                        footerTotal.amount +
                                          footerLedger.ledgerTotalAmount
                                      )
                                }
                                style={{
                                  width: "100%",
                                  // borderBottom: "1px solid black"
                                  // margin: "0px 30px 0px 10px",
                                }}
                                onChange={() => hangleCashDisCount()}
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={9} span={9} className="textEnd">
                            <span>
                              - ₹ {Utils.getFormattedNumber(cashDiscountRs)}
                            </span>
                          </Col>

                          {cashDiscountRs > 0 && (
                            <>
                              <Divider
                                className="grayBg"
                                style={{ margin: "0px" }}
                              />
                              <Col lg={6} span={6}>
                                <span>Sub Total</span>
                              </Col>

                              <Col lg={18} span={18} className="textEnd">
                                <span>
                                  ₹{" "}
                                  {voucherNature === Voucher_Nature.RCM
                                    ? Utils.getFormattedNumber(
                                        footerTotal.taxableAmount +
                                          footerLedger.ledgerAmount -
                                          cashDiscountRs -
                                          footerTotal.discount
                                      )
                                    : Utils.getFormattedNumber(
                                        footerTotal.amount +
                                          footerLedger.ledgerTotalAmount -
                                          cashDiscountRs
                                      )}
                                </span>
                              </Col>
                            </>
                          )}
                        </>
                      )}
                      {/* Update Time Party TCS & TDS Check & Amount, Add time normal condition check  */}
                      {/* 
                      // (updateId || cloneId || draftId
                      //   ? splitPathInVchType === Voucher_Type.SalesVoucher
                      //     ? isPartTDSOrTDS.tcs
                      //     : isPartTDSOrTDS.tds && (totalTds > 0 || totalTcs > 0)
                      //   : splitPathInVchType === Voucher_Type.SalesVoucher
                      //   ? isPartTDSOrTDS.tcs && settingControl.showTcsTds
                      //   : (isPartTDSOrTDS.tds && settingControl.showTcsTds) ||
                      //     totalTds > 0 ||
                      //     totalTcs > 0) 
                      */}
                      {/* splitPathInVchType === Voucher_Type.SalesVoucher ?
                    isPartTDSOrTDS.tcs && settingControl.showTcsTds :
                    isPartTDSOrTDS.tds && settingControl.showTcsTds */}

                      {(splitPathInVchType === Voucher_Type.PurchaseVoucher
                        ? isPartTDSOrTDS.tds && settingControl.showTcsTds
                        : isPartTDSOrTDS.tcs && settingControl.showTcsTds) && (
                        <>
                          <Col lg={17} span={15}>
                            <Row>
                              <Col lg={12} span={12}>
                                <Radio.Group
                                  // disabled
                                  onChange={(e) => {
                                    console.log("e", e);

                                    setTotalTcs(0);
                                    setTotalTds(0);
                                    form.setFieldValue("tcsDetail", {
                                      value: "",
                                      label: "",
                                      title: "",
                                    });
                                    form.setFieldValue("tdsDetail", {
                                      value: "",
                                      label: "",
                                      title: "",
                                    });
                                    setTdsOrTcs(e.target.value);
                                    handleAllTheTotalValues(e.target.value);
                                  }}
                                  value={tdsOrTcs}
                                >
                                  {splitPathInVchType ===
                                  Voucher_Type.PurchaseVoucher ? (
                                    <Radio value={0}>TDS</Radio>
                                  ) : (
                                    <Radio value={1}>TCS</Radio>
                                  )}
                                </Radio.Group>
                              </Col>
                              <Col lg={12} span={12}>
                                {tdsOrTcs === 1 ? (
                                  <Row gutter={5}>
                                    <Col lg={20} span={20}>
                                      <Form.Item
                                        name="tcsDetail"
                                        className="m-0"
                                      >
                                        <Select
                                          labelInValue
                                          placement="bottomRight"
                                          popupMatchSelectWidth={false}
                                          // disabled
                                          allowClear
                                          size="small"
                                          onChange={(e) => {
                                            const tcsSelect = tcsList?.find(
                                              (x) => x?.id === e?.value
                                            );
                                            // console.log("any", tcsSelect);
                                            form.setFieldValue("tcsDetail", {
                                              value: e?.value,
                                              label: tcsSelect?.section,
                                              title: tcsSelect?.rate,
                                            });
                                            handleAllTheTotalValues();
                                          }}
                                          options={tcsList?.map(
                                            (value: any) => ({
                                              value: value?.id,
                                              label: (
                                                <div>
                                                  <Col>
                                                    <p
                                                      style={{
                                                        fontWeight: "bold",
                                                        color: "dimgray",
                                                      }}
                                                    >
                                                      {value?.section +
                                                        "@" +
                                                        value?.rate +
                                                        "%"}
                                                    </p>
                                                  </Col>

                                                  <div>
                                                    <span>
                                                      {/* <span
                                                  style={{
                                                    fontWeight: "500",
                                                    color: "dimgray",
                                                  }}
                                                >
                                                  HSN Code -
                                                </span> */}
                                                      {
                                                        value?.natureOfCollection
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              ),
                                              title: value?.rate,
                                            })
                                          )}
                                          filterOption={(input, option) =>
                                            (option?.label?.toString() ?? "")
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          }
                                          // dropdownRender={(menu) => (
                                          //   <div>
                                          //     {menu}
                                          //     <Divider
                                          //       style={{
                                          //         margin: "5px 0px",
                                          //       }}
                                          //     />
                                          //     <Button
                                          //       style={{ width: "100%" }}
                                          //       icon={<PlusOutlined />}
                                          //       type="link"
                                          //       onClick={() => {
                                          //         setEditParty({ id: "" });
                                          //         setOpenModel({
                                          //           type: "addTcsRate",
                                          //           param: {
                                          //             open: true,
                                          //           },
                                          //         });
                                          //       }}
                                          //     >
                                          //       TCS Rate
                                          //     </Button>
                                          //   </div>
                                          // )}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col
                                      lg={4}
                                      span={4}
                                      style={{ paddingTop: "2.5px" }}
                                    >
                                      {tcsTdsPercentage}%
                                    </Col>
                                  </Row>
                                ) : tdsOrTcs === 0 ? (
                                  <Row gutter={5}>
                                    <Col lg={20} span={20}>
                                      <Form.Item
                                        name="tdsDetail"
                                        className="m-0"
                                      >
                                        <Select
                                          labelInValue
                                          optionFilterProp="children"
                                          // disabled
                                          placement="bottomRight"
                                          popupMatchSelectWidth={false}
                                          size="small"
                                          allowClear
                                          onChange={(e) => {
                                            const tdsSelect = tdsList.find(
                                              (x) => x?.id === e?.value
                                            );
                                            // console.log("any", tdsSelect, e);
                                            form.setFieldValue("tdsDetail", {
                                              value: e?.value,
                                              label: tdsSelect?.section,
                                              title: tdsSelect?.rateDetail,
                                            });
                                            setTdsPostDetail({
                                              formNo: tdsSelect?.form,
                                              transactionType:
                                                tdsSelect?.transactionType,
                                            });
                                            handleAllTheTotalValues();
                                          }}
                                          options={tdsList?.map(
                                            (value: any) => ({
                                              value: value?.id,
                                              label: (
                                                <div>
                                                  <Col>
                                                    <p
                                                      style={{
                                                        fontWeight: "bold",
                                                        color: "dimgray",
                                                      }}
                                                    >
                                                      {value?.section}
                                                    </p>
                                                  </Col>

                                                  <div>
                                                    <span>
                                                      {value?.natureOfPayment}
                                                    </span>
                                                  </div>
                                                </div>
                                              ),
                                              title:
                                                value?.TransactionType ===
                                                Transaction_Type.Domestic
                                                  ? value?.domesticSegment
                                                  : value?.internationalSegment,
                                            })
                                          )}
                                          filterOption={(input, option) =>
                                            (option?.label?.toString() ?? "")
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          }
                                          dropdownRender={(menu) => (
                                            <>
                                              <div>
                                                {menu}
                                                <Divider
                                                  style={{
                                                    margin: "5px 0px",
                                                  }}
                                                />
                                                <Button
                                                  style={{ width: "100%" }}
                                                  icon={<PlusOutlined />}
                                                  type="link"
                                                  onClick={() => {
                                                    setEditParty({
                                                      id: "",
                                                    });
                                                    setOpenModel({
                                                      type: "addTdsRate",
                                                      param: {
                                                        open: true,
                                                      },
                                                    });
                                                  }}
                                                >
                                                  Rate
                                                </Button>
                                              </div>
                                            </>
                                          )}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col
                                      lg={4}
                                      span={4}
                                      style={{ paddingTop: "2.5px" }}
                                    >
                                      {tcsTdsPercentage}%
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>

                          {tdsOrTcs === 1 ? (
                            <Col lg={7} span={9} className="textEnd">
                              <span>
                                ₹ {Utils.getFormattedNumber(totalTcs)}
                              </span>
                            </Col>
                          ) : tdsOrTcs === 0 ? (
                            <Col lg={7} span={9} className="textEnd">
                              <span>
                                ₹ {Utils.getFormattedNumber(totalTds)}
                              </span>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {(settingControl.autoRoundOff ||
                        updateTimeData.roundOff) && (
                        <>
                          <Col lg={5} span={5}>
                            <span>Round off</span>
                          </Col>

                          <Col lg={4} span={4}>
                            <Form.Item
                              valuePropName="checked"
                              name="roundoffCheckbox"
                              className="inputBoxMb"
                            >
                              <Checkbox
                                onChange={() => {
                                  const roundOffAmount = handleRoundoff(
                                    voucherNature === Voucher_Nature.RCM
                                      ? footerTotal.taxableAmount +
                                          footerLedger.ledgerAmount -
                                          footerTotal.discount -
                                          Math.round(cashDiscountRs * 100) / 100
                                      : footerTotal.amount +
                                          footerLedger.ledgerTotalAmount -
                                          Math.round(cashDiscountRs * 100) /
                                            100,
                                    form.getFieldValue("roundoffCheckbox"),
                                    settingControl.roundOffType
                                  );

                                  form.setFieldValue(
                                    "roundOff",
                                    roundOffAmount
                                  );
                                  setRoundoffAmount(roundOffAmount);
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            lg={15}
                            span={15}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Form.Item
                              className="discountInput inputBoxMb textEnd"
                              name="roundOff"
                            >
                              <InputNumber
                                step={0.01}
                                className="inputNumberTextEnd"
                                size="small"
                                controls={false}
                                placeholder="0.0"
                                // bordered={false}
                                // controls={false}
                                style={{
                                  width: "100%",
                                }}
                                onChange={(e: any) => {
                                  setRoundoffAmount(e);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                      {(totalTcs > 0 || totalTds > 0) && (
                        <>
                          <Col lg={6} span={6}>
                            <span>Sub Total</span>
                          </Col>

                          <Col lg={18} span={18} className="textEnd">
                            <span>
                              ₹{" "}
                              {voucherNature === Voucher_Nature.RCM
                                ? Utils.getFormattedNumber(
                                    footerTotal.taxableAmount +
                                      footerLedger.ledgerAmount -
                                      cashDiscountRs -
                                      footerTotal.discount +
                                      totalTcs -
                                      totalTds +
                                      roundOffAmount
                                  )
                                : Utils.getFormattedNumber(
                                    footerTotal.amount +
                                      footerLedger.ledgerTotalAmount -
                                      cashDiscountRs +
                                      totalTcs -
                                      totalTds +
                                      roundOffAmount
                                  )}
                            </span>
                            {/* {Utils.getFormattedNumber(cashDiscount)} */}
                          </Col>
                        </>
                      )}
                      {bankAccountAmt > 0 && (
                        <>
                          <Divider
                            className="grayBg"
                            style={{ margin: "0px" }}
                          />
                          <Col lg={11} span={11}>
                            <span>
                              {splitPathInVchType === Voucher_Type.SalesVoucher
                                ? "Received"
                                : "Paid"}
                            </span>
                          </Col>
                          <Col lg={13} span={13} className="textEnd">
                            <span>
                              ₹ {Utils.getFormattedNumber(bankAccountAmt)}
                            </span>
                            {/* <Form.Item className="m-0" name="receivedAmount1">
                             <InputNumber
                             disabled
                             style={{ width: "100%" }}
                             size="small"
                             controls={false}
                             className="inputNumberTextEnd "
                             // onChange={(e) => onAmountReceived()}
                             /> 
                          </Form.Item> */}
                          </Col>

                          <Col lg={9} span={9}>
                            <span>Balance</span>
                          </Col>
                          <Col lg={15} span={15} className="textEnd">
                            <span>
                              ₹{" "}
                              {Utils.getFormattedNumber(
                                Utils.getNumber(footerTotal.amount) +
                                  Utils.getNumber(
                                    footerLedger.ledgerTotalAmount
                                  ) -
                                  bankAccountAmt +
                                  totalTcs -
                                  totalTds +
                                  roundOffAmount -
                                  cashDiscountRs
                                // balance
                              )}
                            </span>
                          </Col>
                          {/* <Divider className="grayBg" style={{ margin: "0px" }} /> */}
                        </>
                      )}
                    </Row>

                    <Divider className="my-5 grayBg" />

                    <Row gutter={[12, 2]}>
                      <>
                        {/* <Row
                        gutter={8}
                        style={{ display: "flex", justifyContent: "end" }}
                      > */}

                        {/* </Row>                       */}
                      </>
                      <Col
                        lg={11}
                        span={11}
                        style={{ fontWeight: 600, fontSize: "16px" }}
                      >
                        <span>
                          {splitPathInVchType === Voucher_Type.SalesVoucher
                            ? "Total Invoice Value"
                            : "Bill Amount"}
                        </span>
                      </Col>
                      {/* <Col lg={8}  className=" fw-500">
                GBP
              </Col> */}
                      <Col
                        lg={13}
                        span={13}
                        className="textEnd"
                        style={{ fontWeight: 600, fontSize: "16px" }}
                      >
                        <span>
                          ₹{" "}
                          {/* {Utils.getFormattedNumber(
                          voucherNature === Voucher_Nature.RCM
                            ? footerTotal.taxableAmount +
                                footerLedger.ledgerAmount +
                                totalTcs -
                                totalTds +
                                roundOffAmount -
                                footerTotal.discount -
                                Math.round(cashDiscountRs * 100) / 100
                            : Utils.getNumber(footerTotal.amount) +
                                Utils.getNumber(
                                  footerLedger.ledgerTotalAmount
                                ) +
                                totalTcs -
                                totalTds +
                                roundOffAmount -
                                Math.round(cashDiscountRs * 100) / 100
                        )} */}
                          {Utils.getFormattedNumber(
                            voucherNature === Voucher_Nature.RCM
                              ? footerTotal.taxableAmount +
                                  footerLedger.ledgerAmount +
                                  totalTcs +
                                  roundOffAmount -
                                  footerTotal.discount -
                                  Math.round(cashDiscountRs * 100) / 100
                              : Utils.getNumber(footerTotal.amount) +
                                  Utils.getNumber(
                                    footerLedger.ledgerTotalAmount
                                  ) +
                                  totalTcs +
                                  roundOffAmount -
                                  Math.round(cashDiscountRs * 100) / 100
                          )}
                        </span>
                      </Col>
                    </Row>
                    {/* <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              /> */}
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </div>
      </Card>

      <Footer
        style={{
          // backgroundColor:"#f7f8fa",
          backgroundColor: "white",
          // border:"1px solid lightgray",
          position: "fixed",
          bottom: 0,
          height: "55px",
          marginLeft: "-14px",
          zIndex: 1000,
          width: "100%",
          borderTop: "1px solid rgb(228, 232, 240)",
        }}
      >
        <Row style={{ marginTop: "10px" }}>
          <Col lg={16}></Col>
          <Col lg={8}>
            <Row gutter={10}>
              <Col>
                <Button
                  onClick={() => {
                    if (
                      Utils.getNumber(footerTotal.amount) +
                        Utils.getNumber(footerLedger.ledgerTotalAmount) +
                        roundOffAmount -
                        Math.round(cashDiscountRs * 100) / 100 >
                        0 &&
                      !updateId &&
                      selectedParty &&
                      form.getFieldValue("effectiveLedger")
                    ) {
                      saveDrift();
                    }
                    setIsSaveDraft(false);
                    sessionStorage.removeItem(`${currentClient?.id} Sales`);
                    sessionStorage.removeItem("NotificationState");
                    if (location.state?.ledgerId) {
                      navigate(
                        `/accounts/clients/${currentClient?.id}/banking/bankTransactionsDetails/${location.state?.bankEntriesId}`,
                        {
                          state: {
                            ledgerId: location.state?.ledgerId,
                            bankImportIndex: null,
                            voucherRef: null,
                          },
                        }
                      );
                    } else {
                      navigate(-1);
                    }
                    // quotationId
                    //   ? navigate(
                    //       `/accounts/clients/${currentClient?.id}/sales/estimate`
                    //     )
                    //   : salesOrderId
                    //   ? navigate(
                    //       `/accounts/clients/${currentClient?.id}/sales/order`
                    //     )
                    //   : purchaseOrderId
                    //   ? navigate(
                    //       `/accounts/clients/${currentClient?.id}/purchase/order`
                    //     )
                    //   : partyId
                    //   ? navigate(
                    //       `/accounts/clients/${currentClient?.id}/parties/${editParty?.id}`
                    //     )
                    //   : splitPathInVchType === Voucher_Type.SalesVoucher
                    //   ? navigate(
                    //       `/accounts/clients/${currentClient?.id}/sales/invoices`
                    //     )
                    //   : navigate(
                    //       `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`
                    //     );
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                {/* <Form.Item> */}
                <Button
                  type="primary"
                  // loading={updateAndNewButton}
                  onClick={() => {
                    addSalesInvoice(false);
                    setIsSaveDraft(false);
                  }}
                >
                  {updateId && !updateAndNew ? "Update" : "Save"}
                </Button>
                {/* </Form.Item> */}
              </Col>
              <Col>
                {updateId && !updateAndNew ? (
                  <Button
                    // loading={saveNewLoading}
                    onClick={() => {
                      // setIsSaveDraft(false);
                      addSalesInvoice(true);
                    }}
                    type="primary"
                  >
                    Update & New
                  </Button>
                ) : (
                  <Button
                    // loading={saveNewLoading}
                    onClick={() => {
                      // setIsSaveDraft(false);
                      addSalesInvoice(true);
                    }}
                    type="primary"
                  >
                    Save & New
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>

      {/* ------------- props -------------  */}
      {/* -------------TCS Model ----------*/}
      {tcsModelOpen && (
        <TcsModel isModalOpen={tcsModelOpen} setIsModalOpen={setTcsModelOpen} />
      )}
      {/* -------------TDS Model ----------*/}
      {tdsModelOpen && (
        <TdsModel isModalOpen={tdsModelOpen} setIsModalOpen={setTdsModelOpen} />
      )}
      {/* -------------ADD Party ----------*/}

      {/* -------------ADD Items ----------*/}
      {openModel.type === "addItem" && (
        <AddItems
          open={openModel?.param.open}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          id={itemId}
          setTrigger={setloadItemAPI}
          setNewItem={setNewItem}
        />
      )}
      {/* -------------ADD Bank Account ----------*/}
      {openModel.type === "addBankAccount" && (
        <AddBankAccount
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          bankAccountTrigger={() => setTrigger(!trigger)}
          id={openModel.param?.id}
          defaultGroup={openModel?.param?.defaultGroup}
          // setNewItem={setNewItem}
        />
      )}
      {openModel.type === "addInvoiceReferenceModel" && (
        <AddSalesInvReferenceModel
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          invoiceId={updateAndNew ? undefined : updateId}
          voucherType={splitPathInVchType}
          currentInvoiceDetails={currentInvoiceDetails}
          newPartyRecepitDetails={newPartyRecepitDetails}
          oldPartyRecepitDetails={
            updateInPartyId === selectedParty ? oldPartyRecepitDetails : []
          }
          newSalesInvoice={saveNewLoading}
          setSaveAndNew={() => setSaveAndNew(true)}
          setUpdateAndNew={() => setUpdateAndNew(true)}
          editInvBankDetails={editInvBankDetails}
          settingControl={{
            showReceipt: settingControl.showReceipt,
            voucherTitle: selectCustomizationTemplete,
          }}
          voucherStatus={voucherStatus}
        />
      )}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={editParty?.id}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={setTrigger}
          type={openModel?.param?.ledgerByGroupsType}
          setAddLedgerResponse={setAddLedgerResponse}
          // setRefresh={setRefresh}
          defaultGroup={openModel?.param?.defaultGroup}
          showName={openModel?.param?.showName}
        />
      )}
      {openModel.type === "addDespatchDetail" && (
        <AddSalesInvoiceDespatchDetails
          open={openModel?.param.open}
          onCancel={() => {
            !updateId && setInvoiceDespatchDetails({});
            setOpenModel({ type: "", param: "" });
          }}
          data={handleDataFromChild}
          saveData={invoiceDespatchDetails}
          voucherType={splitPathInVchType}
        />
      )}
      {openModel.type === "addExportDetail" && (
        <AddSalesInvoiceExportDetail
          open={openModel?.param.open}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          data={handleDataFromChild}
        />
      )}

      {openModel.type === "termsAndConditionsModal" && (
        <TermsAndConditionModal
          open={openModel.param.open}
          onCancel={() => setOpenModel({ type: "", param: { open: false } })}
          setTermsAndConditionField={setTermsAndConditionField}
          termsAndConditionField={termsAndConditionField}
          voucherType={
            splitPathInVchType === Voucher_Type.SalesVoucher
              ? Voucher_Type?.SalesVoucher
              : splitPathInVchType === Voucher_Type?.PurchaseVoucher
              ? Voucher_Type.PurchaseVoucher
              : ""
          }
          voucherTitleCustomization={selectCustomizationTemplete}
        />
      )}
      {previewImage.path && (
        <DocumentPreview
          open={true}
          onCancel={() =>
            setPreviewImage({
              path: "",
              isBaseConvert: false,
              // uploadType: "",
            })
          }
          documentDetails={previewImage}
        />
      )}
      {/* --------- Add TCS Model--------- */}
      {openModel.type === "addTcsRate" && (
        <AddTcsTaxRate
          open={true}
          setOpen={() => setOpenModel({ type: "", param: { open: false } })}
          setTrigger={setTcsListTrigger}
          editData={openModel.param}
        />
      )}
      {/* --------- Add TDS Model--------- */}
      {openModel.type === "addTdsRate" && (
        <AddTdsTaxRate
          open={true}
          setOpen={() => setOpenModel({ type: "", param: "" })}
          setTrigger={setTdsListTrigger}
          editData={openModel.param}
        />
      )}
    </>
  );
};

export default AddSalesInvoice;

export const returnNetAmount = (i: number, items: any[]) => {
  let netAmount = items && items[i]?.amount?.price * items[i]?.amount?.qty;
  return netAmount;
};

export const handleRoundoff = (
  totalAmount: number,
  roundoffCheckbox: boolean,
  roundOffType?: RoundOff_Type
) => {
  console.log("handleRoundoff", totalAmount, roundoffCheckbox, roundOffType);

  let amount: number = 0;
  if (roundoffCheckbox && totalAmount > 0) {
    const onlyInteger = Math.floor(totalAmount);
    const onlyPointAmount = totalAmount - onlyInteger;

    console.log(
      "form.getFieldValue()",
      totalAmount,
      roundoffCheckbox,
      roundOffType,
      onlyPointAmount
    );

    if (Number(onlyPointAmount)) {
      if (roundOffType === RoundOff_Type.Normal) {
        if (onlyPointAmount > 0.49) {
          amount = Math.round((1 - onlyPointAmount) * 100) / 100;
        } else {
          amount = -Math.round(onlyPointAmount * 100) / 100;
        }
      } else if (roundOffType === RoundOff_Type.Ceiling) {
        amount = Math.round((1 - onlyPointAmount) * 100) / 100;
      } else if (roundOffType === RoundOff_Type.Floor) {
        amount = -Math.round(onlyPointAmount * 100) / 100;
      }
    }
  } else {
    amount = 0;
  }

  return amount;
};

const findVoucharCustomizationTemplete = (
  settingsSections: SettingsSections,
  allVouchersSettings?: vouchersSettings[]
) => {
  const vouchersTypeTem = allVouchersSettings?.filter(
    (x: vouchersSettings) => x?.settingType === settingsSections
  );

  return vouchersTypeTem?.map?.((x: vouchersSettings, index: number) => ({
    value: x?.title,
    label: x?.title,
    key: index,
    title: `${x?.settingType}`,
  }));
};
