import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Row,
  Input,
  DatePicker,
  Table,
  Tooltip,
  Form,
  Select,
  Card,
  Skeleton,
  Space,
  Popover,
  TableColumnsType,
} from "antd";
import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
// import "./Journals.css";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import "../../Common/Acc_Common.css";
//import JournalService from "../../Services/JournalService";
import VoucherServices, {
  Party_Tax,
  Voucher_Type,
  VoucherFilterStatus,
} from "../../Services/VoucherServices";
import { useTheme } from "../../Context/ThemeContext";
import { dayjs } from "../../../Utilities/dayjs";
// import ViewJournal from "./ViewJournal";
import { Utils } from "../../../Utilities/Utils";
import noteContext from "../../Context/StyleContext";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { TransactionTypes } from "../../../Types/CommonConstants";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import { useHotkeys } from "react-hotkeys-hook";
import DraftService from "../../Services/DraftService";
import { VoucherMode } from "../../Services/SalesService";
import {
  VoucherDetailsData,
  voucherType,
} from "../Reports/VoucherDetailsByLedger";
import { viewVoucher } from "../../Services/ViewVoucher";
import { SearchLedgerByGroupsTypes } from "../../Services/LedgerService";
import ViewContra from "../Banking/ViewContra";
import ViewJournal from "../Journals/ViewJournal";
import ViewCreditNote from "../Sales/ViewCreditNote";
import ViewPreviewModel from "../Sales/ViewPreviewModel";
import ViewPaymentIn from "../Sales/ViewPaymentIn";
import AdjustBankBalanceModel from "../Banking/Banks/AdjustBankBalanceModel";
import Party from "../Parties/Party";

const DraftTable = () => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [journalId, setJournalId] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [draftDetailData, setDraftDetailData] = React.useState<any>([]);
  //const [journalList, setJournalList] = React.useState<any[]>([]);
  const [searchJournal, setSearchJournal] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );

  const [totAmount, setTotAmount] = React.useState<number>(0);
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<any>({
    id: "",
    isRev: false,
  });
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [exportType, setExportType] = React.useState<number>(-1);
  const [exportDraftData, setExportDraftData] = React.useState<any[]>([]);
  const [voucharType, setVouchType] = React.useState<Voucher_Type>(
    Voucher_Type.All
  );
  const [id, setId] = React?.useState<any>();
  const [draftId, setDraftId] = React.useState<string>("");
  const [type, setType] = React?.useState<any>();
  const [invoiceModel, setInvoiceModel] = React.useState<string>("");
  const [creditModalOpen, setCreditModalOpen] = React.useState<boolean>(false);
  const [journalModal, setJournalModal] = React.useState<boolean>(false);
  const [contraModalOpen, setContraModalOpen] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [refNo, setRefNo] = useState<string>("");
  const [bank, setBank] = React.useState<{
    id: string;
    name: string | undefined;
    partyOrTax: Party_Tax | undefined;
  }>({
    id: "",
    name: "",
    partyOrTax: Party_Tax.other,
  });

  interface IJournalList {
    key: React.Key;
    id: string;
    number: number;
    ledgerName: string;
    date: any;
    amount: number;
    voucherStatus: number;
  }

  const columns: TableColumnsType<VoucherDetailsData> = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      sorter: true,
      ellipsis: true,
      width: "8%",
      // width: width <= 1440 ? "10%" : "9%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Account Name",
      // key: "ledgerName",
      key: "pLedger",
      sorter: true,
      dataIndex: "ledgerName",
      width: "25%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div
          // className="hoverLinkColor cursorPointer"
          >
            {record?.ledgerName}
          </div>
        </Skeleton>
      ),
    },
    {
      title: "Ref.No",
      key: "number",
      sorter: true,
      dataIndex: "number",
      ellipsis: true,
      width: "10%",

      // width: width <= 1440 ? "9%" : "8%",
      align: "left",
      // render: (_, record) => (
      //   <Skeleton
      //     active
      //     loading={loading}
      //     paragraph={{ rows: 1 }}
      //     title={false}
      //   >
      //     {record?.voucherStatus === 1 ? (
      //       <>
      //         <span
      //           style={{ color: "red" }}
      //           // style={{
      //           //   color: "#395cd2",
      //           //   cursor: "pointer",
      //           // }}
      //           // onClick={() =>
      //           //   viewVoucher(
      //           //     navigate,
      //           //     currentClient,
      //           //     record.id,
      //           //     record.voucherType,
      //           //     setId,
      //           //     setIsOpen,
      //           //     setDraftId,
      //           //     setType,
      //           //     setInvoiceModel,
      //           //     setCreditModalOpen,
      //           //     setJournalModal,
      //           //     setContraModalOpen
      //           //   )
      //           // }
      //         >
      //           {record.mode === VoucherMode.Opening ||
      //           record.mode === VoucherMode.Closing
      //             ? ""
      //             : record?.number}
      //         </span>
      //       </>
      //     ) : (
      //       <>
      //         <span
      //           style={{
      //             color: "#395cd2",
      //             cursor: "pointer",
      //           }}
      //           onClick={() =>
      //             viewVoucher(
      //               navigate,
      //               currentClient,
      //               record.id,
      //               record.voucherType,
      //               setId,
      //               setIsOpen,
      //               setDraftId,
      //               setType,
      //               setInvoiceModel,
      //               setCreditModalOpen,
      //               setJournalModal,
      //               setContraModalOpen
      //             )
      //           }
      //         >
      //           {record.mode === VoucherMode.Opening ||
      //           record.mode === VoucherMode.Closing
      //             ? ""
      //             : record?.number}
      //         </span>
      //       </>
      //     )}
      //   </Skeleton>
      // ),
    },
    {
      title: "Voucher",
      key: "voucherType",
      dataIndex: "voucherType",
      ellipsis: true,
      width: "13%",

      // width: width <= 1440 ? "10%" : "9%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.mode === VoucherMode.Opening ||
          record.mode === VoucherMode.Closing
            ? ""
            : voucherType[record?.voucherType]}
        </Skeleton>
      ),
    },
    {
      title: "Amount ( ₹ )",
      key: "amount",
      dataIndex: "amount",
      ellipsis: true,
      width: "10%",

      // width: width <= 1440 ? "9%" : "8%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <>{Utils.getFormattedNumber(record?.amount)}</>
        </Skeleton>
      ),
    },
    {
      title: "Action",
      key: "id",
      dataIndex: "icon2",
      width: "7%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div>
            <Row justify={"center"}>
              <Col>
                <Tooltip title="Edit" color="#444653" placement="top">
                  <EditOutlined
                    twoToneColor="#444653"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      record?.voucherType === Voucher_Type.Receipt
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editReceiptDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Payment
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editPaymentOutDraft/${record.id}`
                          )
                        : record?.voucherType === Voucher_Type.SalesVoucher
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editInvoiceDraft/${record?.id}`,
                            {
                              state: { copy: false },
                            }
                          )
                        : record?.voucherType === Voucher_Type?.PurchaseVoucher
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editBillDraft/${record?.id}`,
                            {
                              state: { copy: false },
                            }
                          )
                        : record?.voucherType === Voucher_Type.CreditNote
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editCreditNoteDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.DebitNote
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editCreditNoteDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Journal
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Quotation
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editEstimateDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.SalesOrder
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editSalesOrderDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.PurchaseOrder
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editPurchaseOrderDraft/${record?.id}`
                          )
                        : setId(record?.id);

                      setType(SearchLedgerByGroupsTypes?.All);

                      record?.voucherType === Voucher_Type.Contra &&
                        setBank({
                          id:
                            record?.amount > 0
                              ? record?.ledgerId
                              : record?.bankledgerId,
                          name: record?.amount > 0 ? record?.ledgerName : "",
                          partyOrTax: Party_Tax.BanknCash,
                        });

                      record?.voucherType === Voucher_Type.Contra &&
                        setOpenModel({
                          type: "adjustBankBalance",
                          param: {
                            id: "",
                            type: VoucherFilterStatus.Draft,
                          },
                        });

                      // setOpenModel({
                      //   type: "adjustBankBalance",
                      //   param: {
                      //     id: "",
                      //     type: "",
                      //   },
                      // });

                      // Navigate
                      // setId(record?.id);
                      // setType(SearchLedgerByGroupsTypes?.All);
                      // setOpenModel({
                      //   type: "adjustBankBalance",
                      //   param: {
                      //     id: "",
                      //     type: "",
                      //   },
                      // });
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                {/* {invoiceStatus !== VoucherFilterStatus.Deleted && ( */}
                <Tooltip title="Delete" color="red" placement="top">
                  <DeleteTwoTone
                    className="ca-delete-btn cursor"
                    twoToneColor="#ff879d"
                    onClick={() => {
                      setConfirmation(true);
                      setDraftId(record?.id);
                      setRefNo(record?.number);
                      // setDeleteVoucher({
                      //   ...deleteVoucher,
                      //   number: record?.number,
                      // });
                    }}
                  />
                </Tooltip>
                {/* )} */}
              </Col>
            </Row>
          </div>
        </Skeleton>
      ),
    },
  ];

  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      // setLedgerDetailsData([]);
      getList(
        currentClient?.id,
        voucharType,
        dayjs(filterDate.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate.to, "DD/MM/YYYY").endOf("day").toISOString(),
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filter.sortCol,
        filter.sortDir,
        undefined,
        // partyId
        searchJournal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    searchJournal,
    filterDate,
    filterDate,
    refreshAtDelete,
    exportType,
    voucharType,
    // exportType,
    // props?.filterDate,
  ]);

  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    from: string,
    to: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    search?: string
  ) => {
    try {
      if (exportType < 1) {
        setLoading(true);
      }
      const res = await DraftService.getVoucherDraftList(
        clientId,
        Voucher_Type,
        from,
        to,
        start,
        length,
        sortCol,
        sortDir,
        partyId,
        search
      );
      if (res.result?.voucherItems?.length > 0) {
        let draftList = res?.result?.voucherItems
          ?.map((el: any, index: number) => {
            var arr: any = [];
            //  console.log("This is my data", el);
            arr.push({
              ...el?.eLedger[0],
              ledgerName: el?.pLedger[0]?.ledgerName,
              voucherType: el?.voucherType,
              id: el?.vId,
              ledgerId: el?.pLedger[0]?.id,
              bankledgerId: el?.eLedger[0]?.id,
              number: el?.number,
              mode: el?.mode,
              voucherStatus: el?.voucherStatus,
              date: dayjs(el?.date).format("DD/MM/YYYY"),
            });

            let openiningEntryIndex = arr.findIndex(
              (v: any) => v.mode === VoucherMode.Opening
            );
            if (openiningEntryIndex !== -1) {
              const obj = arr.splice(openiningEntryIndex, 1)[0];
              arr.unshift(obj);
            }
            return arr;
          })
          .flat();
        if (exportType >= 1) {
          setExportDraftData(draftList);
          if (exportType === 1) {
            exportCsv(draftList);
          } else if (exportType === 2) {
            exportPDF(draftList);
          } else if (exportType === 3) {
            exportExcel(draftList);
          }
        } else {
          setTotalRecords(res.result?.totalRecords);
          setLoading(false);
          setDraftDetailData(draftList);
        }
        setLoading(false);
      } else {
        setExportDraftData([]);
        setDraftDetailData([]);
        setLoading(false);
        setTotalRecords(res.result?.totalRecords);
      }
    } catch (ex) {
      setExportDraftData([]);
      setDraftDetailData([]);
      console.log("error", ex);
      setLoading(false);
    }
  };
  //console.log(draftDetailData, "dataAll");

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  // const exportCsv = () => {
  //   VoucherServices.exportCsv(
  //     currentClient?.id,
  //     Voucher_Type.Journal,
  //     (page - 1) * pageSize,
  //     pageSize,
  //     filterDate.from,
  //     filterDate.to,
  //     filter.sortCol,
  //     filter.sortDir,
  //     undefined,
  //     undefined,
  //     searchJournal,
  //     status
  //     // searchCustomer
  //     // invoiceStatus
  //   )
  //     .then((res) => {
  //       if (res) {
  //         console.log("download sucess");
  //       }
  //     })
  //     .catch((ex: any) => {
  //       console.error("error..", ex.message);
  //       openNotification("error", ex.message);
  //     });
  // };
  // ----- Short-Cut for Open Model -----F
  // useKeyboardShortcuts("N", ["shiftKey"], () => {
  //   navigate(`/accounts/clients/${currentClient?.id}/journals/add`);
  // });

  // useHotkeys("shift+n", () => {
  //   navigate(`/accounts/clients/${currentClient?.id}/journals/add`);
  // });

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      if (col === "Amount") {
        return `${Utils.getFormattedNumber(totAmount)}`;
      } else if (col === "Account Name") {
        return "Total";
      } else {
        return ""; // Empty string for other columns
      }
    });
  };

  let path = "Draft List";
  const columnsA = ["Date", "Account Name", "Ref No", "Voucher", "Amount"];
  console.log("jData", draftDetailData);

  const fieldMapping = {
    Date: (item: any) => item?.date,
    "Account Name": (item: any) => item?.ledgerName,
    "Ref No": (item: any) => item?.number,
    Voucher: (item: any) => voucherType[item?.voucherType],
    Amount: (item: any) => Utils.getFormattedNumber(item?.amount),
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportDraftData.length > 0) {
              exportCsv(exportDraftData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportDraftData.length > 0) {
              exportPDF(exportDraftData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportDraftData.length > 0) {
              exportExcel(exportDraftData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };

  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };

  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs();
    const startDate = now.subtract(15, "day").startOf("day");
    const endDate = now.endOf("day");
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs();
    const startDate = now.subtract(7, "day").startOf("day");
    const endDate = now.endOf("day");
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <React.Fragment>
        <Row style={{ marginTop: 10 }}>
          <Col lg={10} md={24} xxl={12} xl={10}>
            <Space size={5}>
              <Select
                value={voucharType}
                style={{ width: "190px" }}
                showSearch
                allowClear={false}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  { value: Voucher_Type.All, label: "All Vouchers" },
                  {
                    value: Voucher_Type.SalesVoucher,
                    label: "Sales Invoice",
                  },
                  { value: Voucher_Type.CreditNote, label: "Credit Note" },
                  {
                    value: Voucher_Type.PurchaseVoucher,
                    label: "Purchase Invoice",
                  },
                  { value: Voucher_Type.DebitNote, label: "Debit Note" },
                  { value: Voucher_Type.Receipt, label: "Receipt" },
                  { value: Voucher_Type.Payment, label: "Payment" },
                  { value: Voucher_Type.Quotation, label: "Estimate" },
                  { value: Voucher_Type.SalesOrder, label: "Sales Order" },
                  {
                    value: Voucher_Type.PurchaseOrder,
                    label: "Purchase Order",
                  },
                  { value: Voucher_Type.Journal, label: "Journal" },
                  { value: Voucher_Type.Contra, label: "Contra" },
                ]}
                onChange={(val: Voucher_Type) => {
                  setPage(1);
                  setVouchType(val);
                }}
              />
              {/* <Tooltip
                  title={
                    <>
                      Add Journal
                      <br />
                      <Tag style={{ color: "white" }}>
                        <kbd>Shift + F3</kbd>
                      </Tag>
                    </>
                  }
                >
                  <Button
                    type="primary"
                    style={{
                      color: "white",
                      marginBottom: "0px",
                    }}
                    onClick={() => {
                      navigate(
                        `/accounts/clients/${currentClient?.id}/journals/add`
                      );
                    }}
                  >
                    <PlusOutlined />
                    Journal
                  </Button>
                </Tooltip> */}
              {draftDetailData?.length === 0 ? (
                <>
                  <Button type="default" icon={<DownloadOutlined />} disabled>
                    Export
                  </Button>
                </>
              ) : (
                <>
                  <Popover
                    content={content}
                    placement="rightTop"
                    trigger="hover"
                  >
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      // onClick={() => exportCsv()}
                    >
                      Export
                    </Button>
                  </Popover>
                </>
              )}
            </Space>
          </Col>
          <Col lg={12} md={24} xxl={12}>
            <Row justify="end" gutter={8}>
              <Col>
                {/* <Form.Item name="search" className="inputBoxMb"> */}
                <Input
                  name="search"
                  style={{ width: "200px", border: "1px solid #f1f3ff" }}
                  placeholder="Search Ledger"
                  allowClear
                  onChange={(e: any) => {
                    setLoading(true);
                    if (!e.cancelable) {
                      const searchItem = setTimeout(() => {
                        setPage(1);
                        setSearchJournal(e.target.value);
                      }, 500);
                      return () => clearTimeout(searchItem);
                    } else {
                      setSearchJournal(e.target.value);
                    }
                    setExportDraftData([]);
                    setExportType(-1);
                  }}
                  suffix={
                    loading || searchJournal ? (
                      ""
                    ) : (
                      <img alt="search" src={search} />
                    )
                  }
                />
                {/* </Form.Item> */}
              </Col>
              <Col>
                <RangePicker
                  defaultValue={[
                    dayjs(filterDate?.from, "DD/MM/YYYY"),
                    dayjs(filterDate?.to, "DD/MM/YYYY"),
                  ]}
                  format="DD/MM/YYYY"
                  style={{ width: "250px", border: "1px solid #f1f3ff" }}
                  onChange={(e: any) => {
                    // console.log(e, "date");

                    if (e === null) {
                      setFilterDate({});
                    } else {
                      setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                      localStorage.setItem(
                        "LockPeriod",
                        JSON.stringify({
                          ...filterDate,
                          from: dayjs(e[0]).format("DD/MM/YYYY"),
                          to: dayjs(e[1]).format("DD/MM/YYYY"),
                        })
                      );
                      openNotification("success", `Period locked.`);
                    }
                    setExportDraftData([]);
                    setExportType(-1);
                  }}
                  presets={[
                    {
                      label: "Today",
                      value: [dayjs().add(0, "d"), dayjs()],
                    },
                    {
                      label: "Last 7 Days",
                      value: getLast7Days(),
                    },
                    {
                      label: "Last 15 Days",
                      value: getLast15Days(),
                    },
                    {
                      label: "This Month",
                      value: getThisMonth(),
                    },
                    {
                      label: "Last Month",
                      value: getLastMonth(),
                    },
                    {
                      label: "Quarter 1",
                      value: getQuarterPreset(0),
                    },
                    {
                      label: "Quarter 2",
                      value: getQuarterPreset(3),
                    },
                    {
                      label: "Quarter 3",
                      value: getQuarterPreset(6),
                    },
                    {
                      label: "Quarter 4",
                      value: getQuarterPreset(9),
                    },
                    {
                      label: "This Year",
                      value: thisYear(),
                    },
                    {
                      label: "Last Year",
                      value: lastYear(),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          scroll={
            pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
          }
          locale={{
            triggerDesc: "",
            triggerAsc: "",
            cancelSort: "",
          }}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as IJournalList[])
              : draftDetailData
          }
          columns={columns}
          onChange={tableSort}
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["10", "15", "25", "50", "100"],
            showTotal: (totalRecords, page) =>
              `${page[0]}-${page[1]} of ${totalRecords} items`,
          }}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell
                    index={1}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="fw600">
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="fw600" align="right">
                    ₹ {Utils.getFormattedNumber(totAmount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={6} className="fw600">
                    {"   "}
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />

        {confirmation && (
          <ConfirmationModal
            open={confirmation}
            onNo={() => setConfirmation(false)}
            onYes={() => {
              setDeleteOrRevive({
                id: journalId,
                isRev: false,
              });
              // setRefreshAtDelete(!refreshAtDelete);
            }}
            text={`Are you sure you want to delete?`}
          />
        )}

        {open && (
          <ViewPaymentIn
            open={open}
            onCancel={() => setIsOpen(false)}
            paymentId={draftId}
            type={type}
          />
        )}

        {invoiceModel === "viewSalesInvoice" && (
          <ViewPreviewModel
            open={invoiceModel === "viewSalesInvoice"}
            invVoucharType={type}
            onCancel={() => setInvoiceModel("")}
            voucherId={draftId}
          />
        )}

        {creditModalOpen && (
          <ViewCreditNote
            open={creditModalOpen}
            onCancel={() => setCreditModalOpen(false)}
            creditNoteId={draftId}
            voucharType={type}
          />
        )}

        {journalModal && (
          <ViewJournal
            open={journalModal}
            onCancel={() => setJournalModal(false)}
            journalId={draftId}
            type={type}
          />
        )}

        {contraModalOpen && (
          <ViewContra
            open={contraModalOpen}
            onCancel={() => setContraModalOpen(false)}
            voucherId={draftId}
            type={type}
          />
        )}

        {openModel.type === "adjustBankBalance" && (
          <AdjustBankBalanceModel
            open={true}
            onCancel={() => setOpenModel({ type: "", param: "" })}
            // bankAccountTrigger={() => setBankTrigger(!bankTrigger)}
            // triggerBankTransactions={() =>
            //   setTriggerBankTransactions(!triggerBankTransactions)
            // }
            type={openModel.param?.type}
            allBankAccount={[]}
            bank={bank}
            id={id}
            // ledgerName={props?.ledgerName}
            // setRefresh={setRefresh}
            disable={openModel?.param?.disable}
            // item={{ id: bank.id, name: bankAccountDetails?.name }}
          />
        )}

        {confirmation && (
          <ConfirmationModal
            open={true}
            onNo={() => setConfirmation(false)}
            onYes={async () => {
              try {
                const deleteData = await DraftService.deleteDraft(
                  currentClient?.id!,
                  draftId
                );
                if (deleteData.result) {
                  openNotification("success", "Delete Sucessfully");
                  setRefreshAtDelete(!refreshAtDelete);
                  setExportType(-1);
                  setConfirmation(false);
                }
              } catch (err) {
                openNotification("error", err);
                console.log("error", err);
                setConfirmation(false);
              }
            }}
            text={`Are you sure you want to delete this ${refNo} Draft ?`}
          />
        )}
      </React.Fragment>
    </Card>
  );
};

export default DraftTable;
