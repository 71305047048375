import React from "react";
import { Route, Routes } from "react-router-dom";
import { notification } from "antd";
import { useClient } from "../Context/ClientContext";
import { NotificationPlacement } from "antd/es/notification/interface";
import Dashboard from "../Pages/MainDashboard/MainDashboard";
import AddDeliveryChallan from "../Pages/Sales/AddDeliveryChallan";
import AddPurchaseInvoice from "../Pages/Purchase/AddPurchaseInvoice";
import AddSalesInvoice from "../Pages/Sales/AddSalesInvoice";
import DashboardTemplate from "../Template/DashboardTemplate";
import SalesNestedRoute from "../Pages/Sales/SalesNestedRoute";
import SalesInvoiceTemplate from "../Template/SalesInvoiceTemplate";
import ExpensesIncomeNestedRoute from "../Pages/Expenses&income/ExpensesIncomeNestedRoute";
import AddIncomeInvoice from "../Pages/Expenses&income/AddIncome";
import AddPurchaseOrder from "../Pages/Purchase/AddPurchaseOrder";
import AddSalesCreditNotesNew from "../Pages/Sales/AddSalesCreditNotesNew";
import AddQuotation from "../Pages/Sales/AddQuotation";
import PurchaseNestedRoute from "../Pages/Purchase/PurchaseNestedRoute";
import AddPaymentOut from "../Pages/Purchase/AddPaymentOut";
import AssetsNestedRoute from "../Pages/Assets/AssetsNestedRoute";
import AddJournal from "../Pages/Journals/AddJournal";
import AddExpensesInvoice from "../Pages/Expenses&income/AddExpenses";
import ShareCapitalNestedRoute from "../Pages/Share Capital/ShareCapitalNestedRoute";
import BankRules from "../Pages/BackRules/BankRules";
import Ledger from "../Pages/Reports/Ledger";
import TrialBalance from "../Pages/Reports/TrialBalance";
import BalanceSheet from "../Pages/Reports/BalanceSheet";
import ChartOfAccounts from "../Pages/ChartOfAccounts/ChartOfAccounts";
import Settings from "../Pages/Settings/Settings";
import GstReturn from "../Pages/GstReturn/GstReturn";
import Parties from "../Pages/Parties/Party";
import Journals from "../Pages/Journals/JournalsTable";
import PartyDeatils from "../Pages/Parties/PartyInformation/PartyDeatils";
import AddSalesCreditNote from "../Pages/Sales/AddSalesCreditNotesNew";
import InventoryTabs from "../Pages/ItemInventory/InventoryTabs";
import BankingNestedRoute from "../Pages/Banking/BankingNestedRoute";
// import SettingNestedRoute from "../Pages/Settings/SettingsNestedRoute";
import ProfitAndLoss from "../Pages/Reports/ProfitAndLoss";
import AgeingNestedRoute from "../Pages/Reports/AgeingNestedRoute";
import EditTemplate from "./EditTemplate";
import GroupTable from "../Pages/Master/Group/GroupTable";
import AddPaymentInNew from "../Pages/Sales/AddPaymentInNew";
import TrialBalance_New from "../Pages/Reports/TrialBalance_New";
import AddPaymentIn from "../Pages/Sales/AddPaymentIn";
import MasterNestedRoute from "../Pages/Master/MasterNestedRoute";
//import LedgerNew from "../Pages/Reports/LedgerNew";
import LedgerDetails from "../Pages/Master/Ledger/LedgerDetails";
import { Registers } from "../Pages/Reports/Register";
import GroupDetails from "../Pages/Master/Group/AllDetailsGroupWise/GroupDetails";
import AddOrder from "../Pages/Sales/AddOrder";
import ProfitAndLossNew from "../Pages/Reports/ProfitAndLossNew";
import { Statements } from "../Services/ReportsServiceNew";
import AddJournalNew from "../Pages/Journals/AddJornalNew";
import SettingNestedRoute from "../Pages/Settings/SettingNestedRoute";
import GeneralLedger from "../Pages/Reports/GeneralLedger";
// import EditInvoiceTemplate from "./EditInvoiceTemplate";
import GSTR1 from "../Pages/Reports/GSTReports/GSTR1";
import EditInvoiceTemplate from "../Pages/Settings/EditInvoiceTemplate";
import Reports from "../Pages/Reports/Reports";
import Ageing from "../Pages/Reports/Ageing";
import PartyOutstanding from "../Pages/Reports/PartyOutstanding";
import FAR from "../Pages/Reports/FAR";
import BRSReport from "../Pages/Reports/BRSReport";
import GetBankImportData from "../Pages/Banking/Banks/History";
import BankTransactionsTab from "../Pages/Banking/Banks/BankTransactionsTab";
import AssetModel from "../Pages/Assets/AssetModel";
import DraftTable from "../Pages/Draft/DraftTable";
import AddCustomer from "../Pages/Users/UsersDetailsTabs";
// import BankTransactionsTab from "../Pages/Banking/Banks/BankTransactionsTab";

// ---------- React.lazy ----------

// const AsyncSalesInvoiceTemplate = React.lazy(
//   () => import("../Template/SalesInvoiceTemplate")
// );
// const AsyncDashboardTemplate = React.lazy(
//   () => import("../Template/DashboardTemplate")
// );
// const AsyncAddSalesCreditNotes = React.lazy(
//   () => import("../Pages/Sales/AddSalesCreditNotesNew")
// );
// const AsyncSalesOrders = React.lazy(
//   () => import("../Pages/Sales/AddSalesOrder")
// );
// const AsyncAddSalesInvoice = React.lazy(
//   () => import("../Pages/Sales/AddSalesInvoice")
// );
// const AsyncAddQuotation = React.lazy(
//   () => import("../Pages/Sales/AddQuotation")
// );
// const AsyncSalesNestedRoute = React.lazy(
//   () => import("../Pages/Sales/SalesNestedRoute")
// );
// const AsyncAddPaymentIn = React.lazy(
//   () => import("../Pages/Sales/AddPaymentIn")
// );
// const AsyncShareCapitalNestedRoute = React.lazy(
//   () => import("../Pages/Share Capital/ShareCapitalNestedRoute")
// );
// const AsyncJournals = React.lazy(
//   () => import("../Pages/Journals/JournalsTable")
// );
// const AsyncAddJournal = React.lazy(
//   () => import("../Pages/Journals/AddJournal")
// );
// const AsyncAssetsNestedRoute = React.lazy(
//   () => import("../Pages/Assets/AssetsNestedRoute")
// );
// const AsyncPurchaseNestedRoute = React.lazy(
//   () => import("../Pages/Purchase/PurchaseNestedRoute")
// );
// const AsyncAddPurchaseInvoice = React.lazy(
//   () => import("../Pages/Purchase/AddPurchaseInvoice")
// );
// const AsyncAddPurchaseCreditNote = React.lazy(
//   () => import("../Pages/Purchase/AddPurchaseCreditNote")
// );
// const AsyncAddPurchaseOrder = React.lazy(
//   () => import("../Pages/Purchase/AddPurchaseOrder")
// );
// // const AsyncAddPurchasePayment = React.lazy(
// //   () => import("../Pages/Purchase/AddPurchasePayment")
// // );
// const AsyncAddPayment = React.lazy(
//   () => import("../Pages/Purchase/AddPaymentOut")
// );
// const AsyncLedger = React.lazy(() => import("../Pages/Ledger/Ledger"));
// const AsyncTrialBalance = React.lazy(
//   () => import("../Pages/TrialBalance/TrialBalance")
// );
// const AsyncSettings = React.lazy(() => import("../Pages/Settings/Settings"));
// const AsyncChartOfAccounts = React.lazy(
//   () => import("../Pages/ChartOfAccounts/ChartOfAccounts")
// );
// const AsyncAgeing = React.lazy(() => import("../Pages/Ageing/Ageing"));
// const AsyncBalanceSheet = React.lazy(
//   () => import("../Pages/BalanceSheet/BalanceSheet")
// );
// const AsyncProfitAndLoss = React.lazy(
//   () => import("../Pages/ProfitAndLoss/ProfitAndLoss")
// );
// const AsyncParties = React.lazy(() => import("../Pages/Parties/Party"));

// // const AsyncIncomeIncome = React.lazy(
// //   () => import("../Pages/Expenses&income/ExpensesIncomeNestedRoute")
// // );
// const AsyncExpensesIncomeNestedRoute = React.lazy(
//   () => import("../Pages/Expenses&income/ExpensesIncomeNestedRoute")
// );
// const AsyncAddExpensesInvoice = React.lazy(
//   () => import("../Pages/Expenses&income/AddExpenses")
// );
// const AsyncAddIncomeInvoice = React.lazy(
//   () => import("../Pages/Expenses&income/AddIncome")
// );
// const AsyncAddCategory = React.lazy(
//   () => import("../Pages/Expenses&income/AddCategoryModel")
// );

// const AsyncBankRules = React.lazy(() => import("../Pages/BackRules/BankRules"));
// const AsyncGstReturn = React.lazy(() => import("../Pages/GstReturn/GstReturn"));
// const AsyncOpeningBalance = React.lazy(
//   () => import("../Pages/OpeningBalance/OpeningBalance")
// );

// // const AsyncMainDashboard = React.lazy(
// //   () => import("../Pages/MainDashboard/MainDashboard")
// // );

export type NotificationType = "success" | "info" | "warning" | "error";
export const  notificationContext = React.createContext<any>({});

const PageRoute = () => {
  const { currentClient, companySettings, estimatehide } = useClient();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (
    type: NotificationType,
    message: string,
    description?: string,
    duration?: number,
    closeIcon?: boolean,
    placement?: NotificationPlacement,
    icon?: any
  ) => {
    api[type]({
      message: message,
      description: description,
      duration: duration ? duration : 4,
      // closeIcon: closeIcon ? false : <CloseOutlined  />,
      style: {
        marginTop: "23px",
        padding: "15px 12px 7px 15px",
      },
      placement: placement,
      icon: icon,
    });
  };

  // console.log("client id", currentClient);

  const function1 = (arr1: number[], arr2: number[]) => {
    arr1.concat(arr2).sort((a, b) => a - b);
  };
  function1([1, 3, 4], [2, 6, 7]);

  return (
    <notificationContext.Provider value={{ openNotification }}>
      <>
        {/* <React.Suspense fallback={<FullPageSpinner />}> */}
        <Routes>
          {/*  ----- Dashboard Route -----*/}
          <Route
            path={`${currentClient?.id}/dashboard`}
            element={
              <DashboardTemplate
                element={<Dashboard />}
                breadcrumprops={[
                  {
                    item: "DashBoard",
                  },
                ]}
              />
            }
          />
          {/*  ----- Parties Route -----*/}
          <Route
            path={`${currentClient?.id}/parties`}
            element={
              <DashboardTemplate
                element={<Parties />}
                breadcrumprops={[
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                ]}
              />
            }
          />
          {/* ----------- Party Details Route ----------- */}
          <Route
            path={`/${currentClient?.id}/parties/:partyId`}
            element={
              <DashboardTemplate
                element={<PartyDeatils />}
                breadcrumprops={[
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Details",
                  },
                ]}
              />
            }
          />
          {/* ----------- Party Details Sales Route ----------- */}
          <Route
            path={`${currentClient?.id}/sales/addInvoice/:partyId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Invoices",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addCreditNote/:partyId`}
            element={
              <DashboardTemplate
                element={<AddSalesCreditNote />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "CreditNote",
                    path: `/accounts/clients/${currentClient?.id}/sales/creditNotes`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/receipt/:partyId`}
            element={
              <DashboardTemplate
                element={<AddPaymentInNew />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Receipts",
                    path: `/accounts/clients/${currentClient?.id}/sales/receipts`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addEstimate/:partyId`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Estimate",
                    path: `/accounts/clients/${currentClient?.id}/sales/estimate`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addOrder/:partyId`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Order",
                    path: `/accounts/clients/${currentClient?.id}/sales/order`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addDeliveryChallan/:partyId`}
            element={
              <DashboardTemplate
                element={<AddDeliveryChallan />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "DeliveryChallan",
                    path: `/accounts/clients/${currentClient?.id}/sales/addDeliveryChallan`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          {/* ----------- Party Details Purchase Route ----------- */}
          <Route
            path={`${currentClient?.id}/purchase/purchaseBill/:partyId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Invoice",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/addDebitNote/:partyId`}
            element={
              <DashboardTemplate
                element={<AddSalesCreditNotesNew />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "DebitNote",
                    path: `/accounts/clients/${currentClient?.id}/purchase/debitNote`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/addPaymentOut/:partyId`}
            element={
              <DashboardTemplate
                element={<AddPaymentOut />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Payment",
                    path: `/accounts/clients/${currentClient?.id}/purchase/paymentOut`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/addPaymentOut/:partyId`}
            element={
              <DashboardTemplate
                element={<AddPaymentOut />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "PaymentOut",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseOut`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/addOrder/:partyId`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Order",
                    path: `/accounts/clients/${currentClient?.id}/purchase/order`,
                  },
                  {
                    item: "Parties",
                    path: `/accounts/clients/${currentClient?.id}/parties`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          {/* Master Routes */}
          <Route
            key="salesInvoice"
            path={`/${currentClient?.id}/master/*`}
            // path={"sales/*"}
            element={
              // <AsyncSalesInvoiceTemplate
              <SalesInvoiceTemplate
                // element={<AsyncSalesNestedRoute />}
                element={<MasterNestedRoute />}
                breadcrumprops={[
                  {
                    item: "Master",
                    path: `/accounts/clients/${currentClient?.id}/master/group`,
                  },
                ]}
                menuprops={[
                  {
                    showData: "Ledger",
                    addButton: "addLedger",
                  },
                  {
                    showData: "Group",
                    addButton: "addGroup",
                  },
                  {
                    showData: "Items",
                    addButton: "items",
                  },
                  {
                    showData: "Item Groups",
                    addButton: "itemGroup",
                  },
                  {
                    showData: "Item Category",
                    addButton: "itemCategory",
                  },
                  {
                    showData: "Item Locations",
                    addButton: "itemLocation",
                  },
                  {
                    showData: "Units",
                    addButton: "units",
                  },
                  {
                    showData: "Departments",
                    addButton: "departments",
                  },
                ]}
              />
            }
          />
          {/* Master Inside Details Routes */}
          <Route
            path={`/${currentClient?.id}/master/ledger/:ledgerId`}
            element={
              <DashboardTemplate
                element={<LedgerDetails />}
                breadcrumprops={[
                  {
                    item: "Master",
                    path: `/accounts/clients/${currentClient?.id}/master/ledger`,
                  },
                  {
                    item: "Ledger",
                    path: `/accounts/clients/${currentClient?.id}/master/ledger`,
                  },
                  {
                    item: "Details",
                  },
                ]}
              />
            }
          />
          <Route
            path={`/${currentClient?.id}/master/group/:groupId`}
            element={
              <DashboardTemplate
                element={<GroupDetails />}
                breadcrumprops={[
                  {
                    item: "Master",
                    path: `/accounts/clients/${currentClient?.id}/master/ledger`,
                  },
                  {
                    item: "Group",
                    path: `/accounts/clients/${currentClient?.id}/master/group`,
                  },
                  {
                    item: "Details",
                  },
                ]}
              />
            }
          />
          {/*----------- Sales Route -----------*/}
          <Route
            key="salesInvoice"
            path={`/${currentClient?.id}/sales/*`}
            // path={"sales/*"}
            element={
              // <AsyncSalesInvoiceTemplate
              <SalesInvoiceTemplate
                // element={<AsyncSalesNestedRoute />}
                element={<SalesNestedRoute />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                ]}
                menuprops={[
                  {
                    showData: "Invoices",
                    addButton: "addInvoice",
                  },
                  {
                    showData: "Credit Notes",
                    addButton: "addCreditNote",
                  },
                  {
                    showData: "Receipts",
                    addButton: "addPaymentIn",
                  },
                  {
                    showData: "Estimate",
                    addButton: "addEstimate",
                  },
                  {
                    showData: "Sales Order",
                    addButton: "addOrder",
                  },
                  {
                    showData: "Customers",
                    addButton: "customers",
                  },
                  // {
                  //   showData: "Delivery Challan",
                  //   addButton: "addDeliveryChallan",
                  // },
                  // {
                  //   showData: "Items",
                  //   addButton: "salesItem",
                  // },
                  // {
                  //   showData: "Customers",
                  // },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addInvoice`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Invoices",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          {/* Add sales invoice to sales order  */}
          <Route
            path={`${currentClient?.id}/sales/addInvoice/order/:salesOrderId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Invoices",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "SalesOrder",
                    path: `/accounts/clients/${currentClient?.id}/sales/order`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          {/* Add sales invoice to estimate  */}
          <Route
            path={`${currentClient?.id}/sales/addInvoice/estimate/:estimateId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Invoices",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Estimate",
                    path: `/accounts/clients/${currentClient?.id}/sales/estimate`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          {/* Add Estimate to sales order  sales/addSalesOrder/estimate */}
          <Route
            path={`${currentClient?.id}/sales/addSalesOrder/estimate/:estimateId`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Order",
                    path: `/accounts/clients/${currentClient?.id}/sales/order`,
                  },
                  {
                    item: "Estimate",
                    path: `/accounts/clients/${currentClient?.id}/sales/estimate`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editInvoice/:updateId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Invoices",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editInvoiceDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Invoices",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          {/* -----------------------Order Draft------------------------ */}
          <Route
            path={`${currentClient?.id}/sales/editSalesOrderDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "SalesOrder",
                    path: `/accounts/clients/${currentClient?.id}/sales/order`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/editPurchaseOrderDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/invoices`,
                  },
                  {
                    item: "PurchaseOrder",
                    path: `/accounts/clients/${currentClient?.id}/purchase/order`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          -
          {/* -----------------------Quotations Draft------------------------ */}
          <Route
            path={`${currentClient?.id}/sales/editEstimateDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddQuotation />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Estimate",
                    path: `/accounts/clients/${currentClient?.id}/sales/estimate`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editCreditNoteDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddSalesCreditNotesNew />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "CreditNotes",
                    path: `/accounts/clients/${currentClient?.id}/sales/creditNotes`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/editDebitNoteDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddSalesCreditNotesNew />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/invoices`,
                  },
                  {
                    item: "DebitNote",
                    path: `/accounts/clients/${currentClient?.id}/purchase/debitNote`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editReceiptDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddPaymentInNew />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Receipt",
                    path: `/accounts/clients/${currentClient?.id}/sales/receipt`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/editPaymentOutDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddPaymentInNew />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/invoices`,
                  },
                  {
                    item: "PaymentOut",
                    path: `/accounts/clients/${currentClient?.id}/sales/debitNote`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/cloneInvoice/:cloneId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Invoices",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Clone",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addCreditNote`}
            element={
              <DashboardTemplate
                element={<AddSalesCreditNotesNew />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "CreditNotes",
                    path: `/accounts/clients/${currentClient?.id}/sales/creditNotes`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editCreditNote/:creditNoteId`}
            element={
              <DashboardTemplate
                element={<AddSalesCreditNotesNew />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "CreditNotes",
                    path: `/accounts/clients/${currentClient?.id}/sales/creditNotes`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addReceipt`}
            element={
              <DashboardTemplate
                element={<AddPaymentInNew />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Receipts",
                    path: `/accounts/clients/${currentClient?.id}/sales/receipts`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editReceipt/:paymentId`}
            element={
              <DashboardTemplate
                element={<AddPaymentInNew />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Receipts",
                    path: `/accounts/clients/${currentClient?.id}/sales/receipts`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addEstimate`}
            element={
              <DashboardTemplate
                element={<AddQuotation />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Estimate",
                    path: `/accounts/clients/${currentClient?.id}/sales/estimate`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/editPurchaseOrder/:id`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/invoices`,
                  },
                  {
                    item: "PurchaseOrder",
                    path: `/accounts/clients/${currentClient?.id}/purchase/order`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editEstimate/:id`}
            element={
              <DashboardTemplate
                element={<AddQuotation />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Estimate",
                    path: `/accounts/clients/${currentClient?.id}/sales/estimate`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addOrder`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Order",
                    path: `/accounts/clients/${currentClient?.id}/sales/order`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/addDeliveryChallan`}
            element={
              <DashboardTemplate
                element={<AddDeliveryChallan />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "Delivery Challan",
                    path: `/accounts/clients/${currentClient?.id}/sales/deliveryChallan`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editSalesOrder/:id`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "SalesOrder",
                    path: `/accounts/clients/${currentClient?.id}/sales/order`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/sales/editDeliveryChallan/:id`}
            element={
              <DashboardTemplate
                element={<AddDeliveryChallan />}
                breadcrumprops={[
                  {
                    item: "Sales",
                    path: `/accounts/clients/${currentClient?.id}/sales/invoices`,
                  },
                  {
                    item: "DeliveryChallan",
                    path: `/accounts/clients/${currentClient?.id}/sales/addDeliveryChallan`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          {/* ----------- Purchase Route ----------- */}
          <Route
            key="dashboard"
            path={`/${currentClient?.id}/purchase/*`}
            element={
              <SalesInvoiceTemplate
                element={<PurchaseNestedRoute />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                ]}
                menuprops={[
                  {
                    showData: "Purchase Bill",
                    addButton: "addInvoice",
                  },
                  {
                    showData: "Debit Note",
                    addButton: "addDebitNote",
                  },
                  {
                    showData: "Payment Out",
                    addButton: "addPaymentOut",
                  },
                  {
                    showData: "Purchase Order",
                    addButton: "addOrder",
                  },
                  {
                    showData: "Suppliers",
                    addButton: "suppliers",
                  },
                  // {
                  //   showData: "Items",
                  //   addButton: "purchaseItem",
                  // },
                  // {
                  //   showData: "Suppliers",
                  // },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/addPurchaseBill`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Invoice",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/editPurchaseBill/:updateId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "purchaseBill",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/editBillDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "purchaseBill",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          {/* Add sales invoice to sales order  */}
          <Route
            path={`${currentClient?.id}/purchase/addPurchaseBill/order/:purchaseOrderId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "PurchaseBill",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Order",
                    path: `/accounts/clients/${currentClient?.id}/purchase/order`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/cloneBill/:cloneId`}
            element={
              <DashboardTemplate
                element={<AddSalesInvoice />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "PurchaseBill",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Clone",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/addDebitNote`}
            element={
              <DashboardTemplate
                element={<AddSalesCreditNotesNew />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Debit Note",
                    path: `/accounts/clients/${currentClient?.id}/purchase/debitNote`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/editDebitNote/:creditNoteId`}
            element={
              <DashboardTemplate
                element={<AddSalesCreditNotesNew />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Debit Note",
                    path: `/accounts/clients/${currentClient?.id}/purchase/debitNote`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/addOrder`}
            element={
              <DashboardTemplate
                element={<AddOrder />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Order",
                    path: `/accounts/clients/${currentClient?.id}/purchase/order`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/addPaymentOut`}
            element={
              <DashboardTemplate
                element={<AddPaymentInNew />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Payments",
                    path: `/accounts/clients/${currentClient?.id}/purchase/PaymentOut`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/purchase/editPaymentOut/:paymentId`}
            element={
              <DashboardTemplate
                element={<AddPaymentInNew />}
                breadcrumprops={[
                  {
                    item: "Purchase",
                    path: `/accounts/clients/${currentClient?.id}/purchase/purchaseBill`,
                  },
                  {
                    item: "Payments",
                    path: `/accounts/clients/${currentClient?.id}/purchase/PaymentOut`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          {/* ----------- Assets Route ----------- */}
          <Route
            path={`/${currentClient?.id}/asset/*`}
            element={
              <SalesInvoiceTemplate
                element={<AssetsNestedRoute />}
                breadcrumprops={[
                  {
                    item: "Assets",
                    path: `/accounts/clients/${currentClient?.id}/asset/fixedAsset`,
                  },
                ]}
                menuprops={[
                  {
                    showData: "Fixed Assets",
                    addButton: "fixedasset",
                  },
                  // {
                  //   showData: "Depreciations",
                  //   addButton: "depreciations",
                  // },
                  {
                    showData: "Disposed",
                    addButton: "disposed",
                  },
                  {
                    showData: "Location",
                    addButton: "location",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/asset/addFixedAsset`}
            element={
              <DashboardTemplate
                element={<AssetModel />}
                breadcrumprops={[
                  {
                    item: "Assets",
                    path: `/accounts/clients/${currentClient?.id}/asset/fixedAsset`,
                  },
                ]}
              />
            }
          />
          {/* ----------- Journals Route ----------- */}
          <Route
            path={`${currentClient?.id}/journals/*`}
            element={
              <DashboardTemplate
                element={<Journals />}
                breadcrumprops={[
                  {
                    item: "Journals",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/journals/add`}
            element={
              <DashboardTemplate
                element={<AddJournalNew />}
                breadcrumprops={[
                  {
                    item: "Journals",
                    path: `/accounts/clients/${currentClient?.id}/journals`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/journals/editDraft/:draftId`}
            element={
              <DashboardTemplate
                element={<AddJournalNew />}
                breadcrumprops={[
                  {
                    item: "Journals",
                    path: `/accounts/clients/${currentClient?.id}/journals`,
                  },
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/journals/:journalId`}
            element={
              <DashboardTemplate
                element={<AddJournalNew />}
                breadcrumprops={[
                  {
                    item: "Journals",
                    path: `/accounts/clients/${currentClient?.id}/journals`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          {/* ----------- Expenses & Income Route ----------- */}
          <Route
            key="expenses and income"
            path={`/${currentClient?.id}/*`}
            element={
              <SalesInvoiceTemplate
                element={<ExpensesIncomeNestedRoute />}
                breadcrumprops={[
                  {
                    item: null,
                    path: `/accounts/clients/${currentClient?.id}/expenses`,
                  },
                ]}
                menuprops={[
                  {
                    showData: "Expenses",
                    addButton: "expenses/addExpenses",
                  },
                  {
                    showData: "Income",
                    addButton: "income/addIncome",
                  },
                  {
                    showData: "Category",
                    addButton: "category",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/expenses/addExpenses`}
            element={
              <DashboardTemplate
                element={<AddExpensesInvoice />}
                breadcrumprops={[
                  {
                    item: " Expenses",
                    path: `/accounts/clients/${currentClient?.id}/expenses`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/expenses/editExpenses/:id`}
            element={
              <DashboardTemplate
                element={<AddExpensesInvoice />}
                breadcrumprops={[
                  {
                    item: " Expenses",
                    path: `/accounts/clients/${currentClient?.id}/expenses`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/income/addIncome`}
            element={
              <DashboardTemplate
                element={<AddIncomeInvoice />}
                breadcrumprops={[
                  {
                    item: "Income",
                    path: `/accounts/clients/${currentClient?.id}/income`,
                  },
                  {
                    item: "Add",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/income/editIncome/:id`}
            element={
              <DashboardTemplate
                element={<AddIncomeInvoice />}
                breadcrumprops={[
                  {
                    item: "Income",
                    path: `/accounts/clients/${currentClient?.id}/income`,
                  },
                  {
                    item: "Edit",
                  },
                ]}
              />
            }
          />
          {/* ----------- Share capital Route ----------- */}
          <Route
            key="sharecapital"
            path={`/${currentClient?.id}/sharecapital/*`}
            element={
              <SalesInvoiceTemplate
                element={<ShareCapitalNestedRoute />}
                breadcrumprops={[
                  {
                    item: "Share Capital",
                    path: `/accounts/clients/${currentClient?.id}/sharecapital/sharecapital`,
                  },
                ]}
                menuprops={["Share Capital", "Shareholders", "Dividents"]}
              />
            }
          />
          {/* ----------- BankRules Route ----------- */}
          <Route
            path={`/${currentClient?.id}/bankRules/*`}
            element={
              <DashboardTemplate
                element={<BankRules />}
                breadcrumprops={[
                  {
                    item: "Bank Rules",
                  },
                ]}
              />
            }
          />
          {/* ----------- GST Return Route ----------- */}
          <Route
            path={`/${currentClient?.id}/gstReturn/*`}
            element={
              <DashboardTemplate
                element={<GstReturn />}
                breadcrumprops={[
                  {
                    item: "Gst Return",
                  },
                ]}
              />
            }
          />
          {/*  ----------- Cash & Bank Route -----------  */}
          <Route
            path={`/${currentClient?.id}/banking/*`}
            element={
              <SalesInvoiceTemplate
                element={<BankingNestedRoute />}
                breadcrumprops={[
                  {
                    item: "Banking",
                    path: `/accounts/clients/${currentClient?.id}/banking/bankAccount`,
                  },
                ]}
                menuprops={[
                  { showData: "Bank Accounts", addButton: "banking/Banks" },
                  { showData: "Cash In Hand", addButton: "cashInHand" },
                  { showData: "Cheque", addButton: "cheque" },
                  // { showData: "Loan Accounts", addButton: "loanAccounts" },
                  {
                    showData: "Bank Reconciliation",
                    addButton: "bank Reconciliation",
                  },
                ]}
              />
            }
          />
          {/* ----------- Ledger Route ----------- */}
          {/* <Route
            path={`/${currentClient?.id}/registers/*`}
            element={
              <DashboardTemplate
                element={<Re />}
                breadcrumprops={[
                  {
                    item: "Registers",
                  },
                ]}
              />
            }
          /> */}
          {/* ----------- Ageing Route ----------- */}
          {
            <Route
              path={`/${currentClient?.id}/registers/*`}
              element={
                <DashboardTemplate
                  element={<Registers />}
                  breadcrumprops={[
                    {
                      item: "Registers",
                    },
                  ]}
                />
              }
            />
          }
          {/* defaultActiveKey={"1"} */}
          {
            <Route
              path={`/${currentClient?.id}/banking/bankTransactionsDetails`}
              element={
                <DashboardTemplate
                  element={<BankTransactionsTab />}
                  breadcrumprops={[
                    {
                      item: "Banking",
                      path: `/accounts/clients/${currentClient?.id}/banking/bankAccounts`,
                    },
                    {
                      item: "BankTransactionsDetails",
                    },
                  ]}
                />
              }
            />
          }
          {
            <Route
              path={`/${currentClient?.id}/banking/bankTransactionsHistory`}
              element={
                <DashboardTemplate
                  element={<BankTransactionsTab />}
                  breadcrumprops={[
                    {
                      item: "Banking",
                      path: `/accounts/clients/${currentClient?.id}/banking/bankAccounts`,
                    },
                    {
                      item: "BankTransactionsHistory",
                    },
                  ]}
                />
              }
            />
          }
          {
            <Route
              path={`/${currentClient?.id}/banking/bankTransactionsDetails/:id`}
              element={
                <DashboardTemplate
                  element={<BankTransactionsTab />}
                  breadcrumprops={[
                    {
                      item: "Banking",
                      path: `/accounts/clients/${currentClient?.id}/banking/bankAccounts`,
                    },
                    {
                      item: "BankTransactionsDetails",
                    },
                  ]}
                />
              }
            />
          }
          {/* {
            <Route
              path={`/${currentClient?.id}/banking/bankAccount/bankTransactionsDetails/getBankImportData*`}
              element={
                <DashboardTemplate
                  element={<GetBankImportData />}
                  breadcrumprops={[
                    {
                      item: "GetBankImportData",
                    },
                  ]}
                />
              }
            />
          } */}
          {
            <Route
              path={`/${currentClient?.id}/reports/generalLedger/*`}
              element={
                <DashboardTemplate
                  element={<GeneralLedger />}
                  breadcrumprops={[
                    {
                      item: "Reports",
                      path: `/accounts/clients/${currentClient?.id}/reports`,
                    },
                    {
                      item: "General ledger",
                    },
                  ]}
                />
              }
            />
          }
          <Route
            path={`/${currentClient?.id}reports/ageing/*`}
            element={
              <DashboardTemplate
                element={<Ageing />}
                breadcrumprops={[
                  {
                    item: "Ageing Report",
                  },
                ]}
              />
            }
          />
          {/* ----------- Trial Balance Route ----------- */}
          <Route
            path={`/${currentClient?.id}/trialbalance/*`}
            element={
              <DashboardTemplate
                element={<TrialBalance />}
                breadcrumprops={[
                  {
                    item: "Trial Balance",
                  },
                ]}
              />
            }
          />
          {/* ---------- Trial Balance Route_new --------- */}
          <Route
            path={`/${currentClient?.id}/reports/trialbalancenew/*`}
            element={
              <DashboardTemplate
                element={<TrialBalance_New />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Trial Balance",
                  },
                ]}
              />
            }
          />
          <Route
            path={`/${currentClient?.id}/reports/far/*`}
            element={
              <DashboardTemplate
                element={<FAR />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Fixed Assets Register",
                  },
                ]}
              />
            }
          />
          <Route
            path={`/${currentClient?.id}/reports/brsReports/*`}
            element={
              <DashboardTemplate
                element={<BRSReport />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Bank Reconcilation",
                  },
                ]}
              />
            }
          />
          {/* ----------- Profit & Loss Route -----------
          <Route
            path={`/${currentClient?.id}/profitAndLoss/*`}
            element={
              <DashboardTemplate
                element={<ProfitAndLoss />}
                breadcrumprops={[
                  {
                    item: "Profit And Loss",
                  },
                ]}
              />
            }
          /> */}
          {/* ----------- Profit & Loss Route ----------- */}
          <Route
            path={`/${currentClient?.id}/reports/profitAndLoss/*`}
            element={
              <DashboardTemplate
                element={<ProfitAndLossNew type={Statements.ProfitAndLoss} />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Profit And Loss",
                  },
                ]}
              />
            }
          />
          {/* ----------- Balance Sheet Route ----------- */}
          <Route
            path={`/${currentClient?.id}/reports/balanceSheet/*`}
            element={
              <DashboardTemplate
                element={<ProfitAndLossNew type={Statements.BalanceSheet} />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Balance Sheet",
                  },
                ]}
              />
            }
          />
          <Route
            path={`/${currentClient?.id}/reports/GSTR1/*`}
            element={
              <DashboardTemplate
                element={<GSTR1 />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "GST Report",
                  },
                ]}
              />
            }
          />
          <Route
            path={`/${currentClient?.id}/Reports/*`}
            element={
              <DashboardTemplate
                element={<Reports />}
                breadcrumprops={[
                  {
                    item: "Reports",
                  },
                ]}
              />
            }
          />
          <Route
            key="ageing"
            path={`/${currentClient?.id}/reports/ageing/debtors`}
            element={
              <DashboardTemplate
                element={<Ageing SelType={0} />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Ageing Report",
                    path: `/accounts/clients/${currentClient?.id}/reports/ageing/debtors`,
                  },
                  {
                    item: "Debtors",
                  },
                ]}
              />
            }
          />
          <Route
            key="ageing"
            path={`/${currentClient?.id}/reports/ageing/creditors`}
            element={
              <DashboardTemplate
                element={<Ageing SelType={1} />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Ageing Report",
                    path: `/accounts/clients/${currentClient?.id}/reports/ageing/creditors`,
                  },
                  {
                    item: "Creditors",
                  },
                ]}
              />
            }
          />
          <Route
            key="ageing"
            path={`/${currentClient?.id}/reports/partyOutstanding/customer`}
            element={
              <DashboardTemplate
                element={<PartyOutstanding Type={0} />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Customer Outstanding",
                    // path: `/accounts/clients/${currentClient?.id}/reports/partyOutstanding/customer`,
                  },
                  // {
                  //   item: "Customer Outstanding",
                  // },
                ]}
              />
            }
          />
          <Route
            key="ageing"
            path={`/${currentClient?.id}/reports/partyOutstanding/supplier`}
            element={
              <DashboardTemplate
                element={<PartyOutstanding Type={1} />}
                breadcrumprops={[
                  {
                    item: "Reports",
                    path: `/accounts/clients/${currentClient?.id}/reports`,
                  },
                  {
                    item: "Supplier Outstanding",
                    // path: `/accounts/clients/${currentClient?.id}/reports/partyOutstanding/supplier`,
                  },
                ]}
              />
            }
          />
          {/* ----------- Bank Route ----------- */}
          {/* <Route
            path={`/${currentClient?.id}/baking/banks/*`}
            element={
              <DashboardTemplate
                element={<Banking />}
                breadcrumprops={[
                  {
                    item: "Banks",
                  },
                ]}
              />
            }
          /> */}
          {/* ----------- Opening Balance Route ----------- */}
          {/* <Route
            path={`/${currentClient?.id}/openingBalance/*`}
            element={
              <DashboardTemplate
                element={<OpeningBalance />}
                breadcrumprops={[
                  {
                    item: "Opening Balance",
                  },
                ]}
              />
            }
          /> */}
          {/* ----------- Chart Of Accounts Route ----------- */}
          <Route
            path={`/${currentClient?.id}/chartOfAccounts/*`}
            element={
              <DashboardTemplate
                element={<ChartOfAccounts />}
                breadcrumprops={[
                  {
                    item: "Chart Of Accounts",
                  },
                ]}
              />
            }
          />
          {/* ----------- Settings Route ----------- */}
          <Route
            path={`/${currentClient?.id}/settings/*`}
            element={
              <SalesInvoiceTemplate
                element={<SettingNestedRoute />}
                breadcrumprops={[
                  {
                    item: "Company Settings",
                    path: `/accounts/clients/${currentClient?.id}/settings/companyInfo`,
                  },
                ]}
                menuprops={[
                  { showData: "Company Info" },
                  { showData: "Customization" },
                  // { showData: "Payment Term" },
                  // { showData: "GST" },
                  { showData: "Report" },
                  { showData: "Opening Balance" },
                  // { showData: "Email" },
                  { showData: "Templates" },
                  // { showData: "AdvSetting" },
                  { showData: "Default Ledger" },
                  { showData: "Terms And Conditions" },
                  { showData: "TDS" },

                  // { showData: "NewDashboard" },
                ]}
              />
            }
          />
          {/* ----------- Templetes Route ----------- */}
          {/* <Route
            path={`${currentClient?.id}/settings/templates/1`}
            element={
              <DashboardTemplate
                element={<EditTemplate />}
                breadcrumprops={[
                  {
                    item: "Company Settings",
                    path: `/accounts/clients/${currentClient?.id}/settings/companyInfo`,
                  },
                  {
                    item: "Templates",
                    path: `/accounts/clients/${currentClient?.id}/settings/templates`,
                  },
                  {
                    item: "1",
                  },
                ]}
              />
            }
          /> */}
          <Route
            path={`${currentClient?.id}/settings/templates/:type`}
            element={
              <DashboardTemplate
                element={<EditInvoiceTemplate />}
                breadcrumprops={[
                  {
                    item: "Company Settings",
                    path: `/accounts/clients/${currentClient?.id}/settings/companyInfo`,
                  },
                  {
                    item: "Templates",
                    path: `/accounts/clients/${currentClient?.id}/settings/templates`,
                  },
                  // {
                  //   item: `/:type`,
                  // },
                ]}
              />
            }
          />
          {/* <Route
            path={`${currentClient?.id}/settings/templates/2`}
            element={
              <DashboardTemplate
                element={<EditInvoiceTemplate />}
                breadcrumprops={[
                  {
                    item: "Company Settings",
                    path: `/accounts/clients/${currentClient?.id}/settings/companyInfo`,
                  },
                ]}
              />
            }
          /> */}
          {/* Inventory Route */}
          <Route
            path={`${currentClient?.id}/master/items/inventory`}
            element={
              <DashboardTemplate
                element={<InventoryTabs />}
                breadcrumprops={[
                  {
                    item: "Master",
                    path: `/accounts/clients/${currentClient?.id}/master/ledger`,
                  },
                  {
                    item: "Items",
                    path: `/accounts/clients/${currentClient?.id}/master/items`,
                  },
                  {
                    item: "Goods",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/master/items/inventory`}
            element={
              <DashboardTemplate
                element={<InventoryTabs />}
                breadcrumprops={[
                  {
                    item: "Master",
                    path: `/accounts/clients/${currentClient?.id}/master/ledger`,
                  },
                  {
                    item: "Items",
                    path: `/accounts/clients/${currentClient?.id}/master/items`,
                  },
                  {
                    item: "Goods",
                  },
                ]}
              />
            }
          />
          <Route
            path={`${currentClient?.id}/draft/*`}
            element={
              <DashboardTemplate
                element={<DraftTable />}
                breadcrumprops={[
                  {
                    item: "Draft",
                  },
                ]}
              />
            }
          />
        </Routes>
        {/* <Route
          path={`${currentClient?.id}/users/add`}
          element={
            <DashboardTemplate
              element={<AddCustomer />}
              breadcrumprops={[
                {
                  item: "Users",
                  path: `/accounts/clients/${currentClient?.id}/Users`,
                },
                {
                  item: "Add",
                },
              ]}
            />
          }
        /> */}
        {/* </React.Suspense> */}
        {contextHolder}
      </>
    </notificationContext.Provider>
  );
};
export default PageRoute;
