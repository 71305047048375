import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import UsersService, {
  ApplicationRoleNames,
  UserUpdateSection,
} from "../../Services/UsersService";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ClientService from "../../Services/ClientService";

const UserBasicDetails = ({ userId }: any) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectRole, setSelectRole] = React.useState<ApplicationRoleNames>();
  const [clientList, setClientList] = React.useState<any>();
  const [selectedClients, setSelectedClients] = React.useState<string[]>([]);
  const [selectAllChecked, setSelectAllChecked] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getUserDataById();
  }, []);

  const getClientData = async () => {
    let res = await ClientService?.getClientList(0, 15);
    if (res) {
      setClientList(res?.items);
    }
  };

  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    if (checked) {
      setSelectedClients(clientList?.map((client: any) => client.id));
    } else {
      setSelectedClients([]);
    }
  };

  const handleSelectChange = (selectedValues: string[]) => {
    setSelectedClients(selectedValues);
    setSelectAllChecked(selectedValues.length === clientList?.length);
  };

  React.useEffect(() => {
    getClientData();
  }, []);
  const getUserDataById = async () => {
    setLoading(true);
    let res = await UsersService?.getUserDataById(userId);
    if (res) {
      let d = res?.result;
      setSelectedClients(d?.companyList);
      // setSelectAllChecked(d?.companyList);
      form.setFieldsValue({
        first: d?.name?.first,
        last: d?.name?.last,
        email: d?.email,
        phoneNumber: d?.phoneNumber,
        role: d?.role,
        // companyList: d?.companyList,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  console.log("selectedClients", selectedClients);

  const saveBasicDetailsData = () => {
    form.validateFields().then(async (val) => {
      console.log("val", val);
      let data = {
        ...val,
        name: {
          first: val?.first,
          last: val?.last,
        },
        companyList: selectedClients,
      };
      setBtnLoading(true);
      let res = await UsersService?.UpdateUserDetailsData(userId, data, 0);
      if (res?.status === true) {
        console.log("res", res);
        getUserDataById();
        notification.success({
          message: "Success",
          description: "User details updated successfully.",
        });
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        notification.error({
          message: "Error",
          description: res?.message,
        });
      }
    });
  };

  return (
    <>
      <Card>
        <Spin spinning={loading} indicator={<LoadingOutlined />}>
          <div style={{ marginTop: "10px" }}>
            <Divider orientation="left" orientationMargin={0}>
              User Basic Details
            </Divider>
            <Form form={form} labelAlign="left" layout="vertical">
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    name="first"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your first name",
                      },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message:
                          "First Name can only contain letters and spaces",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && value.trim() === "") {
                            return Promise.reject(
                              new Error(
                                "Last Name cannot be empty or just spaces"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="last"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name",
                      },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message:
                          "Last Name can only contain letters and spaces",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && value.trim() === "") {
                            return Promise.reject(
                              new Error(
                                "Last Name cannot be empty or just spaces"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                      {
                        type: "email",
                        message: "The input is not a valid email!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Phone number must be between 10 digits!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="role" label="Role">
                    <Select
                      options={[
                        {
                          value: "ADMIN",
                          label: "Admin",
                        },
                        {
                          value: "AUTHORITIES",
                          label: "Authorities",
                        },
                        {
                          value: "DATAENTRY",
                          label: "Data Entry",
                        },
                        {
                          value: "AUDITORS",
                          label: "Auditors",
                        },
                        {
                          value: "VIEWONLY",
                          label: "View Only",
                        },
                      ]}
                      onChange={(val: any) => setSelectRole(val)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    // name="companyList"
                    label={
                      <>
                        <Row justify={"space-between"} gutter={20}>
                          <Col>
                            <span>Select Companies</span>
                          </Col>
                          <Col style={{}}>
                            <span style={{ marginTop: "28px" }}>
                              <Checkbox
                                checked={selectAllChecked}
                                onChange={handleSelectAll}
                                style={{ marginRight: "3px" }}

                              />
                              Select All Companise
                            </span>
                          </Col>
                        </Row>
                      </>
                    }
                  >
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select..."
                      value={selectedClients}
                      onChange={handleSelectChange}
                      options={clientList?.map((client: any) => ({
                        value: client?.id,
                        label: client?.bName,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Button
                  loading={btnLoading}
                  type="primary"
                  style={{ padding: "4px 30px" }}
                  onClick={saveBasicDetailsData}
                >
                  Update
                </Button>
              </Row>
            </Form>
          </div>
        </Spin>
      </Card>
    </>
  );
};

export default UserBasicDetails;
