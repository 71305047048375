import React, { useContext, useState } from "react";
import ButtonComponent from "../../Common/ButtonComponent";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Tag,
  Tooltip,
  Skeleton,
  notification,
  Popover,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  DownloadOutlined,
  DeleteTwoTone,
  RetweetOutlined,
  EditOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import noteContext from "../../Context/StyleContext";
import SalesService, {
  DBRecordStatus,
  EstimateStatus,
} from "../../Services/SalesService";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import ViewEstimateModel from "./ViewEstimateModel";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import VoucherServices, {
  OrderStatus,
  Voucher_Type,
} from "../../Services/VoucherServices";
import QuotationServices from "../../Services/QuotationServices";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import DraftService from "../../Services/DraftService";
import DownloadService, { ViewType } from "../../Services/DownloadService";
import ViewPreviewModel from "./ViewPreviewModel";

export interface QuotationDataType {
  key: React.Key;
  id: string;
  tPartner: any;
  number: any;
  name: string;
  date: string;
  duedate: any;
  // suppliedQty: number;
  totalQty: number;
  totalInvoiceAmount: number;
  dueBalance: number;
  deleteType: number;
  orderStatus: OrderStatus;
}

interface IQuotationProps {
  loading?: any;
  setLoading?: any;
  estimateList: any;
  setEstimateList: (value: any) => void;
  partyId?: string;
}

const QuotationTable = (props: IQuotationProps) => {
  const { RangePicker } = DatePicker;
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const navigate = useNavigate();
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  // const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [open1, setIsOpen1] = React.useState<string>("");
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [estimateStatus, setEstimateStatus] = React.useState<number>(4);
  const [deleteEstimate, setDeleteEstimate] = React.useState<any>({
    status: DBRecordStatus.Active,
    open: false,
    name: "",
  });
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React?.useState(false);
  const [exportQuotationData, setExportQuotationData] = useState<any[]>([]);
  const [exportType, setExportType] = useState<number>(-1);
  const [estimateId, setEstimateId] = React.useState<string>("");
  const [confirmationModel, setConfirmationModel] =
    React.useState<boolean>(false);
  const [quotationStatus, setQuotationStatus] = React.useState<any>(
    OrderStatus.Pending
  );
  const [invoiceListData, setInvoiceListData] = React.useState<any[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [loadingButtons, setLoadingButtons] = useState<any>({});
  // Define rowSelection to bind with the Table component
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Ensure selectedRows are derived from selectedRowKeys
  const selectedRows = invoiceListData.filter((item: any) =>
    selectedRowKeys.includes(item.key)
  );

  console.log("Selected Row Keys:", selectedRowKeys); // Should show the array of selected keys
  console.log("Selected Row Data:", selectedRows); // Should show the data of the selected rows
  const downloadAndView = async (
    cliendId: string,
    voucherId: string,
    type: ViewType
    // invId: any
  ) => {
    if (cliendId && voucherId) {
      setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: true }));
      await DownloadService.downloadPdf(
        cliendId,
        voucherId,
        type,
        Voucher_Type.Quotation
      )
        .then((res) => {
          console.log("res", res);
          if (res) {
            console.log("download sucess");
            setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: false }));
          }
        })
        .catch((ex) => {
          openNotification("error", ex.message);
        });
    } else {
      openNotification("error", "Invalid Details");
      setLoadingButtons(false);
    }
  };

  const deleteData = async (id: string) => {
    try {
      let res =
        quotationStatus === OrderStatus.Draft
          ? await DraftService.deleteDraft(currentClient?.id!, id)
          : await QuotationServices?.deleteData(currentClient?.id, id);
      if (res?.result) {
        setRefresh((x: boolean) => !x);
        notification.success({
          message: "",
          description: "Deleted successfully.",
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "",
          description: res?.message || "An error occurred while Delete.",
          placement: "topRight",
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  //-------- Estimate Delivery Challan --------
  // React.useEffect(() => {
  //   if (deleteEstimate.open) {
  //     SalesService.deleteEstimate(
  //       currentClient?.id!,
  //       estimateId,
  //       deleteEstimate.status
  //     )
  //       .then((res: any) => {
  //         if (res.result) {
  //           setTrigger((x) => !x);
  //           console.log(res.result);
  //           openNotification(
  //             "success",
  //             `${
  //               deleteEstimate.status === 1 ? "Delete" : "Active"
  //             } Delivery Challan`
  //           );
  //           setDeleteEstimate({});
  //         }
  //       })
  //       .catch((ex: any) => {
  //         setDeleteEstimate({});
  //         console.error("Delete Api Error", ex);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deleteEstimate.open]);

  // --------- Table Columns  ---------

  const columns: ColumnsType<QuotationDataType> = [
    {
      title: "Estimate No.",
      dataIndex: "number",
      width: width <= 1440 ? "15%" : "15%",
      render: (_, record: QuotationDataType) => (
        <Skeleton
          active
          loading={props?.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.orderStatus !== OrderStatus.Deleted ? (
            <span
              style={{ color: "#1677FF", cursor: "pointer" }}
              onClick={() => {
                // setIsOpen(true);
                setIsOpen1("viewPreview");
                setInvoiceId(record?.id);
              }}
            >
              {record?.number}
            </span>
          ) : (
            <span style={{ color: "red" }}>{record?.number}</span>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      key: "Date",
      ellipsis: true,
      width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Total Qty",
      dataIndex: "totalQty",
      width: "10%",
      align: "right",
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={props?.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.totalQty}
        </Skeleton>
      ),
    },
    // {
    //   title: "Supplied Qty",
    //   dataIndex: "suppliedQty",
    //   width: "10%",
    //   key: "date",
    //   render: (_, record) => (
    //     <Skeleton
    //       active
    //       loading={props?.loading}
    //       paragraph={{ rows: 1 }}
    //       title={false}
    //     >
    //       {record?.suppliedQty}
    //     </Skeleton>
    //   ),
    // },
    {
      title: "Amount",
      dataIndex: "totalEstimateAmount",
      sorter: true,
      align: "right",
      key: "totalEstimateAmount",
      ellipsis: {
        showTitle: false,
      },
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.orderStatus !== OrderStatus.Deleted ? (
            <>₹ {Utils.getFormattedNumber(record?.invAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.invAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Status",
      dataIndex: "address",
      // width: "5%",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.orderStatus === OrderStatus.Completed ? (
            <Skeleton
              active
              loading={props?.loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="success"
                style={{
                  textAlign: "center",
                  width: 70,
                }}
              >
                Completed
              </Tag>
            </Skeleton>
          ) : record?.orderStatus === OrderStatus.Deleted ? (
            <Skeleton
              active
              loading={props?.loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="error"
                style={{
                  textAlign: "center",
                  width: 70,
                }}
              >
                {"Deleted"}
              </Tag>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={props?.loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="warning"
                style={{
                  textAlign: "center",
                  width: 67,
                }}
              >
                Pending
              </Tag>
            </Skeleton>
          )}
        </div>
      ),
    },
    quotationStatus !== OrderStatus.Draft
      ? {
          title: "   ",
          dataIndex: "icon",
          width: "3%",
          align: "center",
          render: (_, record) => (
            <Skeleton
              active
              loading={props?.loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              {record?.orderStatus === OrderStatus.Deleted ? (
                <SyncOutlined />
              ) : (
                <div className="cursor">
                  <Button
                    size="small"
                    icon={<FiDownload />}
                    style={{ border: "none", color: "#395cd2" }}
                    loading={!!loadingButtons[record?.id]}
                    onClick={() =>
                      downloadAndView(
                        currentClient?.id!,
                        record?.id,
                        ViewType.Pdf
                        // record.invNumber?.id
                      )
                    }
                  />
                </div>
              )}
            </Skeleton>
          ),
        }
      : { title: "  ", dataIndex: "icon3", width: "3%" },
    quotationStatus !== OrderStatus.Draft
      ? {
          title: "  ",
          dataIndex: "icon3",
          width: "3%",
          align: "center",
          render: (_, record) => (
            <div className="cursor">
              <Skeleton
                active
                loading={props?.loading}
                paragraph={{ rows: 1 }}
                title={false}
              >
                {record?.orderStatus === OrderStatus.Deleted ? (
                  <></>
                ) : (
                  <Tooltip title="Sales Order" placement="top" color="#64CA31">
                    <Link
                      to={`/accounts/clients/${currentClient?.id}/sales/addSalesOrder/estimate/${record?.id}`}
                    >
                      <ShoppingCartOutlined
                        // className="ca-invoice-btn"
                        onClick={() =>
                          `/accounts/clients/${currentClient?.id}/sales/addSalesOrder/estimate/${record?.id}`
                        }
                        style={{ color: "##7B97A8" }}
                      />
                    </Link>
                  </Tooltip>
                )}
              </Skeleton>
            </div>
          ),
        }
      : { title: "  ", dataIndex: "icon3", width: "3%" },
    {
      title: "",
      dataIndex: "icon2",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={props?.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.orderStatus === OrderStatus.Deleted ? (
            <></>
          ) : (
            <Tooltip title="Edit" placement="top" color="#3D4998">
              {quotationStatus === OrderStatus.Draft ? (
                <Link
                  to={`/accounts/clients/${currentClient?.id}/sales/editEstimateDraft/${record?.id}`}
                >
                  <EditOutlined
                    className="ca-edit-btn"
                    onClick={() =>
                      navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editEstimateDraft/${record?.id}`
                      )
                    }
                  />
                </Link>
              ) : (
                <Link
                  to={`/accounts/clients/${currentClient?.id}/sales/editEstimate/${record?.id}`}
                >
                  <EditOutlined
                    className="ca-edit-btn"
                    onClick={() =>
                      navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editEstimate/${record?.id}`
                      )
                    }
                    style={{ color: "#444653" }}
                  />
                </Link>
              )}
            </Tooltip>
          )}
        </Skeleton>
      ),
    },
    // {
    //   title: "  ",
    //   dataIndex: "icon3",
    //   width: "3%",
    //   align: "center",
    //   render: (_, record) => (
    //     <div className="cursor">
    //       <Skeleton
    //         active
    //         loading={loading}
    //         paragraph={{ rows: 1 }}
    //         title={false}
    //       >
    //         <Tooltip title="Create Invoice" placement="top" color="#64CA31">
    //           <Link
    //             to={`/accounts/clients/${currentClient?.id}/sales/addInvoice/estimate/${record?.id}`}
    //           >
    //             <FileTextOutlined
    //               className="ca-invoice-btn"
    //               onClick={() => {
    //                 navigate(
    //                   `/accounts/clients/${currentClient?.id}/sales/addInvoice/estimate/${record?.id}`
    //                 );
    //               }}
    //               style={{ color: "#64CA31" }}
    //             />
    //           </Link>
    //         </Tooltip>
    //       </Skeleton>
    //     </div>
    //   ),
    // },
    {
      title: "  ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={props?.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.orderStatus === OrderStatus.Deleted ? (
            <></>
          ) : (
            <Tooltip title="Delete" placement="top" color="red">
              <DeleteTwoTone
                className="ca-delete-btn"
                twoToneColor="#ff879d"
                onClick={() => {
                  setConfirmationModel(true);
                  setEstimateId(record?.id);
                  setDeleteEstimate({
                    ...deleteEstimate,
                    status: DBRecordStatus.Deleted,
                    name: record?.number,
                  });
                }}
              />
            </Tooltip>
          )}
          {!record?.id && record?.deleteType === 1 && (
            <Tooltip
              title="Revive Delivery Challan"
              placement="top"
              color="green"
            >
              <RetweetOutlined
                className="ca-invoice-btn"
                style={{ color: "#64CA31" }}
                onClick={() => {
                  setConfirmationModel(true);
                  setEstimateId(record?.id);
                  setDeleteEstimate({
                    ...deleteEstimate,
                    status: DBRecordStatus.Active,
                    name: record?.number?.no,
                  });
                }}
              />
            </Tooltip>
          )}
        </Skeleton>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Party Name",
      dataIndex: "customerName",
      width: "30%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.customerName?.name}
        </Skeleton>
      ),
    });
  }

  console.log("quotat", quotationStatus);

  // --------- Get TableData API ---------
  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      // props?.setLoading(true);
      getEstimateList(
        currentClient?.id,
        Voucher_Type.Quotation,
        dayjs(filterDate.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate.to, "DD/MM/YYYY").endOf("day").toISOString(),
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filter.sortCol,
        filter.sortDir,
        searchCustomer,
        "",
        quotationStatus

        // quotationStatus
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    searchCustomer,
    refresh,
    exportType,
    quotationStatus,
  ]);

  const getEstimateList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    fromDate: string = "",
    toDate: string = "",
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    search?: string,
    status?: OrderStatus
  ) => {
    try {
      if (exportType < 1) {
        props?.setLoading(true);
      }

      let res: any =
        quotationStatus === OrderStatus.Draft
          ? await DraftService?.getVoucherDraftList(
              clientId,
              Voucher_Type,
              fromDate,
              toDate,
              start,
              length,
              sortCol,
              sortDir,
              search,
              partyId
              // status
            )
          : await QuotationServices.getQuotationList(
              clientId,
              Voucher_Type,
              fromDate,
              toDate,
              start,
              length,
              sortCol,
              sortDir,
              search,
              partyId,
              status
            );

      if (res?.result?.voucherItems?.length > 0) {
        console.log("res.items = ", res?.result?.voucherItems);
        // table list data
        let estimateData = res?.result?.voucherItems?.map(
          (v: any, index: number) => {
            // console.log("This is my data", v);
            return {
              key: index,
              orderStatus: v?.orderStatus,
              id: v?.vId,
              date: dayjs(v?.date).format("DD/MM/YYYY"),
              number: v?.number,
              customerName: {
                id: v?.pLedger[0].id,
                name: v?.pLedger[0]?.ledgerName,
              },
              totalQty: v?.totalQty,
              // suppliedQty: v?.suppliedQty,
              invAmount: v?.invAmount,
              refundAmount: v?.refundAmount,
              receivedAmount: v?.receivedAmount,
              dueBalance: v?.dueAmount,
            };
          }
        );
        if (exportType >= 1) {
          setExportQuotationData(estimateData);
          if (exportType === 1) {
            exportCsv(estimateData);
          } else if (exportType === 2) {
            exportPDF(estimateData);
          } else if (exportType === 3) {
            exportExcel(estimateData);
          }
        } else {
          props.setEstimateList({
            // totalRejected: res?.totalRejected,
            // totalAccepted: res?.total?.totalAccepted,
            // totalPendingAmount: res?.result?.totalPendingAmount,
            // totalEstimate: res?.total?.totalEstimate,
            //----For TableSubTotal----///

            totalRejected: res?.result?.voucherItems?.filter(
              (itm: any) => itm.orderStatus === OrderStatus.Deleted
            ).length,
            totalAccepted: res?.result?.voucherItems?.filter(
              (itm: any) => itm.orderStatus === OrderStatus.Completed
            ).length,
            totalPending: res?.result?.voucherItems?.filter(
              (itm: any) => itm.orderStatus === OrderStatus.Pending
            ).length,
            totalEstimate: res?.result?.voucherItems?.length,

            totalAmount: res?.result?.totalAmount,
          });
          setTotalRecords(res?.totalRecords);
          setInvoiceListData(estimateData);
        }
        //-----For Quotation/Estimate Graphs-----//
      } else {
        props.setEstimateList([]);
        setExportQuotationData([]);
        setInvoiceListData([]);
        setTotalRecords(0);
      }
      props?.setLoading(false);
    } catch (ex) {
      console.error(ex);
      props?.setLoading(false);
      props.setEstimateList([]);
    }
    // });
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
  };

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      if (col === "Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.totalEstimateAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(props?.estimateList?.totalAmount)
        }`;
      } else if (col === "Party Name") {
        return "Total";
      } else {
        return ""; // Empty string for other columns
      }
    });
  };

  let path = "Estimate List";
  const columnsA = [
    "Estimate No",
    "Party Name",
    "Date",
    "Total Qty",
    // "Supplied Qty",
    "Amount",
    "Status",
  ];

  const fieldMapping = {
    "Estimate No": (item: any) => item?.number,
    "Party Name": (item: any) => item?.customerName?.name,
    Date: (item: any) => item?.date,
    "Total Qty": (item: any) => item?.totalQty,
    // "Supplied Qty": (item: any) => Utils.getFormattedNumber(item?.suppliedQty),
    Amount: (item: any) => Utils?.getFormattedNumber(item?.dueBalance),
    Status: (item: any) =>
      item?.orderStatus === OrderStatus.Completed
        ? "Completed"
        : item?.orderStatus === OrderStatus.Deleted
        ? "Deleted"
        : "Pending",
  };

  const exportCsv = (invExportData: any[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;

    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (invExportData: any[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (invExportData: any[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;

    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportQuotationData.length > 0) {
              exportCsv(exportQuotationData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportQuotationData.length > 0) {
              exportPDF(exportQuotationData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportQuotationData.length > 0) {
              exportExcel(exportQuotationData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  return (
    <div>
      <Row
        // justify={windowWidth <= 768 ? "center" : "space-between"}
        justify={width <= 768 ? "space-evenly" : "space-between"}
        gutter={windowWidth <= 1024 ? 65 : 0}
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px",
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col xl={6} lg={4}>
          <Space size={5}>
            {/* <Tooltip
              title={
                <>
                  Add Estimate
                  <br />
                  <Tag>
                    <kbd>Shift + F8</kbd>
                  </Tag>
                </>
              }
            > */}
            <Button
              type="primary"
              id="gfg"
              icon={<PlusOutlined />}
              onClick={() =>
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addEstimate/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addEstimate`
                    )
              }
            >
              Estimate
            </Button>
            {/* </Tooltip> */}
            {invoiceListData?.length === 0 ? (
              <>
                <Button type="default" icon={<DownloadOutlined />} disabled>
                  Export
                </Button>
              </>
            ) : (
              <>
                <Popover content={content} placement="rightTop" trigger="hover">
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    // onClick={() => exportCsv()}
                  >
                    Export
                  </Button>
                </Popover>
              </>
            )}
          </Space>
        </Col>

        <Col
          xl={18}
          lg={20}
          style={{ marginTop: width <= 768 ? "6px" : "0px" }}
        >
          <Row justify="end" gutter={8}>
            <Col>
              <Input
                // style={{ width: "220px" }}
                style={{ border: "1px solid #f1f3ff", width: "200px" }}
                placeholder="Search Ledger & No."
                allowClear
                onChange={(e: any) => {
                  props?.setLoading(true);
                  if (!e.cancelable) {
                    const data = setTimeout(() => {
                      // console.log("customer search.......................");
                      setPage(1);
                      setSearchCustomer(e.target.value);
                    }, 1000);
                    return () => clearTimeout(data);
                  } else {
                    setSearchCustomer(e.target.value);
                  }
                  setExportQuotationData([]);
                  setExportType(-1);
                }}
                suffix={
                  props?.loading || searchCustomer ? (
                    ""
                  ) : (
                    <img alt="Search.." src={search} />
                  )
                }
              />
            </Col>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                format="DD/MM/YYYY"
                // style={{ width: "220px" }}
                style={{
                  width:
                    width <= 464 ? "230px" : width == 375 ? "200px" : "250px",
                  border: "1px solid #f1f3ff",
                }}
                onChange={(e: any) => {
                  console.log(e);
                  if (e === null) {
                    setFilterDate({});
                  } else {
                    setFilterDate({
                      ...filterDate,
                      from: dayjs(e[0]).format("DD/MM/YYYY"),
                      to: dayjs(e[1]).format("DD/MM/YYYY"),
                    });
                    localStorage.setItem(
                      "LockPeriod",
                      JSON.stringify({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      })
                    );
                  }
                  openNotification("success", `Period locked.`);
                  setExportQuotationData([]);
                  setExportType(-1);
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: getLast7Days(),
                  },
                  {
                    label: "Last 15 Days",
                    value: getLast15Days(),
                  },
                  {
                    label: "This Month",
                    value: getThisMonth(),
                  },
                  {
                    label: "Last Month",
                    value: getLastMonth(),
                  },
                  {
                    label: "Quarter 1",
                    value: getQuarterPreset(0),
                  },
                  {
                    label: "Quarter 2",
                    value: getQuarterPreset(3),
                  },
                  {
                    label: "Quarter 3",
                    value: getQuarterPreset(6),
                  },
                  {
                    label: "Quarter 4",
                    value: getQuarterPreset(9),
                  },
                  {
                    label: "This Year",
                    value: thisYear(),
                  },
                  {
                    label: "Last Year",
                    value: lastYear(),
                  },
                ]}
              />
            </Col>
            {/* <Col>
              <Select
                style={{
                  width: width === 1024 ? 80 : 120,
                }}
                value={estimateStatus}
                onSelect={(e) => {
                  setPage(1);
                  setEstimateStatus(e);
                }}
                options={[
                  { value: EstimateStatus.All, label: "All" },
                  { value: EstimateStatus.Active, label: "Active" },
                  { value: EstimateStatus.Pending, label: "Pending" },
                  { value: EstimateStatus.Accpected, label: "Accpected" },
                  { value: EstimateStatus.Deleted, label: "Deleted" },
                ]}
              />
            </Col> */}

            <Col>
              <Select
                // clearIcon={true}
                defaultValue={OrderStatus.Pending}
                style={{ width: 120 }}
                allowClear={false}
                options={[
                  { value: OrderStatus.All, label: "All" },
                  { value: OrderStatus.Pending, label: "Pending" },
                  { value: OrderStatus.Completed, label: "Completed" },
                  { value: OrderStatus.Deleted, label: "Deleted" },
                  { value: OrderStatus.Draft, label: "Draft" },
                ]}
                onSelect={(val: OrderStatus) => {
                  setPage(1);
                  setQuotationStatus(val);
                  setExportQuotationData([]);
                  setExportType(-1);
                }}
              />
            </Col>
            <Col>
              <ButtonComponent />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
              >
                <Table.Summary.Cell
                  index={1}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="fw600">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="fw600" align="right">
                  ₹ {Utils.getFormattedNumber(props.estimateList?.totalAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        rowSelection={rowSelection}
        columns={columns}
        scroll={
          pageSize > 15 ? { x: 1500, y: window.innerHeight - 580 } : undefined
        }
        dataSource={
          props?.loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as any)
            : invoiceListData
        }
        onChange={tableSort}
        // style={{ overflowX: "auto" }}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {open && (
        <ViewEstimateModel
          open={open}
          onCancel={() => setIsOpen(false)}
          estimateId={invoiceId}
          voucharType={Voucher_Type.Quotation}
        />
      )}
      {open1 === "viewPreview" && (
        <ViewPreviewModel
          open={open1 === "viewPreview"}
          onCancel={() => setIsOpen1("")}
          voucherId={invoiceId}
          invVoucharType={Voucher_Type.Quotation}
          partyId={currentClient?.id}
        />
      )}
      {confirmationModel && (
        <ConfirmationModal
          open={confirmationModel}
          text={`Do you really want to ${
            deleteEstimate.status === 1 ? "Delete" : "Active"
          }  this Estimate "${deleteEstimate.name}" ?`}
          onNo={() => setConfirmationModel(false)}
          onYes={() => {
            setConfirmationModel(false);
            deleteData(estimateId);
          }}
        />
      )}
    </div>
  );
};
export default QuotationTable;
